
export const allLogosData =  [
      {id: 3000, name: 'Bears', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Bears.png'}, 
      {id: 3001, name: 'Bengals', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Bengals.png'}, 
      {id: 3002, name: 'Bills', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Bills.png'}, 
      {id: 3003, name: 'Broncos', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Broncos.png'}, 
      {id: 3004, name: 'Browns', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Browns.png'}, 
      {id: 3005, name: 'Buccaneers', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Buccaneers.png'}, 
      {id: 3006, name: 'Cardinals', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Cardinals.png'}, 
      {id: 3031, name: 'Chargers', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Chargers.png'}, 
      {id: 3007, name: 'Chiefs', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Chiefs.png'}, 
      {id: 3008, name: 'Colts', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Colts.png'}, 
      {id: 3009, name: 'Commanders', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Commanders.png'}, 
      {id: 3010, name: 'Cowboys', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Cowboys.png'}, 
      {id: 3011, name: 'Dolphins', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Dolphins.png'}, 
      {id: 3012, name: 'Eagles', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Eagles.png'}, 
      {id: 3013, name: 'Falcons', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Falcons.png'}, 
      {id: 3015, name: '49ers', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/FortyNiners.png'}, 
      {id: 3014, name: 'Giants', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Giants.png'}, 
      {id: 3016, name: 'Jaguars', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Jaguars.png'}, 
      {id: 3017, name: 'Jets', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Jets.png'}, 
      {id: 3018, name: 'Lions', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Lions.png'}, 
      {id: 3019, name: 'Packers', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Packers.png'}, 
      {id: 3020, name: 'Panthers', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Panthers.png'}, 
      {id: 3021, name: 'Patriots', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Patriots.png'}, 
      {id: 3022, name: 'Raiders', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Raiders.png'}, 
      {id: 3023, name: 'Rams', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Rams.png'}, 
      {id: 3024, name: 'Ravens', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Ravens.png'}, 
      {id: 3025, name: 'Saints', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Saints.png'}, 
      {id: 3026, name: 'Seahawks', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Seahawks.png'}, 
      {id: 3027, name: 'Steelers', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Steelers.png'}, 
      {id: 3028, name: 'Texans', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Texans.png'}, 
      {id: 3029, name: 'Titans', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Titans.png'}, 
      {id: 3030, name: 'Vikings', subType:'NFL', type: 'Logo', imageUrl: '/KitCreator/logos/Vikings.png'}, 

      
      {id: 3203, name: 'Bengals', subType:'NFL Alternate', type: 'Logo', imageUrl: '/KitCreator/logos/Bengals_Alternate.png'}, 
      {id: 3204, name: 'Cardinals', subType:'NFL Alternate', type: 'Logo', imageUrl: '/KitCreator/logos/Cardinals_Alternate.png'}, 
     
      {id: 3182, name: 'Bengals', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Bengals_Retro.png'}, 
      {id: 3183, name: 'Broncos', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Broncos_Retro.png'}, 
     {id: 3184, name: 'Browns', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Browns_Retro.png'}, 
       {id: 3185, name: 'Buccaneers', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Buccaneers_Retro.png'}, 
      {id: 3186, name: 'Cardinals', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Cardinals_Retro.png'}, 
      {id: 3187, name: 'Dolphins', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Dolphins_Retro.png'}, 
      {id: 3188, name: 'Eagles', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Eagles_Retro.png'}, 
      {id: 3189, name: 'Falcons', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Falcons_Retro.png'}, 
       {id: 3061, name: 'Football Team', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/FootballTeam.png'}, 
      {id: 3190, name: '49ers', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/FortyNiners_Retro.png'}, 
      {id: 3191, name: 'Jaguars', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Jaguars_Retro.png'}, 
       {id: 3192, name: 'Jets', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Jets_Retro.png'}, 
      {id: 3193, name: 'Jets', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Jets_Retro2.png'}, 
      {id: 3194, name: 'Lions', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Lions_Retro.png'}, 
       {id: 3079, name: 'Oilers', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Oilers.png'}, 
      {id: 3195, name: 'Patriots', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Patriots_Retro.png'}, 
       {id: 3196, name: 'Rams', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Rams_Retro.png'}, 
       {id: 3197, name: 'Rams', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Rams_Retro2.png'}, 
       {id: 3198, name: 'Rams', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Rams_Retro3.png'}, 
      {id: 3199, name: 'Ravens', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Ravens_Retro.png'}, 
       {id: 3085, name: 'Redskins', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Redskins.png'}, 
       {id: 3200, name: 'Saints', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Saints_Retro.png'}, 
      {id: 3201, name: 'Seahawks', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Seahawks_Retro.png'}, 
      {id: 3202, name: 'Seahawks', subType:'NFL Classic', type: 'Logo', imageUrl: '/KitCreator/logos/Seahawks_Retro2.png'}, 

      {id: 3032, name: 'Aftershocks', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Aftershocks.png'}, 
      {id: 3033, name: 'Antlers', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Antlers.png'}, 
      {id: 3034, name: 'Armadillos', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Armadillos.png'}, 
      {id: 3035, name: 'Aviators', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Aviators.png'}, 
      {id: 3036, name: 'Barons', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Barons.png'}, 
      {id: 3037, name: 'Bats', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Bats.png'}, 
      {id: 3038, name: 'Beats', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Beats.png'}, 
      {id: 3039, name: 'Bisons', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Bisons.png'}, 
      {id: 3040, name: 'Black Knights', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/BlackKnights.png'}, 
      {id: 3041, name: 'Blues', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Blues.png'}, 
      {id: 3043, name: 'Bulldogs', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Bulldogs.png'}, 
      {id: 3044, name: 'Bulls', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Bulls.png'}, 
      {id: 3045, name: 'Caps', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Caps.png'}, 
      {id: 3046, name: 'Celtic Tigers', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/CelticTigers.png'}, 
      {id: 3048, name: 'Condors', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Condors.png'}, 
      {id: 3049, name: 'Conquistadors', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Conquistadors.png'}, 
      {id: 3050, name: 'Cougars', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Cougars.png'}, 
      {id: 3052, name: 'Crusaders', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Crusaders.png'}, 
      {id: 3053, name: 'Desperados', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Desperados.png'}, 
      {id: 3054, name: 'Diablos', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Diablos.png'}, 
      {id: 3055, name: 'Dreadnaughts', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Dreadnaughts.png'}, 
      {id: 3056, name: 'Egyptians', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Egyptians.png'}, 
      {id: 3057, name: 'Elks', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Elks.png'}, 
      {id: 3058, name: 'Explorers', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Explorers.png'}, 
      {id: 3060, name: 'Flyers', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Flyers.png'}, 
      {id: 3062, name: 'Golden Eagles', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/GoldenEagles.png'}, 
      {id: 3063, name: 'Gunners', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Gunners.png'}, 
      {id: 3064, name: 'Hounds', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Hounds.png'}, 
      {id: 3065, name: 'Huskies', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Huskies.png'}, 
      {id: 3066, name: 'Lancers', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Lancers.png'}, 
      {id: 3069, name: 'Lumberjacks', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Lumberjacks.png'}, 
      {id: 3070, name: 'Marshalls', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Marshalls.png'}, 
      {id: 3073, name: 'Miners', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Miners.png'}, 
      {id: 3074, name: 'Monarchs', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Monarchs.png'}, 
      {id: 3075, name: 'Mounties', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Mounties.png'}, 
      {id: 3078, name: 'Nighthawks', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Nighthawks.png'}, 
     {id: 3081, name: 'Orbits', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Orbits.png'}, 
     {id: 3083, name: 'Red Dragons', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/RedDragons.png'}, 
     {id: 3084, name: 'Red Hawks', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/RedHawks.png'}, 
     {id: 3086, name: 'Redwoods', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Redwoods.png'}, 
     {id: 3087, name: 'Riverhogs', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Riverhogs.png'}, 
     {id: 3088, name: 'Sentinels', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Sentinels.png'}, 
     {id: 3089, name: 'Shamrocks', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Shamrocks.png'}, 
     {id: 3090, name: 'Snowhawks', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Snowhawks.png'}, 
     {id: 3092, name: 'Stampede', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Stampede.png'}, 
     {id: 3093, name: 'Steamers', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Steamers.png'}, 
     {id: 3094, name: 'Thunderbirds', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Thunderbirds.png'}, 
     {id: 3095, name: 'Tigers', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Tigers.png'}, 
     {id: 3096, name: 'Voyagers', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Voyagers.png'}, 
     {id: 3097, name: 'Wizards', subType:'Relocation', type: 'Logo', imageUrl: '/KitCreator/logos/Wizards.png'}, 

      {id: 3042, name: 'Buckeyes', subType:'College', type: 'Logo', imageUrl: '/KitCreator/logos/Buckeyes.png'}, 
      {id: 3047, name: 'Clemson', subType:'College', type: 'Logo', imageUrl: '/KitCreator/logos/Clemson.png'}, 
      {id: 3051, name: 'Crimson Tide', subType:'College', type: 'Logo', imageUrl: '/KitCreator/logos/CrimsonTide.png'}, 
      {id: 3059, name: 'Florida', subType:'College', type: 'Logo', imageUrl: '/KitCreator/logos/Florida.png'}, 
      {id: 3067, name: 'Longhorns', subType:'College', type: 'Logo', imageUrl: '/KitCreator/logos/Longhorns.png'}, 
      {id: 3068, name: 'LSU', subType:'College', type: 'Logo', imageUrl: '/KitCreator/logos/LSU.png'}, 
      {id: 3071, name: 'Miami', subType:'College', type: 'Logo', imageUrl: '/KitCreator/logos/Miami.png'}, 
      {id: 3072, name: 'Michigan State', subType:'College', type: 'Logo', imageUrl: '/KitCreator/logos/MichiganState.png'}, 
      {id: 3076, name: 'Nebraska', subType:'College', type: 'Logo', imageUrl: '/KitCreator/logos/Nebraska.png'}, 
      {id: 3080, name: 'Oklahoma', subType:'College', type: 'Logo', imageUrl: '/KitCreator/logos/Oklahoma.png'}, 
      {id: 3082, name: 'Oregon', subType:'College', type: 'Logo', imageUrl: '/KitCreator/logos/Oregon.png'}, 
      {id: 3091, name: 'SC', subType:'College', type: 'Logo', imageUrl: '/KitCreator/logos/SouthCalifornia.png'}, 

      {id: 3124, name: 'Elks', subType:'CFL', type: 'Logo', imageUrl: '/KitCreator/logos/Elks2.png'}, 

      {id: 3107, name: 'Breakers', subType:'USFL', type: 'Logo', imageUrl: '/KitCreator/logos/Breakers.png'}, 
      {id: 3133, name: 'Gamblers', subType:'USFL', type: 'Logo', imageUrl: '/KitCreator/logos/Gamblers.png'}, 
      {id: 3135, name: 'Generals', subType:'USFL', type: 'Logo', imageUrl: '/KitCreator/logos/Generals.png'}, 
      {id: 3147, name: 'Maulers', subType:'USFL', type: 'Logo', imageUrl: '/KitCreator/logos/Maulers.png'}, 
      {id: 3150, name: 'Panthers', subType:'USFL', type: 'Logo', imageUrl: '/KitCreator/logos/PanthersUSFL.png'}, 
      {id: 3164, name: 'Stallions', subType:'USFL', type: 'Logo', imageUrl: '/KitCreator/logos/Stallions.png'},
      {id: 3166, name: 'Stars', subType:'USFL', type: 'Logo', imageUrl: '/KitCreator/logos/Stars.png'}, 


      {id: 3098, name: 'Admirals', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Admirals.png'}, 
      {id: 3099, name: 'Alpacas', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Alpacas.png'}, 
      {id: 3100, name: 'Archers', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Archers.png'}, 
      {id: 3101, name: 'Barons', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Barons2.png'}, 
      {id: 3102, name: 'Barons', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Barons3.png'}, 
      {id: 3103, name: 'Black Knights', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/BlackKnights2.png'}, 
      {id: 3104, name: 'Blue Tigers', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/BlueTigers.png'}, 
      {id: 3105, name: 'Blue Tigers', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/BlueTigers2.png'}, 
      {id: 3106, name: 'Braves', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Braves.png'}, 
      {id: 3108, name: 'Bs', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Bs.png'}, 
      {id: 3109, name: 'Bulldogs', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/BulldogsAlt.png'}, 
      {id: 3110, name: 'Bulldogs', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/BulldogsAlt2.png'}, 
      {id: 3111, name: 'Bulls', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Bulls2.png'}, 
      {id: 3112, name: 'Capitals', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Capitals.png'}, 
      {id: 3113, name: 'Caps', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Caps2.png'}, 
      {id: 3114, name: 'Celtic Tigers', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/CelticTigers2.png'}, 
      {id: 3181, name: 'Cougars', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/CAR2.png'}, 
      {id: 3115, name: 'Defenders', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Defenders.png'}, 
      {id: 3116, name: 'Demons', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Demons.png'}, 
      {id: 3117, name: 'Desperados', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Desperados2.png'}, 
      {id: 3185, name: 'Desperados', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/DEN.png'}, 
      {id: 3118, name: 'Diablos', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Diablos2.png'}, 
      {id: 3119, name: 'Dragons', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Dragons.png'}, 
      {id: 3120, name: 'Dragons', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Dragons2.png'}, 
     // {id: 3121, name: 'Dragons', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Dragons2.png'}, 
      {id: 3122, name: 'Eagles', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Eagles2.png'}, 
      {id: 3123, name: 'Echoes', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Echoes.png'}, 
      {id: 3125, name: 'Express', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Express.png'}, 
      {id: 3126, name: 'Express', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Express2.png'}, 
      {id: 3192, name: 'Firebirds', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/PHX.png'}, 
      {id: 3127, name: 'Firebirds', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Firebirds.png'}, 
      {id: 3128, name: 'Fleet', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Fleet.png'}, 
      {id: 3129, name: 'Freedom', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Freedom.png'}, 
      {id: 3130, name: 'Freedom', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/FreedomAlt.png'}, 
      {id: 3131, name: 'Freeze', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Freeze.png'}, 
      {id: 3132, name: 'Fronteir', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Fronteir.png'}, 
      {id: 3134, name: 'Gamblers', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/GamblersAlt.png'}, 
      {id: 3136, name: 'Generals', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Generals2.png'}, 
      {id: 3137, name: 'Grizzlies', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Grizzlies.png'}, 
      {id: 3189, name: 'Hounds', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/MEM.png'}, 
      {id: 3138, name: 'Hurricanes', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Hurricanes.png'}, 
      {id: 3139, name: 'Huskies', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Huskies2.png'}, 
      {id: 3140, name: 'Invaders', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Invaders.png'}, 
      {id: 3141, name: 'Islanders', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Islanders.png'}, 
      {id: 3142, name: 'Jets', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Jets2.png'}, 
      {id: 3143, name: 'Kraken', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Kraken.png'}, 
      {id: 3144, name: 'Liberty', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Liberty.png'}, 
      {id: 3145, name: 'Liberty', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Liberty2.png'}, 
      {id: 3146, name: 'Lumberjacks', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Lumberjacks2.png'}, 
      {id: 3191, name: 'Marauders', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/NO.png'}, 
      {id: 3190, name: 'Mariners', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/MIA.png'}, 
      {id: 3184, name: 'Marshalls', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/DAL.png'}, 
      {id: 3195, name: 'Miners', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/SF.png'}, 
      {id: 3180, name: 'Minutemen', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/BOS.png'}, 
      {id: 3186, name: 'Monarchs', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/DET.png'}, 
      {id: 3148, name: 'Mustangs', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Mustangs.png'}, 
      {id: 3149, name: 'Night Horses', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/NightHorses.png'}, 
      {id: 3194, name: 'Orcas', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/SEA.png'}, 
      {id: 3151, name: 'Phantoms', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Phantoms.png'}, 
      {id: 3152, name: 'Pioneers', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Pioneers_Alt.png'}, 
      {id: 3193, name: 'Pros', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/SCR.png'}, 
      {id: 3187, name: 'Rattlers', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/LV.png'}, 
      {id: 3153, name: 'Reapers', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Reapers.png'}, 
      {id: 3154, name: 'Roosters', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Roosters.png'}, 
      {id: 3155, name: 'Roosters', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Roosters2.png'}, 
      {id: 3156, name: 'Roosters', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Roosters3.png'}, 
      {id: 3157, name: 'Royals', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Royals.png'}, 
      {id: 3158, name: 'Seadragons', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Seadragons.png'}, 
      {id: 3159, name: 'Sentinels', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Sentinels2.png'}, 
      {id: 3160, name: 'Sharks', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Sharks.png'}, 
      {id: 3161, name: 'Silverbacks', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Silverbacks.png'}, 
      {id: 3162, name: 'Soldiers', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Soldiers.png'}, 
      {id: 3183, name: 'Spartans', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/CHI.png'}, 
      {id: 3163, name: 'Spartans', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Spartans.png'}, 
      {id: 3165, name: 'Stallions', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/StallionsAlt.png'}, 
      {id: 3167, name: 'Stars', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Stars2.png'}, 
      {id: 3196, name: 'Tanners', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/STL.png'}, 
      {id: 3168, name: 'Terrecota Warriors', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/TerrecotaWarriors.png'}, 
      {id: 3169, name: 'Terrorizers', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Terrorizers.png'}, 
      {id: 3170, name: 'Tide', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Tide.png'}, 
      {id: 3171, name: 'Timberwolves', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Timberwolves.png'}, 
      {id: 3172, name: 'Tsunamis', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Tsunamis.png'}, 
      {id: 3173, name: 'Tyrannos', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Tyrannos.png'}, 
      {id: 3174, name: 'Tyrannos', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/TyrannosAlt.png'}, 
      {id: 3175, name: 'Warriors', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Warriors.png'}, 
      {id: 3176, name: 'Wolfpack', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Wolfpack.png'}, 
      {id: 3177, name: 'Wolves', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Wolves.png'}, 
      {id: 3178, name: 'Wranglers', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Wranglers.png'}, 
      {id: 3179, name: 'Yellowjackets', subType:'Custom', type: 'Logo', imageUrl: '/KitCreator/logos/Yellowjackets.png'}, 

      //3205
    ]

export const allJerseyAssetsData = [
    { id: 39, name: 'NFL', subType:'Pro', type: 'League', imageUrl: '/KitCreator/jersey/league/NFL.png', previewUrl: '/KitCreator/preview/league/NFL.png',color: '#000000'},
    { id: 31, name: 'CFL', subType:'Pro', type: 'League', imageUrl: '/KitCreator/jersey/league/CFL.png',previewUrl: '/KitCreator/preview/league/CFL.png', color: '#000000'},
    { id: 42, name: 'XFL', subType:'Pro', type: 'League', imageUrl: '/KitCreator/jersey/league/XFL.png',previewUrl: '/KitCreator/preview/league/XFL.png', color: '#000000', canColor: true},
    { id: 37, name: 'NFL', subType:'Concept', type: 'League', imageUrl: '/KitCreator/jersey/league/NFL_CONCEPT.png', previewUrl: '/KitCreator/preview/league/NFL_CONCEPT.png',color: '#000000'},
    { id: 38, name: 'NFL', subType:'Retro', type: 'League', imageUrl: '/KitCreator/jersey/league/NFL_RETRO.png',previewUrl: '/KitCreator/preview/league/NFL_CLASSIC.png', color: '#000000'},
    { id: 33, name: 'IFL', subType:'Arena', type: 'League', imageUrl: '/KitCreator/jersey/league/IFL.png', previewUrl: '/KitCreator/preview/league/IFL.png',color: '#000000'},
    { id: 35, name: 'MLF', subType:'JB83', type: 'League', imageUrl: '/KitCreator/jersey/league/MLF_JB83.png', previewUrl: '/KitCreator/preview/league/MLF.png',color: '#000000'},
    { id: 32, name: 'FFL', subType:'FFC', type: 'League', imageUrl: '/KitCreator/jersey/league/FFL_FFC.png',previewUrl: '/KitCreator/preview/league/FFL.png', color: '#000000'},
    { id: 26, name: 'ACC', subType:'College', type: 'League', imageUrl: '/KitCreator/jersey/league/ACC_COLLEGE.png', previewUrl: '/KitCreator/preview/league/ACC.png',color: '#000000'},
    { id: 27, name: 'American', subType:'College', type: 'League', imageUrl: '/KitCreator/jersey/league/AMERICAN_COLLEGE.png', previewUrl: '/KitCreator/preview/league/AMERICAN.png',color: '#000000'},
    { id: 28, name: 'BIG 10', subType:'College', type: 'League', imageUrl: '/KitCreator/jersey/league/BIG10_COLLEGE.png', previewUrl: '/KitCreator/preview/league/BIG10.png',color: '#000000',canColor: true},
    { id: 29, name: 'BIG 12', subType:'College', type: 'League', imageUrl: '/KitCreator/jersey/league/BIG12_COLLEGE.png', previewUrl: '/KitCreator/preview/league/BIG12.png',color: '#000000'},
    { id: 30, name: 'C USA', subType:'College', type: 'League', imageUrl: '/KitCreator/jersey/league/C-USA_COLLEGE.png', previewUrl: '/KitCreator/preview/league/CUSA.png',color: '#000000',canColor: true},
    { id: 34, name: 'MAC', subType:'College', type: 'League', imageUrl: '/KitCreator/jersey/league/MAC_COLLEGE.png', previewUrl: '/KitCreator/preview/league/MAC.png',color: '#000000'},
    { id: 36, name: 'Mountain West', subType:'College', type: 'League', imageUrl: '/KitCreator/jersey/league/MW_COLLEGE.png',previewUrl: '/KitCreator/preview/league/MW.png', color: '#000000'}, 
    { id: 40, name: 'PAC 12', subType:'College', type: 'League', imageUrl: '/KitCreator/jersey/league/PAC12_COLLEGE.png',previewUrl: '/KitCreator/preview/league/PAC12.png', color: '#000000'},
    { id: 41, name: 'SEC', subType:'College', type: 'League', imageUrl: '/KitCreator/jersey/league/SEC_COLLEGE.png', previewUrl: '/KitCreator/preview/league/SEC.png',color: '#000000'},
    { id: 131, name: 'USFL', subType:'Pro', type: 'League', imageUrl: '/KitCreator/jersey/league/USFL.png',previewUrl: '/KitCreator/preview/league/USFL.png', color: '#000000'},
    
    { id: 132, name: 'AAF', subType:'Pro', type: 'League', imageUrl: '/KitCreator/jersey/league/AAF.png', previewUrl: '/KitCreator/preview/league/AAF.png',color: '#000000'},
    { id: 133, name: 'AFL', subType:'Pro', type: 'League', imageUrl: '/KitCreator/jersey/league/AFL.png', previewUrl: '/KitCreator/preview/league/AFL.png',color: '#000000'},
    { id: 134, name: 'EUFL', subType:'Pro', type: 'League', imageUrl: '/KitCreator/jersey/league/EUFL.png', previewUrl: '/KitCreator/preview/league/EUFL.png',color: '#000000'},
    { id: 135, name: 'IFL2', subType:'Pro', type: 'League', imageUrl: '/KitCreator/jersey/league/IFL2.png', previewUrl: '/KitCreator/preview/league/IFL2.png',color: '#000000'},
    { id: 136, name: 'LFA', subType:'Pro', type: 'League', imageUrl: '/KitCreator/jersey/league/LFA.png', previewUrl: '/KitCreator/preview/league/LFA.png',color: '#000000'},
    { id: 137, name: 'NAFL', subType:'Pro', type: 'League', imageUrl: '/KitCreator/jersey/league/NAFL.png', previewUrl: '/KitCreator/preview/league/NAFL.png',color: '#000000'},
    { id: 138, name: 'NFLEU', subType:'Pro', type: 'League', imageUrl: '/KitCreator/jersey/league/NFLEU.png', previewUrl: '/KitCreator/preview/league/NFLEU.png',color: '#000000'},
    { id: 139, name: 'UoF', subType:'Pro', type: 'League', imageUrl: '/KitCreator/jersey/league/UoF.png', previewUrl: '/KitCreator/preview/league/UoF.png',color: '#000000'},
    { id: 140, name: 'WFL', subType:'Pro', type: 'League', imageUrl: '/KitCreator/jersey/league/WFL.png', previewUrl: '/KitCreator/preview/league/WFL.png',color: '#000000'},
    { id: 141, name: 'XLeague', subType:'Pro', type: 'League', imageUrl: '/KitCreator/jersey/league/XLeague.png',previewUrl: '/KitCreator/preview/league/XLeague.png', color: '#000000'},
    { id: 142, name: 'FCF', subType:'Pro', type: 'League', imageUrl: '/KitCreator/jersey/league/FCF.png',previewUrl: '/KitCreator/preview/league/FCF.png', color: '#000000'},


    // { id: 1, name: 'Base', subType:'Jersey', type: 'Jersey', imageUrl: '/KitCreator/jersey/base/base.png', color: '#000000'},
    { id: 2, name: 'Broncos', subType:'Spokes', type: 'Jersey', imageUrl: '/KitCreator/jersey/base/BRONCOS.png', color: '#000000'},
    { id: 3, name: 'Lines', subType:'Lines', type: 'Jersey', imageUrl: '/KitCreator/jersey/base/LINES.png', color: '#000000'},
    { id: 132, name: 'Logo Tag', subType:'Logo Tag', type: 'Jersey', imageUrl: '/KitCreator/jersey/base/Logo_Tag.png', color: '#000000'},
    { id: 4, name: 'Nameplate', subType:'Nameplate', type: 'Jersey', imageUrl: '/KitCreator/jersey/base/NAMEPLATE.png', color: '#000000'},
    { id: 133, name: 'Nameplate 2', subType:'Nameplate', type: 'Jersey', imageUrl: '/KitCreator/jersey/base/NAMEPLATE_2.png', color: '#000000'},
    { id: 5, name: 'Pro Bowl Spokes', subType:'Spokes', type: 'Jersey', imageUrl: '/KitCreator/jersey/base/PRO_BOWL.png', color: '#000000'},
    { id: 6, name: 'Reebok', subType:'Lines', type: 'Jersey', imageUrl: '/KitCreator/jersey/base/REEBOK.png', color: '#000000'},
    { id: 7, name: 'Titans', subType:'Spokes', type: 'Jersey', imageUrl: '/KitCreator/jersey/base/TITANS.png', color: '#000000'},

    { id: 12, name: 'Nike', subType:'Chest', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/NIKE.png', previewUrl: '/KitCreator/preview/branding/nike.png', color: '#000000'},
    { id: 11, name: 'Nike', subType:'Sleeves', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/NIKE_SLEEVES.png', previewUrl: '/KitCreator/preview/branding/nike.png', color: '#000000'},
    // { id: 10, name: 'Nike Chest', subType:'Brand', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/NIKE_CHEST.png', previewUrl: '/KitCreator/preview/branding/nike.png', color: '#000000'},
    { id: 8, name: 'Adidas', subType:'Chest', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/ADIDAS.png', previewUrl: '/KitCreator/preview/branding/adidas.png', color: '#000000'},
    { id: 9, name: 'Jordan', subType:'Chest', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/JORDAN.png', previewUrl: '/KitCreator/preview/branding/jordan.png', color: '#000000'},
    { id: 13, name: 'Under Armour', subType:'Brand', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/UA.png',  previewUrl: '/KitCreator/preview/branding/ua.png',color: '#000000'},
    { id: 115, name: 'Logo Athletic', subType:'Sleeves', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/LogoAthletic.png',  previewUrl: '/KitCreator/preview/branding/logoathletic.png',color: '#000000'},
    { id: 116, name: 'Puma', subType:'Sleeves', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/Puma.png', previewUrl: '/KitCreator/preview/branding/puma.png', color: '#000000'},
    { id: 117, name: 'Reebok', subType:'Sleeves', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/Reebok.png', previewUrl: '/KitCreator/preview/branding/reebok.png', color: '#000000'},
    { id: 118, name: 'Starter', subType:'Sleeves', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/Starter.png', previewUrl: '/KitCreator/preview/branding/starter.png', color: '#000000'},
    { id: 119, name: 'Wilson', subType:'Sleeves', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/Wilson.png', previewUrl: '/KitCreator/preview/branding/wilson.png', color: '#000000'},
    
    { id: 120, name: 'Adidas', subType:'Chest', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/Adidas_Alt.png', previewUrl: '/KitCreator/preview/branding/Adidas_Alt.png', color: '#000000'},
    { id: 121, name: 'Converse', subType:'Chest', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/Converse.png', previewUrl: '/KitCreator/preview/branding/Converse.png', color: '#000000'},
    { id: 122, name: 'Jordan Wings', subType:'Chest', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/Jordan_Alt1.png', previewUrl: '/KitCreator/preview/branding/Jordan_Alt1.png', color: '#000000'},
    { id: 123, name: 'Jordan 23', subType:'Chest', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/Jordan_Alt2.png', previewUrl: '/KitCreator/preview/branding/Jordan_Alt2.png', color: '#000000'},
    { id: 124, name: 'Mamba', subType:'Chest', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/Kobe.png', previewUrl: '/KitCreator/preview/branding/Kobe.png', color: '#000000'},
    { id: 125, name: 'New Balance', subType:'Chest', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/NewBalance.png', previewUrl: '/KitCreator/preview/branding/NewBalance.png', color: '#000000'},
    { id: 126, name: 'Nike Outline', subType:'Chest', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/Nike_Alt1.png', previewUrl: '/KitCreator/preview/branding/Nike_Alt1.png', color: '#000000'},
    { id: 127, name: 'Nike Air', subType:'Chest', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/Nike_Alt2.png', previewUrl: '/KitCreator/preview/branding/Nike_Alt2.png', color: '#000000'},
    { id: 128, name: 'Nike Japan', subType:'Chest', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/Nike_Alt3.png', previewUrl: '/KitCreator/preview/branding/Nike_Alt3.png', color: '#000000'},
    { id: 129, name: 'Reebok', subType:'Chest', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/Reebok_Chest.png', previewUrl: '/KitCreator/preview/branding/Reebok_Alt.png', color: '#000000'},
    { id: 130, name: 'Russel', subType:'Chest', type: 'Branding', imageUrl: '/KitCreator/jersey/branding/Russel.png', previewUrl: '/KitCreator/preview/branding/Russel.png', color: '#000000'},

    { id: 17, name: 'Undershirt', subType:'Undershirt', type: 'Neck', imageUrl: '/KitCreator/jersey/neck/UNDERSHIRT.png', color: '#000000'},
    { id: 14, name: 'Aviator', subType:'Chest Pattern', type: 'Neck', imageUrl: '/KitCreator/jersey/neck/AVIATOR.png', color: '#000000'},
    { id: 15, name: 'Colored 1', subType:'Collar', type: 'Neck', imageUrl: '/KitCreator/jersey/neck/COLORED.png', color: '#000000'},
    { id: 46, name: 'Colored 2', subType:'Collar', type: 'Neck', imageUrl: '/KitCreator/jersey/neck/COLORED_2.png', color: '#000000'},
    { id: 47, name: 'Colored 3', subType:'Collar', type: 'Neck', imageUrl: '/KitCreator/jersey/neck/COLORED_3.png', color: '#000000'},
    { id: 48, name: 'Colored 4', subType:'Collar', type: 'Neck', imageUrl: '/KitCreator/jersey/neck/COLORED-4.png', color: '#000000'},
    { id: 57, name: 'U', subType:'Collar', type: 'Neck', imageUrl: '/KitCreator/jersey/neck/U.png', color: '#000000'},
    { id: 45, name: 'Classic', subType:'Collar', type: 'Neck', imageUrl: '/KitCreator/jersey/neck/CLASSIC.png', color: '#000000'},
    { id: 56, name: 'Thin Collar', subType:'Collar', type: 'Neck', imageUrl: '/KitCreator/jersey/neck/THIN_COLLAR.png', color: '#000000'},
    { id: 54, name: 'Thin 1', subType:'Collar', type: 'Neck', imageUrl: '/KitCreator/jersey/neck/THIN.png', color: '#000000'},
    { id: 55, name: 'Thin 2', subType:'Collar', type: 'Neck', imageUrl: '/KitCreator/jersey/neck/THIN_2.png', color: '#000000'},
    { id: 42, name: 'Aviator', subType:'Chest Pattern', type: 'Neck', imageUrl: '/KitCreator/jersey/neck/AVIATOR.png', color: '#000000'},
    { id: 43, name: 'Black Knights', subType:'Chest Pattern', type: 'Neck', imageUrl: '/KitCreator/jersey/neck/BK.png', color: '#000000'},
    { id: 44, name: 'Bulldog', subType:'Collar', type: 'Neck', imageUrl: '/KitCreator/jersey/neck/BULLDOG.png', color: '#000000'},
    { id: 49, name: 'FSU', subType:'Collar', type: 'Neck', imageUrl: '/KitCreator/jersey/neck/FSU.png', color: '#000000'},
    { id: 50, name: 'Jaguars', subType:'Collar', type: 'Neck', imageUrl: '/KitCreator/jersey/neck/JAGS.png', color: '#000000'},
    { id: 51, name: 'Seahawks', subType:'Collar', type: 'Neck', imageUrl: '/KitCreator/jersey/neck/SEAHAWKS.png', color: '#000000'},
    { id: 52, name: 'Stars', subType:'Collar', type: 'Neck', imageUrl: '/KitCreator/jersey/neck/STARS.png', color: '#000000'},
    { id: 53, name: 'TCU', subType:'Collar', type: 'Neck', imageUrl: '/KitCreator/jersey/neck/TCU.png', color: '#000000'},
    { id: 114, name: 'Vapor Fusion', subType:'Collar', type: 'Neck', imageUrl: '/KitCreator/jersey/neck/VAPOR_FUSION.png', color: '#000000'},

    { id: 21, name: '49ers', subType:'Stripes', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/NINERS.png', color: '#000000'},
    { id: 134, name: '49ers Filled', subType:'Stripes', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/NINERS_FILLED.png', color: '#000000'},
    { id: 18, name: 'Colts', subType:'Stripes', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/COLTS.png', color: '#000000'},
    { id: 98, name: 'Colored Sleeves', subType:'Style 1 Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/SLEEVE_2.png', color: '#000000'},
    // { id: 99, name: 'Colored Sleeves', subType:'Style 2 Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/SLEEVE_3.png', color: '#000000'},
     { id: 100, name: 'Colored Sleeves', subType:'Style 2 Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/SLEEVE_COLORED.png', color: '#000000'},
    //{ id: 54, name: 'All Madden', subType:'Shoulders & Chest', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/ALL_MADDEN.png', color: '#000000'},
    { id: 55, name: 'Bengals', subType:'Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/BENGALS_CURRENT.png', color: '#000000'},
    { id: 56, name: 'Bengal Stripes', subType:'Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/BENGALS_CUSTOM.png', color: '#000000'},
    { id: 57, name: 'Bills Horns', subType:'Sleeve & Chest', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/BILLS_HORNS.png', color: '#000000'},
    { id: 58, name: 'Boise State', subType:'Style 1 Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/BOISE_STATE.png', color: '#000000'},
    { id: 59, name: 'Boise State', subType:'Style 2 Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/BOISE_STATE_2.png', color: '#000000'},
    { id: 60, name: 'Cardinals', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/CARDINALS_REEBOK.png', color: '#000000'},
    { id: 61, name: 'Cardinals Head', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/CARDINALS_HEAD.png', color: '#000000'},
    { id: 62, name: 'Chargers', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/CHARGERS.png', color: '#000000'},
    { id: 63, name: 'Checkered', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/CHECKERED.png', color: '#000000'},
    { id: 64, name: 'Bears', subType:'Classic Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/CLASSIC_BEARS.png', color: '#000000'},
    { id: 65, name: 'Rams', subType:'Classic Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/CLASSIC_RAMS.png', color: '#000000'},
    { id: 66, name: 'Claws', subType:'Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/CLAWS.png', color: '#000000'},
    { id: 67, name: 'Clemson', subType:'Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/CLEMSON.png', color: '#000000'},
    { id: 68, name: 'Rams', subType:'Concept Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/CONCEPT_RAMS.png', color: '#000000'},
    { id: 69, name: 'Condors', subType:'Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/CONDOR.png', color: '#000000'},
    { id: 70, name: 'Rams Away', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/CURRENT_RAMS_AWAY.png', color: '#000000'},
    { id: 71, name: 'Rams', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/CURRENT_RAMS.png', color: '#000000'},
    { id: 72, name: 'Diablos', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/DIABLOS.png', color: '#000000'},
    { id: 73, name: 'Dots', subType:'Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/DOTS.png', color: '#000000'},
    { id: 74, name: 'Feathers', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/FEATHERS.png', color: '#000000'},
    { id: 20, name: 'Fire', subType:'Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/FIRE.png', color: '#000000'},
    { id: 75, name: 'Filled', subType:'Style 1 Sleeves & Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/FILLED_2.png', color: '#000000'},
    { id: 76, name: 'Filled', subType:'Style 2 Sleeves & Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/FILLED_3.png', color: '#000000'},
    { id: 77, name: 'Filled', subType:'Style 3 Sleeves & Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/FILLED_4.png', color: '#000000'},
    { id: 78, name: 'Filled', subType:'Style 4 Sleeves & Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/FILLED_5.png', color: '#000000'},
    //{ id: 79, name: 'Filled', subType:'Style 6 Sleeves & Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/FILLED_6.png', color: '#000000'},
    //{ id: 80, name: 'Filled', subType:'Style 7 Sleeves & Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/FILLED_7.png', color: '#000000'},
    { id: 19, name: 'Filled', subType:'Style 5 Sleeves & Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/FILLED.png', color: '#000000'},
    //{ id: 81, name: 'Gamblers', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/GAMBLERS.png', color: '#000000'},
    { id: 82, name: 'Geometric Shapes', subType:'Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/GEOMETRIC_SHAPES.png', color: '#000000'},
    { id: 83, name: 'Grunge', subType:'Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/GRUNGE.png', color: '#000000'},
    { id: 84, name: 'Jets', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/JETS.png', color: '#000000'},
    { id: 85, name: 'Lightning', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/LIGHTNING.png', color: '#000000'},
    { id: 86, name: 'Louisville', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/LOUISVILLE.png', color: '#000000'},
    { id: 87, name: 'MSU', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/MSU.png', color: '#000000'},
    { id: 88, name: 'Ohio U', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/OHIOU.png', color: '#000000'},
    // { id: 89, name: 'Oregon', subType:'Style 1 Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/OREGON_1.png', color: '#000000'},
    { id: 90, name: 'Oregon', subType:'Style 2 Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/OREGON_2.png', color: '#000000'},
    { id: 91, name: 'Patrtiots 2012', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/PATS_2012.png', color: '#000000'},
    { id: 92, name: 'Purdue', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/PURDUE.png', color: '#000000'},
    { id: 93, name: 'Seahawks', subType:'Sleeves & Chest', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/SEAHAWKS.png', color: '#000000'},
    { id: 94, name: 'Shapes', subType:'Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/SHAPES.png', color: '#000000'},
    { id: 95, name: 'Shoulder Stripes', subType:'Style 1 Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/SHOULDER_2.png', color: '#000000'},
    { id: 96, name: 'Shoulder Stripes', subType:'Style 2 Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/SHOULDER_3.png', color: '#000000'},
    { id: 97, name: 'Shoulder Stripes', subType:'Style 3 Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/SHOULDER_4.png', color: '#000000'},
    { id: 101, name: 'Stars', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/STARS_1.png', color: '#000000'},
    { id: 102, name: 'Stars', subType:'Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/STARS_2.png', color: '#000000'},
    { id: 104, name: 'Stripe', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/STRIPES_2.png', color: '#000000'},
    { id: 105, name: 'Sway', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/SWAY.png', color: '#000000'},
    { id: 23, name: 'Texans', subType:'Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/TEXANS.png', color: '#000000'},
    { id: 24, name: 'Thin', subType:'Stripes', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/THIN.png', color: '#000000'},
    { id: 106, name: 'Thin Stripes', subType:'Style 1 Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/THIN_STRIPES.png', color: '#000000'},
    { id: 107, name: 'Thin Stripes', subType:'Style 2 Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/THIN_STRIPES_2.png', color: '#000000'},
    { id: 108, name: 'Tight Shoulders', subType:'Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/TIGHT_SHOULDER.png', color: '#000000'},
    { id: 109, name: 'Two Tone', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/TWO_TONE.png', color: '#000000'},
    { id: 110, name: 'UA', subType:'Shoulders', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/UA.png', color: '#000000'},
    { id: 25, name: 'UConn', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/UCONN.png', color: '#000000'},
    { id: 111, name: 'Vertical Lines', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/VERTICAL_LINES.png', color: '#000000'},
    { id: 112, name: 'Vikings', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/VIKINGS.png', color: '#000000'},
    { id: 113, name: 'Wings', subType:'Sleeves', type: 'Sleeve', imageUrl: '/KitCreator/jersey/shoulders/WINGS.png', color: '#000000'},
    // Add more asset objects as needed

    { id: 143, name: 'Fade', subType:'Base', type: 'Jersey', imageUrl: '/KitCreator/jersey/base/FADE.png', color: '#000000'},

  ]

export const allPantAssetsData = [
    // { id: 500, name: 'Base', subType:'Pants', type: 'Pants', imageUrl: '/KitCreator/pants/base/base.png', color: '#000000'},

    { id: 518, name: 'Nike', subType:'Branding', type: 'Branding', imageUrl: '/KitCreator/pants/branding/nike.png', previewUrl: '/KitCreator/preview/branding/nike.png', color: '#000000'}, 
    { id: 528, name: 'Adidas', subType:'Branding', type: 'Branding', imageUrl: '/KitCreator/pants/branding/adidas.png', previewUrl: '/KitCreator/preview/branding/adidas.png', color: '#000000'}, 
    { id: 529, name: 'Jordan', subType:'Branding', type: 'Branding', imageUrl: '/KitCreator/pants/branding/jordan.png', previewUrl: '/KitCreator/preview/branding/jordan.png', color: '#000000'}, 
    { id: 530, name: 'Logo Athletic', subType:'Branding', type: 'Branding', imageUrl: '/KitCreator/pants/branding/logoathletic.png', previewUrl: '/KitCreator/preview/branding/logoathletic.png', color: '#000000'}, 
    { id: 531, name: 'Puma', subType:'Branding', type: 'Branding', imageUrl: '/KitCreator/pants/branding/puma.png', previewUrl: '/KitCreator/preview/branding/puma.png', color: '#000000'}, 
    { id: 532, name: 'Reebok', subType:'Branding', type: 'Branding', imageUrl: '/KitCreator/pants/branding/reebok.png', previewUrl: '/KitCreator/preview/branding/reebok.png', color: '#000000'}, 
    { id: 533, name: 'Starter', subType:'Branding', type: 'Branding', imageUrl: '/KitCreator/pants/branding/starter.png', previewUrl: '/KitCreator/preview/branding/starter.png', color: '#000000'}, 
    { id: 534, name: 'Under Armour', subType:'Branding', type: 'Branding', imageUrl: '/KitCreator/pants/branding/ua.png', previewUrl: '/KitCreator/preview/branding/ua.png', color: '#000000'}, 
    { id: 535, name: 'Wilson', subType:'Branding', type: 'Branding', imageUrl: '/KitCreator/pants/branding/wilson.png', previewUrl: '/KitCreator/preview/branding/wilson.png', color: '#000000'}, 
    { id: 536, name: 'Adidas', subType:'Branding', type: 'Branding', imageUrl: '/KitCreator/pants/branding/Adidas_Alt.png', previewUrl: '/KitCreator/preview/branding/Adidas_Alt.png', color: '#000000'},
    { id: 537, name: 'Converse', subType:'Branding', type: 'Branding', imageUrl: '/KitCreator/pants/branding/Converse.png', previewUrl: '/KitCreator/preview/branding/Converse.png', color: '#000000'},
    { id: 538, name: 'Jordan Wings', subType:'Branding', type: 'Branding', imageUrl: '/KitCreator/pants/branding/Jordan_Alt1.png', previewUrl: '/KitCreator/preview/branding/Jordan_Alt1.png', color: '#000000'},
    { id: 539, name: 'Jordan 23', subType:'Branding', type: 'Branding', imageUrl: '/KitCreator/pants/branding/Jordan_Alt2.png', previewUrl: '/KitCreator/preview/branding/Jordan_Alt2.png', color: '#000000'},
    { id: 540, name: 'Mamba', subType:'Branding', type: 'Branding', imageUrl: '/KitCreator/pants/branding/Kobe.png', previewUrl: '/KitCreator/preview/branding/Kobe.png', color: '#000000'},
    { id: 541, name: 'New Balance', subType:'Branding', type: 'Branding', imageUrl: '/KitCreator/pants/branding/NewBalance.png', previewUrl: '/KitCreator/preview/branding/NewBalance.png', color: '#000000'},
    { id: 542, name: 'Nike Outline', subType:'Branding', type: 'Branding', imageUrl: '/KitCreator/pants/branding/Nike_Alt1.png', previewUrl: '/KitCreator/preview/branding/Nike_Alt1.png', color: '#000000'},
    { id: 543, name: 'Nike Air', subType:'Branding', type: 'Branding', imageUrl: '/KitCreator/pants/branding/Nike_Alt2.png', previewUrl: '/KitCreator/preview/branding/Nike_Alt2.png', color: '#000000'},
    { id: 544, name: 'Nike Japan', subType:'Branding', type: 'Branding', imageUrl: '/KitCreator/pants/branding/Nike_Alt3.png', previewUrl: '/KitCreator/preview/branding/Nike_Alt3.png', color: '#000000'},
    { id: 545, name: 'Reebok', subType:'Branding', type: 'Branding', imageUrl: '/KitCreator/pants/branding/Reebok_Alt.png', previewUrl: '/KitCreator/preview/branding/Reebok_Alt.png', color: '#000000'},
    { id: 546, name: 'Russel', subType:'Branding', type: 'Branding', imageUrl: '/KitCreator/pants/branding/Russel.png', previewUrl: '/KitCreator/preview/branding/Russel.png', color: '#000000'},

    { id: 501, name: 'NFL', subType:'Pro', type: 'League', imageUrl: '/KitCreator/pants/league/NFL.png',previewUrl: '/KitCreator/preview/league/NFL.png', color: '#000000'},
    { id: 502, name: 'CFL', subType:'Pro', type: 'League', imageUrl: '/KitCreator/pants/league/CFL.png',previewUrl: '/KitCreator/preview/league/CFL.png',  color: '#000000'},
    { id: 503, name: 'XFL', subType:'Pro', type: 'League', imageUrl: '/KitCreator/pants/league/XFL.png',previewUrl: '/KitCreator/preview/league/XFL.png',  color: '#000000', canColor: true},
    { id: 504, name: 'NFL', subType:'Concept', type: 'League', imageUrl: '/KitCreator/pants/league/NFL_CONCEPT.png', previewUrl: '/KitCreator/preview/league/NFL_CONCEPT.png', color: '#000000'},
    { id: 505, name: 'NFL', subType:'Retro', type: 'League', imageUrl: '/KitCreator/pants/league/NFL_RETRO.png',previewUrl: '/KitCreator/preview/league/NFL_CLASSIC.png',  color: '#000000'},
    { id: 506, name: 'IFL', subType:'Arena', type: 'League', imageUrl: '/KitCreator/pants/league/IFL.png',previewUrl: '/KitCreator/preview/league/IFL.png',  color: '#000000'},
    { id: 507, name: 'MLF', subType:'JB83', type: 'League', imageUrl: '/KitCreator/pants/league/MLF.png',previewUrl: '/KitCreator/preview/league/MLF.png',  color: '#000000'},
    { id: 508, name: 'FFL', subType:'FFC', type: 'League', imageUrl: '/KitCreator/pants/league/FFL.png',previewUrl: '/KitCreator/preview/league/FFL.png',  color: '#000000'},
    { id: 509, name: 'ACC', subType:'College', type: 'League', imageUrl: '/KitCreator/pants/league/COLLEGE_ACC.png',previewUrl: '/KitCreator/preview/league/ACC.png',  color: '#000000'},
    { id: 510, name: 'American', subType:'College', type: 'League', imageUrl: '/KitCreator/pants/league/COLLEGE_AMERICAN.png',previewUrl: '/KitCreator/preview/league/AMERICAN.png',  color: '#000000'},
    { id: 511, name: 'BIG 10', subType:'College', type: 'League', imageUrl: '/KitCreator/pants/league/BIG10_COLLEGE.png',previewUrl: '/KitCreator/preview/league/BIG10.png',  color: '#000000', canColor: true},
    { id: 512, name: 'BIG 12', subType:'College', type: 'League', imageUrl: '/KitCreator/pants/league/BIG12_COLLEGE.png',previewUrl: '/KitCreator/preview/league/BIG12.png',  color: '#000000'},
    { id: 513, name: 'C USA', subType:'College', type: 'League', imageUrl: '/KitCreator/pants/league/COLLEGE_CUSA.png',previewUrl: '/KitCreator/preview/league/CUSA.png',  color: '#000000', canColor: true},
    { id: 514, name: 'MAC', subType:'College', type: 'League', imageUrl: '/KitCreator/pants/league/MAC_COLLEGE.png',previewUrl: '/KitCreator/preview/league/MAC.png',  color: '#000000'},
    { id: 515, name: 'Mountain West', subType:'College', type: 'League', imageUrl: '/KitCreator/pants/league/MW_COLLEGE.png',previewUrl: '/KitCreator/preview/league/MW.png',  color: '#000000'}, 
    { id: 516, name: 'PAC 12', subType:'College', type: 'League', imageUrl: '/KitCreator/pants/league/PAC12_COLLEGE.png',previewUrl: '/KitCreator/preview/league/PAC12.png',  color: '#000000'},
    { id: 517, name: 'SEC', subType:'College', type: 'League', imageUrl: '/KitCreator/pants/league/COLLEGE_SEC.png',previewUrl: '/KitCreator/preview/league/SEC.png',  color: '#000000'}, 

    { id: 519, name: 'Bengals', subType:'Stripes', type: 'Stripes', imageUrl: '/KitCreator/pants/stripes/BENGALS.png', color: '#000000'}, 
    { id: 520, name: 'Bolts', subType:'Stripes', type: 'Stripes', imageUrl: '/KitCreator/pants/stripes/BOLTS.png', color: '#000000'}, 
    { id: 521, name: 'Dual', subType:'Stripes', type: 'Stripes', imageUrl: '/KitCreator/pants/stripes/DUAL_STRIPES.png', color: '#000000'}, 
    { id: 522, name: 'Full', subType:'Stripes', type: 'Stripes', imageUrl: '/KitCreator/pants/stripes/FULL.png', color: '#000000'}, 
    { id: 523, name: 'Half', subType:'Stripes', type: 'Stripes', imageUrl: '/KitCreator/pants/stripes/HALF_STRIPES.png', color: '#000000'}, 
    { id: 524, name: 'Low', subType:'Stripes', type: 'Stripes', imageUrl: '/KitCreator/pants/stripes/LOW_STRIPES.png', color: '#000000'}, 
    { id: 525, name: 'Swords', subType:'Stripes', type: 'Stripes', imageUrl: '/KitCreator/pants/stripes/SWORDS.png', color: '#000000'}, 
    { id: 526, name: 'Raptor', subType:'Stripes', type: 'Stripes', imageUrl: '/KitCreator/pants/stripes/RAPTOR.png', color: '#000000'}, 
    { id: 527, name: 'Thin', subType:'Stripes', type: 'Stripes', imageUrl: '/KitCreator/pants/stripes/THIN.png', color: '#000000'}, 
    
    { id: 547, name: 'Belt', subType:'Base', type: 'Base', imageUrl: '/KitCreator/pants/base/belt.png', color: '#000000'},
  ]

export const allJerseyNormsData =[
      // { id: 500, name: 'Base', subType:'Pants', type: 'Pants', imageUrl: '/KitCreator/pants/base/base.png', color: '#000000'},
      {id: 1000, name: 'Type 1', subType:'Norm', type: 'Norm', imageUrl: '/KitCreator/jersey/norm/norm.png'}, 
      {id: 1001, name: 'Type 2', subType:'Norm', type: 'Norm', imageUrl: '/KitCreator/jersey/norm/UA.png'}, 
    ]

export const allPantsNormsData = [
      // { id: 500, name: 'Base', subType:'Pants', type: 'Pants', imageUrl: '/KitCreator/pants/base/base.png', color: '#000000'},
      {id: 1200, name: 'Type 1', subType:'Norm', type: 'Norm', imageUrl: '/KitCreator/pants/norm/norm.png'}, 
      {id: 1201, name: 'Type 2', subType:'Norm', type: 'Norm', imageUrl: '/KitCreator/pants/norm/broncos.png'}, 
    ]

export const allHelmetAssetsData = [
    { id: 1504, name: 'Thin Stripe', subType:'Stripe', type: 'Stripe', imageUrl: '/KitCreator/helmet/stripes/Thin_Stripe.png', color: '#000000'}, 
    { id: 1513, name: 'Thick Stripe', subType:'Stripe', type: 'Stripe', imageUrl: '/KitCreator/helmet/stripes/Thick_Stripe.png', color: '#000000'}, //biggest
    { id: 1505, name: 'Aviator', subType:'Wings', type: 'Base', imageUrl: '/KitCreator/helmet/base/Aviator.png', color: '#000000'}, 
    { id: 1506, name: 'Rams', subType:'Horns', type: 'Base', imageUrl: '/KitCreator/helmet/base/Rams.png', color: '#000000'}, 
    { id: 1507, name: 'Bengals', subType:'Stripes', type: 'Stripe', imageUrl: '/KitCreator/helmet/stripes/Bengals.png', color: '#000000'}, 
    { id: 1508, name: 'Broncos', subType:'Stripe', type: 'Stripe', imageUrl: '/KitCreator/helmet/stripes/Broncos.png', color: '#000000'}, 
    { id: 1510, name: 'Panthers', subType:'Stripe', type: 'Stripe', imageUrl: '/KitCreator/helmet/stripes/Panthers.png', color: '#000000'}, 
    { id: 1511, name: 'Ravens', subType:'Stripe', type: 'Stripe', imageUrl: '/KitCreator/helmet/stripes/Ravens.png', color: '#000000'}, 

    { id: 1512, name: 'Back Arrow', subType:'Stripe', type: 'Stripe', imageUrl: '/KitCreator/helmet/stripes/backarrow.png', color: '#000000'}, 
    { id: 1519, name: 'Checkered', subType:'Stripe', type: 'Stripe', imageUrl: '/KitCreator/helmet/stripes/Checkered.png', color: '#000000'}, 
    { id: 1514, name: 'Eagles', subType:'Stripe', type: 'Base', imageUrl: '/KitCreator/helmet/stripes/Eagle1.png', color: '#000000'}, 
    { id: 1515, name: 'Eagles', subType:'Stripe', type: 'Base', imageUrl: '/KitCreator/helmet/stripes/Eag2.png', color: '#000000'}, 
    { id: 1516, name: 'Eagles', subType:'Stripe', type: 'Base', imageUrl: '/KitCreator/helmet/stripes/Eag3.png', color: '#000000'}, 
    { id: 1517, name: 'Stripe Outline', subType:'Stripe', type: 'Stripe', imageUrl: '/KitCreator/helmet/stripes/StripeOutline.png', color: '#000000'}, 
    { id: 1518, name: 'Thick Inner Stripe', subType:'Stripe', type: 'Stripe', imageUrl: '/KitCreator/helmet/stripes/ThickInnerStripe.png', color: '#000000'}, 

    { id: 1520, name: 'Camo', subType:'Texture', type: 'Texture', imageUrl: '/KitCreator/helmet/texture/Camo.png', color: '#000000'}, 
    { id: 1521, name: 'Flowers', subType:'Texture', type: 'Texture', imageUrl: '/KitCreator/helmet/texture/Flowers.png', color: '#000000'}, 
    { id: 1522, name: 'Fade', subType:'Base', type: 'Base', imageUrl: '/KitCreator/helmet/base/fade.png', color: '#000000'}, 

  ]

export const allSockAssetsData = [
    { id: 2001, name: 'Half Sock', subType:'Base', type: 'Base', imageUrl: '/KitCreator/socks/base/HalfSock.png', color: '#000000'}, 
    { id: 2002, name: 'Three Stripes', subType:'Stripes', type: 'Stripes', imageUrl: '/KitCreator/socks/stripes/3stripe.png', color: '#000000'}, 
    { id: 2003, name: 'Dual Stripes', subType:'Stripes', type: 'Stripes', imageUrl: '/KitCreator/socks/stripes/DualStripes.png', color: '#000000'}, 
    { id: 2004, name: 'Large Stripe', subType:'Stripes', type: 'Stripes', imageUrl: '/KitCreator/socks/stripes/LargeStripe.png', color: '#000000'}, 
    { id: 2005, name: 'Medium Stripe', subType:'Stripes', type: 'Stripes', imageUrl: '/KitCreator/socks/stripes/MediumStripe.png', color: '#000000'}, 
    { id: 2006, name: 'Thin Stripe', subType:'Stripes', type: 'Stripes', imageUrl: '/KitCreator/socks/stripes/ThinStripe.png', color: '#000000'}, 

  ]