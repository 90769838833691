import React, { useEffect, useRef } from 'react';
import './Home.css';

function Home() {
  const imageRef = useRef(null);

  useEffect(() => {
    const imageElement = imageRef.current;

    const handleLoad = () => {
      imageElement.classList.add('animate');
    };

    imageElement.addEventListener('load', handleLoad);

    return () => {
      imageElement.removeEventListener('load', handleLoad);
    };
  }, []);

  const getRandomImage = () => {
    const imagePaths = [
       "/Graphics/polaroid/ffxGlobe.png",
       "/Graphics/polaroid/ffx3.png",
       "/Graphics/polaroid/q2.png",
       "/Graphics/polaroid/ffxGlobe2.png",
       "/Graphics/polaroid/ffxGlobe3.png",
       "/Graphics/polaroid/ffxGlobe4.png",
       "/Graphics/polaroid/ffxGlobe5.png",

    ];
    const randomIndex = Math.floor(Math.random() * imagePaths.length);
    return imagePaths[randomIndex];
  };

  const imagePath = getRandomImage();

  return (
    <div className="home-container">
      <img
        ref={imageRef}
        src={imagePath}
        alt="alternate"
        className="rising-image"
      />
    </div>
  );
}

export default Home;

