import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const LeagueList = () => {
  const [leagues, setLeagues] = useState([]);
  const [allLeagues, setAllLeagues] = useState([]);
  const [dataUser, setUser] = useState([]);

  const [isJoinPopupOpen, setJoinPopupOpen] = useState(false);
  const [leagueNameInputValue, setLeagueNameInputValue] = useState('');
  const [uniqueIdInputValue, setUniqueIdInputValue] = useState('');

  const handleJoinLeague = async (leagueName, uniqueId) => {
    const matchingLeague = allLeagues.find((league) => league.league_name === leagueName && league.unique_id === uniqueId);

    if (matchingLeague) {
      // Update the league's user_data with the new user information
      const newUser = { user_uuid: dataUser.uuid, scouts: [], favorites: [], percentages: [] };
      matchingLeague.user_data.push(newUser);

      const csrfTokenElement = document.querySelector('[name=csrfmiddlewaretoken]');
      const csrfToken = csrfTokenElement ? csrfTokenElement.value : null;
      try {
        // Make a POST request to update the league data on the server
        await fetch(`http://localhost:8000/api/update_league/${matchingLeague.unique_id}/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          },
          body: JSON.stringify({ user_data: matchingLeague.user_data }),
        });
  
        // Update the leagues state with the modified data
        //let newLeagues = allLeagues.map((league) => (league.id === matchingLeague.id ? matchingLeague : league))
        let filteredData = allLeagues.filter((league) =>
          league.user_data.some((user) => user.user_uuid === dataUser.uuid)
        );
        setLeagues(filteredData);
    
        // After joining, close the popup
        setJoinPopupOpen(false);
      } catch (error) {
        // Handle errors during the POST request
        console.error('Error updating league data:', error);
      }


      // Update the leagues state with the modified data

      // After joining, close the popup
      setJoinPopupOpen(false);
    } else {
      // Handle the case when no matching league is found
      console.log('League not found with the provided name and unique ID');
    }
    setJoinPopupOpen(false);
  };

  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        const response = await fetch('http://localhost:8000/api/league/');
        const data = await response.json();
        setAllLeagues(data)

        const responseUser = await fetch('http://localhost:8000/api/current_user/', {
          credentials: 'include', // Include credentials (cookies) in the request
        });
        const dataUser = await responseUser.json();
        setUser(dataUser)

        let filteredData = data.filter((league) =>
        league.user_data.some((user) => user.user_uuid === dataUser.uuid)
        );
        setLeagues(filteredData);
        //console.log(filteredData, data)
      } catch (error) {
        console.error('Error fetching leagues:', error);
      }
    };

    fetchLeagues();
  }, []);

  return (
    <div style={{ paddingTop: '50px' }}>

      <h2>Leagues</h2>
      <button onClick={() => setJoinPopupOpen(true)}>Join League</button>
 {/* Popup */}
 {isJoinPopupOpen && (
        <div className="join-popup">
          <div className="popup-content">
            <h2>Join League</h2>
            <input
              type="text"
              placeholder="League Name"
              value={leagueNameInputValue}
              onChange={(e) => setLeagueNameInputValue(e.target.value)}
            />
            <input
              type="text"
              placeholder="Unique ID"
              value={uniqueIdInputValue}
              onChange={(e) => setUniqueIdInputValue(e.target.value)}
            />
            <button onClick={() => handleJoinLeague(leagueNameInputValue,uniqueIdInputValue)}>Join</button>
            <button onClick={() => setJoinPopupOpen(false)}>Close</button>
          </div>
        </div>
      )}
      <div className="league-list">
        {leagues.map((league) => (
          <div key={league.id} className="league-card">
            <h3>{league.league_name}</h3>
            <Link to={`/skillspotter/leagues/${league.unique_id}`}>Go to League</Link>
            {/* Add more details or actions if needed */}
          </div>
        ))}
      </div>

     
    </div>
  );
};

export default LeagueList;
