import React, { useState, useEffect } from 'react';
import './RFL.css';
import { API_URL } from './api.js';
import GameModule from './GameModule';

const RFL = () => {

    const [leagueData, setLeagueData] = useState(null);
    // const [playersOfTheWeek, setPlayersOfTheWeek] = useState(null);
    const [standings, setStandings] = useState(null);
    const [gameOfTheWeek, setGameOfTheWeek] = useState(null);

    useEffect(() => {
      const fetchLeagueData = async () => {
        try {
          const response = await fetch(API_URL);
          if (response.ok) {
            const data = await response.json();
            setLeagueData(data);
            const response2 = await fetch(API_URL+'games/?size=0&page=1&seasonIndex=4&stageIndex=1&isGameOfTheWeek=1&ordering=weekIndex&weekIndex=11');
            if (response2.ok) {
              const data2 = await response2.json();
              setGameOfTheWeek(data2);
            } else {
              throw new Error('Failed to fetch gotw data');
            }
          } else {
            throw new Error('Failed to fetch league data');
          }
        } catch (error) {
          console.error(error);
        }
      };
  
      const fetchPlayersOfTheWeek = async () => {
        try {
          const response = await fetch(API_URL+'potw');
          if (response.ok) {
            //const data = await response.json();
            //setPlayersOfTheWeek(data);
          } else {
            throw new Error('Failed to fetch potw data');
          }
        } catch (error) {
          console.error(error);
        }
      };

      const fetchStandings = async () => {
        try {
          const response = await fetch(API_URL+'teams/?size=0&ordering=-rank');
          if (response.ok) {
            const data = await response.json();
            setStandings(data);
          } else {
            throw new Error('Failed to fetch potw data');
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchLeagueData();
      fetchPlayersOfTheWeek();
      fetchStandings();
    }, []);

    //console.log(leagueData)

  return (
    <div className="rfl-container">
      <div className="logo-container">
        <img
         src="/RFL/rflLogo.png"
         alt="RFL Logo" 
         className="logo" />
        <p className="title">RFL</p>
      </div>
      {/* <p className="summary">
        The Rocket Football League (RFL) is a PC online Madden league that has been around since 2019. It provides an immersive gaming experience for Madden and Football enthusiasts, offering competitive gameplay and a dedicated community of players.
      </p> */}
      {/* <iframe src="https://discord.com/widget?id=998407818635915296&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe> */}
      {leagueData ? (
        <div>
            <p>
                Week {leagueData.week}  Year {leagueData.season}
            </p>
            <div>
            </div>
        </div>
      ) : (
        <p>Loading league data...</p>
      )}
      <div className="column-container">
      <div className="column">
      {standings ? (
    <div className="team-container">
  {standings
  .slice()
  .sort((a, b) => a.rank - b.rank) // Sort teams based on rank (ascending order)
    .map((team, index) => {    

      return (
        <div key={index} className="team-card">
          <img src={'https://cdn.neonsportz.com/teamlogos/256/'+team.logoId+'.png'} alt={team.teamName} className="team-logo" />
          <div className="team-info-container">
            <h2 className="team-name">{team.rank} {team.teamName}</h2>
            <p className="">{team.totalWins}-{team.totalLosses}{team.totalTies > 0 && `-${team.totalTies}`}</p>
            <b className="username">{team.userName}</b>
            {/* <p className="team-info">{team.totalWins}-{team.totalLosses}{team.totalTies > 0 && `-${team.totalTies}`}</p>
            <p className="team-info">{team.divWins}-{team.divLosses}{team.totalTies > 0 && `-${team.divTies}`} (Division)</p>
            <p>Prev Rank: {team.prevRank}</p>
            <p>OVR: {team.teamOvr}</p> */}
            {/* Add more relevant information here */}
          </div>
        </div>
      );
    })}
</div>

) : (
  <p>Loading league standings...</p>
)}
      </div>
      <div className="column">
        { gameOfTheWeek && 
      <GameModule gameOfTheWeek={gameOfTheWeek[0]} />
        }
      </div>
      <div className="column">Column 3</div>
    </div>
    </div>
  );
};

export default RFL;
