import React, { useState, useEffect } from 'react';
import chroma from "chroma-js";
import Anton from '../Fonts/Microsport.ttf';
import { FaThumbsUp } from "react-icons/fa";

function GOTW(){
    const [game, setGame] = useState([]);
    const [teamColors, setTeamColors] = useState({});

    useEffect(() => {
        fetch('https://neonsportz.com/api/leagues/PFL/gotw')
          .then(response => response.json())
          .then(data => {
            setGame(data);
            console.log(data)
            fetchTeamColors(data);
          })
          .catch(error => console.error('Error fetching rankings:', error));
      }, []);
    
      const fetchTeamColors = (data) => {
        const colors = {};
        
        const promise1 = fetch(`https://neonsportz.com/api/leagues/PFL/teams/${data.homeTeam.abbrName}`)
            .then(response => response.json())
            .then(teamData => {
              colors[data.homeTeam.abbrName] = {
                primaryColor: teamData.primaryColor,
                secondaryColor: teamData.secondaryColor
              };
            })
            .catch(error => console.error(`Error fetching team colors for ${data.homeTeam.abbrName}:`, error));
        const promise2 = fetch(`https://neonsportz.com/api/leagues/PFL/teams/${data.awayTeam.abbrName}`)
            .then(response => response.json())
            .then(teamData => {
              colors[data.awayTeam.abbrName] = {
                primaryColor: teamData.primaryColor,
                secondaryColor: teamData.secondaryColor
              };
            })
            .catch(error => console.error(`Error fetching team colors for ${data.awayTeam.abbrName}:`, error));
        

        const promises = [promise1, promise2]
        Promise.all(promises).then(() => {
          setTeamColors(colors);
        });
      };

    
    return <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <div style={{width: '1040px', height: '700px', border: '10px solid #25272a', marginTop: '70px', zIndex: 1}}>
    <div style={{width: '1040px', height: '700px', position: 'absolute',marginTop:'0px', zIndex: -1}}><img src='../Images/gotwoverlay.png' style={{}}/></div>

        {game && teamColors && game.homeTeam && game.homeTeam.displayName && <div style={{gridTemplateColumns: '1fr 1fr', display: 'grid', height: '100%'}}>
            {TeamBackground(game.awayTeam, teamColors, 'left', game.away_team_spread, game.winner_votes, false)}
            {TeamBackground(game.homeTeam, teamColors, 'right', game.home_team_spread, game.winner_votes, true)}
        </div>}
    </div>
    </div>
}

function TeamBackground(data, teamColors, textAlign, spread, winnerVotes, homeTeam) {
    if(spread>0){
        spread = '+'+spread
    }
    let votes = 0
    winnerVotes.map(vote => {
        if(vote.homeTeam === homeTeam){
            votes++
        }
        return null
    })
    const customFont = new FontFace('Anton', `url(${Anton}`);
    document.fonts.add(customFont);
    return(
        <div style={{zIndex:-2,  backgroundColor: teamColors[data.abbrName]?getColors(teamColors[data.abbrName], data.displayName).primaryColor:'', color: 'white', textAlign: textAlign, padding: '0 5px', overflow: 'hidden'}}>
            <div style={{zIndex:100}}>
                {/* {textAlign==="left"&& <div style={{ marginTop: '0px', width: '100%', height: '700px', display:'flex', justifyContent: 'right', marginBottom: '-700px'}}><img src='../Images/fireleft.png' style={{ height: '715px', marginRight: '-7.5px', marginTop: '-10px'}}/></div>} */}
                {/* {textAlign==="right"&& <div style={{ marginTop: '0px',  width: '100%', height: '700px', display:'flex', justifyContent: 'left', marginBottom: '-700px'}}><img src='../Images/fireright.png' style={{ height: '715px', marginLeft: '-7.5px', marginTop: '-10px'}}/></div>} */}
            </div>
            <img style={{width: '475px', height: '700px', borderRadius: '0', marginLeft: '0px', marginTop: '-50px', zIndex:-1, opacity: '10%', position: 'fixed'}} src={`https://cdn.neonsportz.com/teamlogos/256/${data.logoId}.png`} alt={data.teamName} className="team-logo" />

            <img style={{width: '450px', height: '450px', borderRadius: '0',  marginTop: '75px', zIndex:0, marginBottom: '125px'}} src={`https://cdn.neonsportz.com/teamlogos/256/${data.logoId}.png`} alt={data.teamName} className="team-logo" />

            
                    <div style={{backgroundColor: teamColors[data.abbrName]?getColors(teamColors[data.abbrName], data.displayName).darkerPrimaryColor+'99':'', zIndex: -1, color: 'white' ,  fontSize: '100%', height: '120px', marginLeft: '-5px',marginRight: '-5px', marginTop: '-80px', justifyContent: textAlign, padding:'10px', }}>
                    <div>{data.leaguemember && (
                    <div style={{display: 'flex', color: 'white' ,  fontSize: '100%', justifyContent: textAlign}}>
                        <img style={{ marginTop: '0px', width: '35px',height: '35px',  borderRadius: '10rem', marginRight: '5px'}} src={data.leaguemember.user.avatar_url} alt={`${data.displayName} Avatar`} />
                        <p style={{fontSize: '175%', fontWeight: 'bold', marginTop: '0px'}}>{data.leaguemember?data.leaguemember.user.username:'CPU'} ({data.totalWins}-{data.totalLosses}{data.totalTies>0&&'-'+data.totalTies}) </p>

                    </div>
                    )}</div>
                        <div style={{marginTop: '-10px'}}>
                            <div style={{marginBottom: '2.5px'}}>
                                {spread}
                            </div>
                            <div>
                             <FaThumbsUp/> {votes} Votes
                            </div>
                        </div>
                    </div>
            <div style={{
                position: 'fixed', 
                height: '700px',
                width: '100px',
                marginTop: '-714px',
                marginLeft: textAlign==="right"?'-5px':'415px',
                backgroundColor: teamColors[data.abbrName]?getColors(teamColors[data.abbrName], data.displayName).secondaryColor:''}}>
                </div>
            <div style={{ 
                // font: 'Anton',
                marginTop: '-400px', 
                marginLeft: textAlign==="right"?'-180px':'250px', 
                rotate: textAlign==="right"?'90deg':'-90deg', 
                width: 'fit-content', 
                textAlign: 'center', 
                fontWeight:'bold', 
                fontSize: '75px', 
                letterSpacing: '5px',}}>{data.displayName.toUpperCase()}</div>
                
        </div>
    )
}

const getColors = (teamInfo, displayName) => {
    let primaryColor = teamInfo.primaryColor ? '#' + parseInt(teamInfo.primaryColor).toString(16).padStart(6, '0') : '#000000';
    let secondaryColor = teamInfo.secondaryColor ? '#' + parseInt(teamInfo.secondaryColor).toString(16).padStart(6, '0') : primaryColor;
    
    const hueDistance = chroma.distance(primaryColor, secondaryColor, 'hsl');

    if (hueDistance < 30) {
        secondaryColor = '#ffffff';
    }
    
    if (displayName === "Titans") {
        secondaryColor = '#ff0000';
    }
    if (displayName === "Buccaneers") {
        secondaryColor = '#FF7900';
    }
    if (displayName === "Jaguars" || displayName === "Ravens") {
        secondaryColor = '#D7A22A';
    }
    if (displayName === "Lions") {
        secondaryColor = '#B0B7BC';
    }
    if (displayName === "Saints" || displayName === "Steelers" || displayName === "Raiders") {
        secondaryColor = primaryColor;
        primaryColor = '#2D2D2D'
    }
    if (displayName === "Black Knights" ) {
        primaryColor = '#2D2D2D'
    }
    if (displayName === "Blues" || displayName === "Cowboys" || displayName === "Colts" || displayName === "Condors"  || displayName === "Aviators") {
        secondaryColor = '#ababb0';
    }
    if (displayName === "Thunderbirds") {
        secondaryColor = '#cb7a20';
    }
    if(displayName === "Panthers" || displayName === "Bengals"){
        secondaryColor = '#2D2D2D'
    }
    if( displayName === "Eagles"){
        secondaryColor = "#38df26"
    }
    let darkerPrimaryColor = chroma(primaryColor).darken(1).hex();

    return {'primaryColor': primaryColor, 'secondaryColor': secondaryColor, "darkerPrimaryColor": darkerPrimaryColor}
}

export default GOTW