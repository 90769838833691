import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';


const ProgressChart = ({ rating, size, str }) => {
    const svgRef = useRef(null);
    const width = size;
    const height = size;
    const radius = Math.min(width, height) / 2;

    let data = [{ label: 'Completed', value: rating }, { label: 'Remaining', value: 99 - rating }];

    let saturation = 100; // fixed saturation value
    const lightness = 50; // fixed lightness value
    let hue;
    if (rating >= 99) {
        hue = 50; // gold color
        saturation = 55;
    } else if (rating <= 50) {
        hue = 0; // bright red color
    } else {
        // interpolate hue to a value between 0 and 100 as rating decreases from 100 to 50
        hue = (rating-50) * 2;
    }
    // const ratingColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    const ratingColor = 'green'
    const styles = `
  .gold {
    filter: sepia(50%);
  } 
`;

    useEffect(() => {
        const svg = d3.select(svgRef.current);

        // Clear any existing elements
        svg.selectAll('*').remove();
        svg.append('style').text(styles);

        const g = svg.append('g').attr('transform', `translate(${width / 2}, ${height / 2}) rotate(180)`);

        let color = d3.scaleOrdinal()
        .domain(data.map(d => d.label))
        .range([ratingColor, "rgba(0, 0, 0, 0.15)"]);
    
    const pie = d3.pie().sort(null).value(d => d.value);
    
    const arc = d3.arc().innerRadius(radius / 1.35).outerRadius(radius);
    
    const paths = pie(data);
        const path = g
            .selectAll('path')
            .data(paths)
            .enter()
            .append('path')
            .attr('d', (d) => arc(d) || '')
            .attr('fill', (d) => color(d.data.label))
            .classed('gold', rating >= 100); // apply the gold class to the path element if the rating is greater than or equal to 99%

        if (str === '') {
            const text = g
                .append('text')
                // .text(rating)
                .attr('text-anchor', 'middle')
                .attr('dy', '0.4em')
                .style('font-weight', 'bold')
                .style('fill', ratingColor);

        } else {
            const text = g
                .append('text')
                // .text(rating)
                .attr('text-anchor', 'middle')
                .attr('dy', '0em')
                .style('font-weight', 'bold')
                .style('fill', ratingColor);

            const subtext = g
                .append('text')
                // .text(str)
                .attr('text-anchor', 'middle')
                .attr('dy', '1em')
                .style('font-size', '0.7em')
                .style('fill', ratingColor);

        }
        if (rating >= 99) {
            path.style('filter', 'url(#gold-filter)');
        }
        svg.attr('class', rating >= 99 ? 'gold' : ''); // apply the gold class to the svg element

        return () => {
            // Remove the SVG element when the component is unmounted
            svg.selectAll('*').remove();
        };
    }, [data]);

    return (
        <div>
            <svg ref={svgRef} width={width} height={height} />
        </div>
    );
};

export default ProgressChart;
