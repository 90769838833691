import React from 'react';
import scouts from './Scouts.json';
import { useState, useEffect } from 'react';
import { FaSquarePlus } from "react-icons/fa6";
import ScoutStickyHeader from './ScoutStickyHeader';

const CurrScouts = (league, setLeague) => {
    const [user, setUser] = useState([]);
    const [gm, setGM] = useState([]);
    const [director, setDirector] = useState([]);
    const [nationals, setNationals] = useState([]);
    const [assistants, setAssistants] = useState([]);
    const [doctor, setDoctor] = useState([]);

    const [showPopup, setShowPopup] = useState(false);
    const [showPopupType, setShowPopupType] = useState(false);

    const [hireScoutList, setHireScoutList] = useState([]);

    const handleHireScoutClick = (scoutType) => {
      setShowPopup(true);
      setShowPopupType(scoutType);

      //get list of all scouts that are that type
      //check all currently owned scouts
      let filteredScouts = scouts.filter(item => item.position === scoutType)
      const combinedScouts = league.league.user_data.reduce((accumulator, currentItem) => {
        // Check if the 'scouts' property exists in the current item
        if (currentItem.scouts && Array.isArray(currentItem.scouts)) {
          // Concatenate the arrays
          accumulator = accumulator.concat(currentItem.scouts);
        }
        return accumulator;
      }, []);
      
      const result = filteredScouts.filter(item => !combinedScouts.includes(item.id));
      setHireScoutList(result)

    };
  
    const handleClosePopup = () => {
      setShowPopup(false);
      setShowPopupType('')
    };
  
    const handleHireScout = async (hireID) => {
      const targetObject = league.league.user_data.find(item => item.user_uuid === user.uuid);
      if (targetObject) {
        targetObject.scouts.push(hireID)  
        const csrfTokenElement = document.querySelector('[name=csrfmiddlewaretoken]');
        const csrfToken = csrfTokenElement ? csrfTokenElement.value : null;
        try {
          await
           fetch(`http://localhost:8000/api/update_league/${league.league.unique_id}/`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
            // Add any other headers if needed
          },
          body: JSON.stringify({
            'user_data': league.league.user_data,
          }),
        })
        } catch {
        }
        setShowPopup(false);
        setShowPopupType('')
        window.location.reload();
      } else {
        // Object not found
        console.log('Object not found');
      }
    };
  
    const handleFireScout = async (fireID) => {
      const targetObject = league.league.user_data.find(item => item.user_uuid === user.uuid);
    
      if (targetObject) {
        // Remove the specified ID from the scouts array
        targetObject.scouts = targetObject.scouts.filter(scoutID => scoutID !== fireID);
    
        const csrfTokenElement = document.querySelector('[name=csrfmiddlewaretoken]');
        const csrfToken = csrfTokenElement ? csrfTokenElement.value : null;
    
        try {
          await fetch(`http://localhost:8000/api/update_league/${league.league.unique_id}/`, {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrfToken,
              // Add any other headers if needed
            },
            body: JSON.stringify({
              'user_data': league.league.user_data,
            }),
          });
        } catch (error) {
          // Handle errors if needed
          console.error('Error firing scout:', error);
        }
    
        setShowPopup(false);
        setShowPopupType('');
        window.location.reload();
      } else {
        // Object not found
        console.log('Object not found');
      }
    };
    

    useEffect(() => {
        const fetchLeagues = async () => {
          let dataUser;
          try {
            const responseUser = await fetch('http://localhost:8000/api/current_user/', {
              credentials: 'include', // Include credentials (cookies) in the request
            });
            dataUser = await responseUser.json();
            setUser(dataUser)
          } catch (error) {
            console.log('could not get curr user')
          }

          if (user){
            let userData = league.league.user_data.find((testu) => testu.user_uuid == dataUser.uuid);
            let gm = null
            let director = null
            let nationals = []
            let assistants = []
            let doctor = null

            for(let i=0; i<userData.scouts.length; i++){
              let currI = userData.scouts[i]
              let currScout = null
              for(let j=0; j<scouts.length; j++){
                  if(scouts[j].id===currI){currScout=scouts[j]}
              }
              if(currScout.position==="GM"){gm=currScout}
              else if(currScout.position==="Director"){director=currScout}
              else if(currScout.position==="National"){nationals.push(currScout)}
              else if(currScout.position==="Assistant"){assistants.push(currScout)}
              else if(currScout.position==="Doctor"){doctor = currScout}

            }
            // console.log(gm, director, nationals)
            setGM(gm)
            setDirector(director)
            setNationals(nationals)
            setAssistants(assistants)
            setDoctor(doctor)
          }
        };
    
        fetchLeagues();
      }, []);
  const cardStyle = {
    // Define your card styles here
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '16px',
    marginBottom: '16px',
    width: '400px',
    backgroundColor: "#f0f0f0",

    borderRadius: "2px",
    margin: "20px",
    borderTop: "2px solid grey",
    padding: "5px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    position: "relative",
    // cursor: "pointer",
  };
  const popupCardStyle = {
    // Define your card styles here
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '16px',
    marginBottom: '16px',
    width: '500px',
    backgroundColor: "#f0f0f0",

    borderRadius: "2px",
    margin: "20px",
    borderTop: "2px solid grey",
    padding: "5px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    position: "relative",
    // cursor: "pointer",
  };

  const buttonStyle={
    backgroundColor: 'rgba(255,255,255,0.8)',
    border: 'none',
    cursor: 'pointer',
    padding: '5px 10px',
    borderRadius: '4px',
    marginTop: '-30px'
  }
  
  const topSectionStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 8px',
    fontSize: '16px', // Adjust the font size as needed
    // width: '800px',
    borderBottom: '1px solid #ddd',

  };

  const bottomSectionStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '6px',
    paddingTop: '10px',
    padding: '5px',
  };
  
  const columnStyle = {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10px',
  };

  const perkStyle = {
    marginBottom: '-15px',
    display: 'flex',
    alignItems: 'center', // Align items to the center vertically

  }
  const lastPerkStyle = {
    ...perkStyle,
    marginBottom: '-0',
  }
  const bottomHeading ={
    fontSize: '10px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: '5px'
  } 
  const overlayStyle = {
    position: 'absolute',
    top: '0',
    right: '0',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: '7.5px',
    borderRadius: '4px',
    marginRight: '15px',
    marginTop: '20px',
    width: '70px',
    height: '35px',
 
  };
  const portraitStyle = {
    width: '90px',
    marginTop:'-20px',
    marginBottom:'-10px',
    marginRight: '10px'
  }
  const currencyStyle = {
    width: '40px',
    marginRight: '10px',
  }
  const hireScout = {
    justifyContent:"center",
    alignItems: 'center', // Center content vertically
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    padding: '40px'
  }

  const popupContainerStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000,
  };

  const popupStyle = {
    // Your popup styles
    padding: '20px',
    background: '#fff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    height: '80vh',
    overflowY:'scroll',
    width: '600px'
  };

  const backdropStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)', // Adjust the opacity as needed
    backdropFilter: 'blur(5px)', // Adjust the blur intensity as needed
    zIndex: 999,
    display: showPopup ? 'block' : 'none',
  };

  return (
    <div className="vertical-cards-container">
      <ScoutStickyHeader/>
       {showPopup && (
        <>
          <div style={backdropStyle}></div>
          <div style={popupContainerStyle}>
            <div style={popupStyle}>
              {/* Content for your popup */}
              <p>Hire a {showPopupType}</p>
              <div>
                {hireScoutList.map(item => (
                <div style={popupCardStyle}>
                {/* Content for Card 1 */}
                <div style={topSectionStyle}>
                        <button onClick={()=>handleHireScout(item.id)} style={buttonStyle}>hire</button>

                <img
                      style={portraitStyle}
                      src={`https://madden-assets-cdn.pulse.ea.com/madden24/portraits/256/${item.portrait}.png`}
                    />
                <div style={columnStyle}>
                  <h3>{item.name}</h3>
                  {item.specialty!='NA' && <p style={{marginTop:"-10px"}}>{getSpecialtyTag(item.specialty)} Specialist</p>}
                </div>
                <div style={overlayStyle}>
                <div style={columnStyle}>
                  <span style={bottomHeading}>Position</span>
                  <div>{item.position}</div>
                </div>
                </div>
                </div>
                
                {item.specialty!='NA' && <p style={perkStyle}>
                  <img
                      style={currencyStyle}
                      src={getSpecialtyImg(item.specialty)}
                    />
                    <div>
                  +{item.specialty_tokens} {getSpecialtyTag(item.specialty)} Tokens
                  </div>
                </p>}
                <p style={showPopupType!="Doctor" && showPopupType!="National" && showPopupType!="Assistant" ? perkStyle : lastPerkStyle}>
                <img
                      style={currencyStyle}
                      src={`/Images/Currency/AllPurpose.png`}
                    />
                    <div>
                  +{item.tokens} All Purpose Tokens
                  </div>
                  </p>
                {(showPopupType!="Doctor" &&showPopupType!="National" && showPopupType!="Assistant")&& <p style={lastPerkStyle}>
                <img
                      style={currencyStyle}
                      src={`/Images/ICONS/Trending up.png`}
                    />
                    <div>
                  {item.bonus}
                  </div>
                </p>}
              </div>
               
              ))}
              </div>
              <button onClick={handleClosePopup}>Close Popup</button>
            </div>
          </div>
        </>
      )}
      {gm && gm.position==="GM" &&
      <div style={cardStyle}>
        {/* Content for Card 1 */}
        <div style={topSectionStyle}>
        <button onClick={()=>handleFireScout(gm.id) } style={buttonStyle}>Fire</button>
        <img
              style={portraitStyle}
              src={`https://madden-assets-cdn.pulse.ea.com/madden24/portraits/256/${gm.portrait}.png`}
            />
        <div style={columnStyle}>
          <h3>{gm.name}</h3>
          {gm.specialty!='NA' && <p style={{marginTop:"-10px"}}>{getSpecialtyTag(gm.specialty)} Specialist</p>}
        </div>
        <div style={overlayStyle}>
        <div style={columnStyle}>
          <span style={bottomHeading}>Position</span>
          <div>{gm.position}</div>
        </div>
        </div>
        </div>
        
        {gm.specialty!='NA' && <p style={perkStyle}>
          <img
              style={currencyStyle}
              src={getSpecialtyImg(gm.specialty)}
            />
            <div>
          +{gm.specialty_tokens} {getSpecialtyTag(gm.specialty)} Tokens
          </div>
        </p>}
        <p style={perkStyle}>
        <img
              style={currencyStyle}
              src={`/Images/Currency/AllPurpose.png`}
            />
            <div>
          +{gm.tokens} All Purpose Tokens
          </div>
          </p>
        <p style={lastPerkStyle}>
        <img
              style={currencyStyle}
              src={`/Images/ICONS/Trending up.png`}
            />
            <div>
          {gm.bonus}
          </div>
        </p>
      </div>}
      {!gm &&
      <div style={cardStyle}>
        {/* Content for Card 1 */}
        <div style={overlayStyle}>
        <div style={columnStyle}>
          <span style={bottomHeading}>Position</span>
          <div>GM</div>
        </div>
        </div>

        <div style={hireScout} onClick={()=>{handleHireScoutClick('GM')}}>
        <FaSquarePlus style={{color: 'green', height:'50px',width:'50px', marginBottom: '20px'}}/>
        <div>Fill Position: General Manager</div>
        </div>
      </div>}

      {director && director.position==="Director" &&
      <div style={cardStyle}>
        {/* Content for Card 1 */}
        <div style={topSectionStyle}>
        <button onClick={()=>handleFireScout(director.id)}  style={buttonStyle}>Fire</button>

        <img
              style={portraitStyle}
              src={`https://madden-assets-cdn.pulse.ea.com/madden24/portraits/256/${director.portrait}.png`}
            />
        <div style={columnStyle}>
          <h3>{director.name}</h3>
          {director.specialty!='NA' && <p style={{marginTop:"-10px"}}>{getSpecialtyTag(director.specialty)} Specialist</p>}
        </div>
        <div style={overlayStyle}>
        <div style={columnStyle}>
          <span style={bottomHeading}>Position</span>
          <div>{director.position}</div>
        </div>
        </div>
        </div>
        
        {director.specialty!='NA' && <p style={perkStyle}>
          <img
              style={currencyStyle}
              src={getSpecialtyImg(director.specialty)}
            />
            <div>
          +{director.specialty_tokens} {getSpecialtyTag(director.specialty)} Tokens
          </div>
        </p>}
        <p style={perkStyle}>
        <img
              style={currencyStyle}
              src={`/Images/Currency/AllPurpose.png`}
            />
            <div>
          +{director.tokens} All Purpose Tokens
          </div>
          </p>
        <p style={lastPerkStyle}>
        <img
              style={currencyStyle}
              src={`/Images/ICONS/Trending up.png`}
            />
            <div>
          {director.bonus}
          </div>
        </p>
      </div>}

      {!director &&
      <div style={cardStyle}>
        {/* Content for Card 1 */}
        <div style={overlayStyle}>
        <div style={columnStyle}>
          <span style={bottomHeading}>Position</span>
          <div>Director</div>
        </div>
        </div>

        <div style={hireScout} onClick={()=>{handleHireScoutClick('Director')}}>
        <FaSquarePlus style={{color: 'green', height:'50px',width:'50px', marginBottom: '20px'}}/>
        <div>Fill Position: Director of College Scouting</div>
        </div>
      </div>}

      {doctor && doctor.position==="Doctor" &&
      <div style={cardStyle}>
        {/* Content for Card 1 */}
        <div style={topSectionStyle}>
        <button onClick={()=>handleFireScout(doctor.id)}  style={buttonStyle}>Fire</button>

        <img
              style={portraitStyle}
              src={`https://madden-assets-cdn.pulse.ea.com/madden24/portraits/256/${doctor.portrait}.png`}
            />
        <div style={columnStyle}>
          <h3>{doctor.name}</h3>
          {doctor.specialty!='NA' && <p style={{marginTop:"-10px"}}>{getSpecialtyTag(doctor.specialty)} Specialist</p>}
        </div>
        <div style={overlayStyle}>
        <div style={columnStyle}>
          <span style={bottomHeading}>Position</span>
          <div>{doctor.position}</div>
        </div>
        </div>
        </div>
        
        {doctor.specialty!='NA' && <p style={perkStyle}>
          <img
              style={currencyStyle}
              src={getSpecialtyImg(doctor.specialty)}
            />
            <div>
          +{doctor.specialty_tokens} {getSpecialtyTag(doctor.specialty)} Tokens
          </div>
        </p>}
        <p style={lastPerkStyle}>
        <img
              style={currencyStyle}
              src={`/Images/Currency/AllPurpose.png`}
            />
            <div>
          +{doctor.tokens} All Purpose Tokens
          </div>
          </p>

      </div>}

      {!doctor &&
      <div style={cardStyle}>
        {/* Content for Card 1 */}
        <div style={overlayStyle}>
        <div style={columnStyle}>
          <span style={bottomHeading}>Position</span>
          <div>Doctor</div>
        </div>
        </div>

        <div style={hireScout} onClick={()=>{handleHireScoutClick('Doctor')}}>
        <FaSquarePlus style={{color: 'green', height:'50px',width:'50px', marginBottom: '20px'}}/>
        <div>Fill Position: Team Doctor</div>
        </div>
      </div>}
      {nationals[0] && nationals[0].position==="National" &&
      <div style={cardStyle}>
        {/* Content for Card 1 */}
        <div style={topSectionStyle}>
        <button onClick={()=>handleFireScout(nationals[0].id)} style={buttonStyle}>Fire</button>

        <img
              style={portraitStyle}
              src={`https://madden-assets-cdn.pulse.ea.com/madden24/portraits/256/${nationals[0].portrait}.png`}
            />
        <div style={columnStyle}>
          <h3>{nationals[0].name}</h3>
          {nationals[0].specialty!='NA' && <p style={{marginTop:"-10px"}}>{getSpecialtyTag(nationals[0].specialty)} Specialist</p>}
        </div>
        <div style={overlayStyle}>
        <div style={columnStyle}>
          <span style={bottomHeading}>Position</span>
          <div>{nationals[0].position}</div>
        </div>
        </div>
        </div>
        
        {nationals[0].specialty!='NA' && <p style={perkStyle}>
          <img
              style={currencyStyle}
              src={getSpecialtyImg(nationals[0].specialty)}
            />
            <div>
          +{nationals[0].specialty_tokens} {getSpecialtyTag(nationals[0].specialty)} Tokens
          </div>
        </p>}
        <p style={lastPerkStyle}>
        <img
              style={currencyStyle}
              src={`/Images/Currency/AllPurpose.png`}
            />
            <div>
          +{nationals[0].tokens} All Purpose Tokens
          </div>
          </p>
      </div>}
      
      {nationals[1] && nationals[1].position==="National" &&
      <div style={cardStyle}>
        {/* Content for Card 1 */}
        <div style={topSectionStyle}>
        <button onClick={()=>handleFireScout(nationals[1].id)} style={buttonStyle}>Fire</button>

        <img
              style={portraitStyle}
              src={`https://madden-assets-cdn.pulse.ea.com/madden24/portraits/256/${nationals[1].portrait}.png`}
            />
        <div style={columnStyle}>
          <h3>{nationals[1].name}</h3>
          {nationals[1].specialty!='NA' &&  <p style={{marginTop:"-10px"}}>{getSpecialtyTag(nationals[1].specialty)} Specialist</p>}
        </div>
        <div style={overlayStyle}>
        <div style={columnStyle}>
          <span style={bottomHeading}>Position</span>
          <div>{nationals[1].position}</div>
        </div>
        </div>
        </div>
        
        {nationals[1].specialty!='NA' && <p style={perkStyle}>
          <img
              style={currencyStyle}
              src={getSpecialtyImg(nationals[1].specialty)}
            />
            <div>
          +{nationals[1].specialty_tokens} {getSpecialtyTag(nationals[1].specialty)} Tokens
          </div>
        </p>}
        <p style={lastPerkStyle}>
        <img
              style={currencyStyle}
              src={`/Images/Currency/AllPurpose.png`}
            />
            <div>
          +{nationals[1].tokens} All Purpose Tokens
          </div>
          </p>
      </div>}

      {!nationals[0] &&
      <div style={cardStyle}>
        {/* Content for Card 1 */}
        <div style={overlayStyle}>
        <div style={columnStyle}>
          <span style={bottomHeading}>Position</span>
          <div>National</div>
        </div>
        </div>

        <div style={hireScout} onClick={()=>{handleHireScoutClick('National')}}>
        <FaSquarePlus style={{color: 'green', height:'50px',width:'50px', marginBottom: '20px'}}/>
        <div>Fill Position: National Scout</div>
        </div>
      </div>}
      {!nationals[1] &&
      <div style={cardStyle}>
        {/* Content for Card 1 */}
        <div style={overlayStyle}>
        <div style={columnStyle}>
          <span style={bottomHeading}>Position</span>
          <div>National</div>
        </div>
        </div>

        <div style={hireScout} onClick={()=>{handleHireScoutClick('National')}}>
        <FaSquarePlus style={{color: 'green', height:'50px',width:'50px', marginBottom: '20px'}}/>
        <div>Fill Position: National Scout</div>
        </div>
      </div>}

      {assistants[0] && assistants[0].position==="Assistant" &&
      <div style={cardStyle}>
        {/* Content for Card 1 */}
        <div style={topSectionStyle}>
        <button onClick={()=>handleFireScout(assistants[0].id)} style={buttonStyle}>Fire</button>

        <img
              style={portraitStyle}
              src={`https://madden-assets-cdn.pulse.ea.com/madden24/portraits/256/${assistants[0].portrait}.png`}
            />
        <div style={columnStyle}>
          <h3>{assistants[0].name}</h3>
          {assistants[0].specialty!='NA' && <p style={{marginTop:"-10px"}}>{getSpecialtyTag(assistants[0].specialty)} Specialist</p>}
        </div>
        <div style={overlayStyle}>
        <div style={columnStyle}>
          <span style={bottomHeading}>Position</span>
          <div>{assistants[0].position}</div>
        </div>
        </div>
        </div>
        
        {assistants[0].specialty!='NA' && <p style={perkStyle}>
          <img
              style={currencyStyle}
              src={getSpecialtyImg(assistants[0].specialty)}
            />
            <div>
          +{assistants[0].specialty_tokens} {getSpecialtyTag(assistants[0].specialty)} Tokens
          </div>
        </p>}
        <p style={lastPerkStyle}>
        <img
              style={currencyStyle}
              src={`/Images/Currency/AllPurpose.png`}
            />
            <div>
          +{assistants[0].tokens} All Purpose Tokens
          </div>
          </p>
      </div>}
      
      {assistants[1] && assistants[1].position==="Assistant" &&
      <div style={cardStyle}>
        {/* Content for Card 1 */}
        <div style={topSectionStyle}>
        <button onClick={()=>handleFireScout(assistants[1].id)} style={buttonStyle}>Fire</button>

        <img
              style={portraitStyle}
              src={`https://madden-assets-cdn.pulse.ea.com/madden24/portraits/256/${assistants[1].portrait}.png`}
            />
        <div style={columnStyle}>
          <h3>{assistants[1].name}</h3>
          {assistants[1].specialty!='NA' &&  <p style={{marginTop:"-10px"}}>{getSpecialtyTag(assistants[1].specialty)} Specialist</p>}
        </div>
        <div style={overlayStyle}>
        <div style={columnStyle}>
          <span style={bottomHeading}>Position</span>
          <div>{assistants[1].position}</div>
        </div>
        </div>
        </div>
        
        {assistants[1].specialty!='NA' && <p style={perkStyle}>
          <img
              style={currencyStyle}
              src={getSpecialtyImg(assistants[1].specialty)}
            />
            <div>
          +{assistants[1].specialty_tokens} {getSpecialtyTag(assistants[1].specialty)} Tokens
          </div>
        </p>}
        <p style={lastPerkStyle}>
        <img
              style={currencyStyle}
              src={`/Images/Currency/AllPurpose.png`}
            />
            <div>
          +{assistants[1].tokens} All Purpose Tokens
          </div>
          </p>
      </div>}

      {!assistants[0] &&
      <div style={cardStyle}>
        {/* Content for Card 1 */}
        <div style={overlayStyle}>
        <div style={columnStyle}>
          <span style={bottomHeading}>Position</span>
          <div>Assistant</div>
        </div>
        </div>

        <div style={hireScout} onClick={()=>{handleHireScoutClick('Assistant')}}>
        <FaSquarePlus style={{color: 'green', height:'50px',width:'50px', marginBottom: '20px'}}/>
        <div>Fill Position: Assistant Junior Scout</div>
        </div>
      </div>}
      {!assistants[1] &&
      <div style={cardStyle}>
        {/* Content for Card 1 */}
        <div style={overlayStyle}>
        <div style={columnStyle}>
          <span style={bottomHeading}>Position</span>
          <div>Assistant</div>
        </div>
        </div>

        <div style={hireScout} onClick={()=>{handleHireScoutClick('Assistant')}}>
        <FaSquarePlus style={{color: 'green', height:'50px',width:'50px', marginBottom: '20px'}}/>
        <div>Fill Position: Assistant Junior Scout</div>
        </div>
      </div>}

    </div>
  );
};

const getSpecialtyImg = (specialty) =>{
  let link = `/Images/Currency/${specialty}.png`
  if(specialty.includes('College_')){
    const index = specialty.indexOf('College_');
    link = `/Images/CollegeLogos/${specialty.substring(index + 'College_'.length)}.svg`
  }
  return link
}

const getSpecialtyTag = (specialty) =>{
  let link = specialty
  if(specialty.includes('College_')){
    const index = specialty.indexOf('College_');
    link = specialty.substring(index + 'College_'.length)
  }
  return link
}

export default CurrScouts;
