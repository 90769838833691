import React, { useState, useEffect } from 'react';
import chroma from "chroma-js";
import Anton from '../Fonts/Microsport.ttf';
import { IoLockClosedSharp } from "react-icons/io5";
import { IoMdJet } from "react-icons/io";
import { FaTrain } from "react-icons/fa6";
import { IoMail } from "react-icons/io5";
import { FaWeightHanging } from "react-icons/fa6";
import { FaPowerOff } from "react-icons/fa";

function PflDashboard() {

  
    const groupStyle ={
        // border: '1px solid red',
    }

    
  return (
    <div style={{height: '100vh'}}>
        <div style={{display: 'grid', gridTemplateRows: '5vh 95vh', height: '100%'}}>
            <div style={{border: '1px solid green'}}></div>
            <div style={{display: 'grid', gridTemplateColumns: '1fr 2.5fr 1fr'}}>
                <div><h2 style={{width: '100%', textAlign:"center", backgroundColor: '#f0f0f0', height: '65px',  border: '1px solidr red', marginBottom: '-0px'}}>League Leaders</h2>
                <div style={{display: 'grid', gridTemplateRows: '1fr 1fr 1fr 1fr 1fr 1fr ', height: '85vh'}}> 
                    {LeagueLeaders('https://neonsportz.com/api/leagues/PFL/stats/passing/?size=5')}
                    {LeagueLeaders('https://neonsportz.com/api/leagues/PFL/stats/rushing/?size=5')}
                    {LeagueLeaders('https://neonsportz.com/api/leagues/PFL/stats/receiving/?size=5')}
                    {LeagueLeaders('https://neonsportz.com/api/leagues/PFL/stats/defense/?size=5&ordering=-defTotalTackles')}
                    {LeagueLeaders('https://neonsportz.com/api/leagues/PFL/stats/defense/?size=5&ordering=-defTotalInts')}
                    {LeagueLeaders('https://neonsportz.com/api/leagues/PFL/stats/defense/?size=5&ordering=-defTotalSacks')}

                </div></div>
                <div style={groupStyle}>
                    <div style={{display: 'grid', gridTemplateRows: '1fr 1.75fr', height: '100%'}}>
                        <div style={{}}>
                            {GOTW()}
                        </div>
                        <div style={{}}></div>
                    </div>
                </div>
                <div style={groupStyle}>
                    {PowerRankings()}
                </div>
            </div>
        </div>
    </div>
  );
}


function GOTW(){
    const [game, setGame] = useState([]);
    const [teamColors, setTeamColors] = useState({});

    useEffect(() => {
        fetch('https://neonsportz.com/api/leagues/PFL/gotw')
          .then(response => response.json())
          .then(data => {
            setGame(data);
            console.log(data)
            fetchTeamColors(data);
          })
          .catch(error => console.error('Error fetching rankings:', error));
      }, []);
    
      const fetchTeamColors = (data) => {
        const colors = {};
        
        const promise1 = fetch(`https://neonsportz.com/api/leagues/PFL/teams/${data.homeTeam.abbrName}`)
            .then(response => response.json())
            .then(teamData => {
              colors[data.homeTeam.abbrName] = {
                primaryColor: teamData.primaryColor,
                secondaryColor: teamData.secondaryColor
              };
            })
            .catch(error => console.error(`Error fetching team colors for ${data.homeTeam.abbrName}:`, error));
        const promise2 = fetch(`https://neonsportz.com/api/leagues/PFL/teams/${data.awayTeam.abbrName}`)
            .then(response => response.json())
            .then(teamData => {
              colors[data.awayTeam.abbrName] = {
                primaryColor: teamData.primaryColor,
                secondaryColor: teamData.secondaryColor
              };
            })
            .catch(error => console.error(`Error fetching team colors for ${data.awayTeam.abbrName}:`, error));
        

        const promises = [promise1, promise2]
        Promise.all(promises).then(() => {
          setTeamColors(colors);
        });
      };
      const customFont = new FontFace('Anton', `url(${Anton}`);
      document.fonts.add(customFont);
    
    return <div>
        {game && teamColors && game.homeTeam && game.homeTeam.displayName && <div style={{gridTemplateColumns: '1fr 0.75fr 1fr', display: 'grid', height: '100%'}}>
            
            {TeamBackground(game.awayTeam, teamColors, 'left', game.away_team_spread, game.winner_votes, false)}
            <div style={{zIndex: 2}}>

                <div>{<div style={{zIndex: 1, marginTop: '20px', color: 'red', height: '300px', textAlign: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                    <h2 style={{marginBottom: '50px', marginTop: '-50px'}}>Gibby's GOTW</h2>
                    <h3 style={{marginBottom: '50px', marginTop: '-50px', fontSize: '75%'}}>PFL SZN {game.awayTeam.seasonIndex + 1} WEEK {game.awayTeam.weekIndex + 1}</h3>
                    <div>
                        <h1 style={{fontWeight: 'bold', font: '300% Anton'}}>{game.awayTeam.displayName}</h1>
                        <h3 style={{ color: 'red', font: '150% Anton', margin: '0px 0px 5px 0px' }}>VS</h3>
                        <h1 style={{fontWeight: 'bold', font: '300% Anton'}}>{game.homeTeam.displayName}</h1>
                    </div>
                </div>}
                <div style={{
                    borderTop:'305px solid white',
                    borderLeft: '75px solid transparent',
                    borderRight: '75px solid transparent',
                    marginRight: '-75px',
                    marginLeft: '-75px',
                    zIndex: 1,
                    marginTop: '-325px'
                }}></div></div>


            </div>
            {TeamBackground(game.homeTeam, teamColors, 'right', game.home_team_spread, game.winner_votes, true)}
        </div>}
    </div>
}

function PowerRankings(){
    const [rankings, setRankings] = useState([]);
    const [teamColors, setTeamColors] = useState({});
    
    const customFont = new FontFace('Anton', `url(${Anton}`);
    document.fonts.add(customFont);
  
    useEffect(() => {
      fetch('https://neonsportz.com/api/leagues/PFL/rankings/?size=0&page=1&ordering=rank')
        .then(response => response.json())
        .then(data => {
          setRankings(data);
          fetchTeamColors(data);
        })
        .catch(error => console.error('Error fetching rankings:', error));
    }, []);
  
    const fetchTeamColors = (data) => {
      const colors = {};
      const promises = data.map(ranking => {
        return fetch(`https://neonsportz.com/api/leagues/PFL/teams/${ranking.team.abbrName}`)
          .then(response => response.json())
          .then(teamData => {
            colors[ranking.team.abbrName] = {
              primaryColor: teamData.primaryColor,
              secondaryColor: teamData.secondaryColor
            };
          })
          .catch(error => console.error(`Error fetching team colors for ${ranking.team.abbrName}:`, error));
      });
      
      Promise.all(promises).then(() => {
        setTeamColors(colors);
      });
    };
  
    const rankingCard = {
      // border: '1px solid rgba(155,155,155,0.5)',
      borderRadius: '2px',
      marginTop: '7.5px'
      // padding: '10px',
      // height: '50px',
    }
  
    return (
      <div style={{ height: '95vh', }}>
          <div className="tiles" style={{display: 'grid', gridTemplateColumns: '1fr', padding: '0 5px', padding: '0 20px'}}>
          <h2 style={{width: '100%', textAlign:"center", backgroundColor: '#f0f0f0', height: '65px',  border: '1px solidr red'}}>Power Rankings Top 10</h2>

          {rankings.map((ranking, index) => {
            if(index<10){
                return <div key={index} className="tile" style={{...rankingCard, width: '100%', backgroundColor: teamColors[ranking.team.abbrName]?getColors(teamColors[ranking.team.abbrName], ranking.team.displayName).darkerPrimaryColor:'', color: 'white', borderBottom: teamColors[ranking.team.abbrName]?`5px solid ${getColors(teamColors[ranking.team.abbrName], ranking.team.displayName).secondaryColor}`:'5px solid white'}}>
                <div style={{display:'flex', overflow: 'hidden'}}>
                <div style={{ position: 'relative', overflow: 'hidden', width: '300px', height: '70px', marginRight: '-300px'}}>
                    <div style={{ zIndex: 2,  position: 'absolute', top: '40%', left: '75%', transform: 'translate(-100%, -90%) rotate(-25deg)', backgroundColor: teamColors[ranking.team.abbrName]?getColors(teamColors[ranking.team.abbrName], ranking.team.displayName).primaryColor+'96':'', width: '300px', height: '100px', clipPath: 'polygon(0 0, 100% 0%, 85% 100%, 0% 100%)' }} />
                </div>
                <div style={{zIndex: 3, display: 'flex'}}>
                    <div style={{paddingLeft: '10px', width: '40px', zIndex: 2}}>
                    <h2 style={{ color: 'white', fontSize: '215%', marginTop: '10px', marginBottom: '-30px', textShadow: `1px 1px 0 ${teamColors[ranking.team.abbrName]?getColors(teamColors[ranking.team.abbrName], ranking.team.displayName).secondaryColor:''}` }}>{ranking.rank}</h2>
                    </div>
                    <div style={{zIndex: 1, width: '100px', marginLeft:'-75px', marginRight: '115px'}}>
                        <img style={{opacity: '25%', width: '150px',  marginBottom:'-35px', borderRadius: 0}} src={`https://cdn.neonsportz.com/teamlogos/256/${ranking.team.logoId}.png`} alt={ranking.team.teamName} className="team-logo" />
                    </div>
                    <div style={{ display: 'flex', color: 'white' , fontWeight: 'bold',  width: '0px', fontSize: '90%', position: 'relative',  justifyContent:'left', height: '30px', marginLeft: '-125px', zIndex: 2, marginTop: '15px'}}>
                    <img style={{height: '55px', borderRadius: 0, marginRight:"5px", marginLeft: '-10px', marginTop: '-5px'}} src={`https://cdn.neonsportz.com/teamlogos/256/${ranking.team.logoId}.png`} alt={ranking.team.teamName} className="team-logo" />

                    {ranking.team.leaguemember && (<>
                            <img style={{ marginTop: '11px', width: '25px',height: '25px',  borderRadius: '10rem', marginRight: '5px'}} src={ranking.team.leaguemember.user.avatar_url} alt={`${ranking.team.displayName} Avatar`} />
                            <p style={{marginTop: '15px',  }}>{ranking.team.leaguemember.user.username.toUpperCase()}</p>
                        </>
                    )}
                    {!ranking.team.leaguemember && (
                            <p style={{marginTop: '15px',  }}>CPU</p>
                       
                    )}</div>
                    <div style={{marginLeft: '265px'}}></div>
                    <h2 style={{color: 'white' ,  fontSize: '100%', width:'145px', marginLeft: '-95px', textAlign: 'right', marginTop: '20px', marginBottom:'-30px', marginRight: '-50px'}}>{ranking.team.displayName}</h2>
                    <i style={{color: teamColors[ranking.team.abbrName]?getColors(teamColors[ranking.team.abbrName], ranking.team.displayName).secondaryColor:'' ,  fontSize: '50%', width:'150px', textAlign: 'right', marginTop: '42.5px', marginBottom:'-30px', marginLeft: '-100px', marginRight: '-50px'}}>{ranking.team.cityName}</i>
                    <h3 style={{color: 'white' ,  fontSize: '90%', width:'145px', marginLeft: '-95px', textAlign: 'right', marginTop: '5px', marginRight: '5px'}}>{ranking.team.totalWins}-{ranking.team.totalLosses}{ranking.team.totalTies>0&&'-'+ranking.team.totalTies}</h3>
                </div>
              </div>

              </div>
            }

        })}
        </div>
      </div>
    );
}

function LeagueLeaders(link){
    const [leader, setLeaders] = useState([]); 
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [leagueInfo, setLeagueInfo] = useState(null);

    useEffect(() => {
        fetch('https://neonsportz.com/api/leagues/PFL/')
        .then(response => response.json())
        .then(data => {
          setLeagueInfo(data);
        })
        .catch(error => console.error('Error fetching rankings:', error));
      }, []);

      useEffect(() => {
        if(leagueInfo){
            fetch(link+'&seasonIndex='+leagueInfo.season)
            .then(response => response.json())
            .then(data => {
                setLeaders(data.results[0]);
                setTotalPages(Math.ceil(data.count / 5))
            })
            .catch(error => console.error('Error fetching rankings:', error));
        }
      }, [page, leagueInfo]);

      return <div>
        <PlayerStats player={leader}/>

      </div>
}


function PlayerStats(props){
    const {player} = props

    const [teamData, setTeamData] = useState(null);
    const [primaryColor, setPrimaryColor] = useState(null);
    const [secondaryColor, setSecondaryColor] = useState(null);

    useEffect(() => {
        fetch('https://neonsportz.com/api/leagues/PFL/teams/'+player.team__abbrName)
        .then(response => response.json())
        .then(data => {
          setTeamData(data);
          setPrimaryColor(data.primaryColor ? '#' + parseInt(data.primaryColor).toString(16).padStart(6, '0') : '#000000')
          setSecondaryColor(data.secondaryColor ? '#' + parseInt(data.secondaryColor).toString(16).padStart(6, '0') : '#000000')
        })
        .catch(error => console.error('Error fetching rankings:', error));
      }, [player]);
     
      
    return<>{player && teamData && primaryColor && secondaryColor && 
    <div style={{display: 'grid',backgroundColor: `${primaryColor}`, margin: '10px',height:'80%', padding: '10px', borderRadius: '3px'}}>
    <div style={{fontWeight: 'bold', paddingLeft: '2px', color: 'white'}}>{player.player__fullName}</div>
    <div style={{display: 'flex', color: 'white'}}>                     
        <img style={{  marginRight: '0px', height: '15px', width: '15px', borderRadius: 0, marginTop: '-1px'}} src={`https://cdn.neonsportz.com/teamlogos/256/${player.team__logoId}.png`} alt={player.team__logoId} className="team-logo" />
        {player.team__abbrName}
    </div>
    <div style={{color: 'white'}}>{player.player__position}</div>
    </div>}</>
}

  
export default PflDashboard;

function TeamBackground(data, teamColors, textAlign, spread, winnerVotes, homeTeam) {
    if(spread>0){
        spread = '+'+spread
    }
    let votes = 0
    winnerVotes.map(vote => {
        if(vote.homeTeam === homeTeam){
            votes++
        }
        return null
    })
    return(
        <div style={{zIndex:-2,  backgroundColor: teamColors[data.abbrName]?getColors(teamColors[data.abbrName], data.displayName).primaryColor:'', color: 'white', textAlign: textAlign, padding: '0 5px', overflow: 'hidden'}}>
            <div style={{zIndex:100}}>
                {textAlign==="left"&& <div style={{ marginTop: '0px', width: '100%', height: '300px', display:'flex', justifyContent: 'right', marginBottom: '-300px'}}><img src='./Images/fireleft.png' style={{ height: '315px', marginRight: '-7.5px', marginTop: '-10px'}}/></div>}
                {textAlign==="right"&& <div style={{ marginTop: '0px',  width: '100%', height: '300px', display:'flex', justifyContent: 'left', marginBottom: '-300px'}}><img src='./Images/fireright.png' style={{ height: '315px', marginLeft: '-7.5px', marginTop: '-10px'}}/></div>}
            </div>
            <img style={{width: '300px', height: '300px', borderRadius: '0', margin: '-75px 0s', zIndex:-1}} src={`https://cdn.neonsportz.com/teamlogos/256/${data.logoId}.png`} alt={data.teamName} className="team-logo" />
            
                    <div style={{backgroundColor: teamColors[data.abbrName]?getColors(teamColors[data.abbrName], data.displayName).darkerPrimaryColor+'99':'', zIndex: -1, color: 'white' ,  fontSize: '100%', height: '60px', marginLeft: '-5px',marginRight: '-5px', marginTop: '-80px', justifyContent: textAlign, padding:'10px', }}>
                        <p style={{fontSize: '125%', fontWeight: 'bold', marginTop: '0px'}}>{data.leaguemember?data.leaguemember.user.username:'CPU'} ({data.totalWins}-{data.totalLosses}{data.totalTies>0&&'-'+data.totalTies}) </p>
                        <div style={{marginTop: '-17.5px'}}>
                            <div style={{marginBottom: '2.5px'}}>
                                {spread}
                            </div>
                            <div>
                                {votes} Votes
                            </div>
                        </div>
                    </div>
                
                
        </div>
    )
}

const getColors = (teamInfo, displayName) => {
    let primaryColor = teamInfo.primaryColor ? '#' + parseInt(teamInfo.primaryColor).toString(16).padStart(6, '0') : '#000000';
    let secondaryColor = teamInfo.secondaryColor ? '#' + parseInt(teamInfo.secondaryColor).toString(16).padStart(6, '0') : primaryColor;
    
    const hueDistance = chroma.distance(primaryColor, secondaryColor, 'hsl');

    if (hueDistance < 30) {
        secondaryColor = '#ffffff';
    }
    
    if (displayName === "Titans") {
        secondaryColor = '#ff0000';
    }
    if (displayName === "Buccaneers") {
        secondaryColor = '#FF7900';
    }
    if (displayName === "Jaguars" || displayName === "Ravens") {
        secondaryColor = '#D7A22A';
    }
    if (displayName === "Lions") {
        secondaryColor = '#B0B7BC';
    }
    if (displayName === "Saints" || displayName === "Steelers" || displayName === "Raiders") {
        secondaryColor = primaryColor;
        primaryColor = '#2D2D2D'
    }
    if (displayName === "Black Knights" ) {
        primaryColor = '#2D2D2D'
    }
    if (displayName === "Blues" || displayName === "Cowboys" || displayName === "Colts" || displayName === "Condors"  || displayName === "Aviators") {
        secondaryColor = '#ababb0';
    }
    if (displayName === "Thunderbirds") {
        secondaryColor = '#cb7a20';
    }
    if(displayName === "Panthers" || displayName === "Bengals"){
        secondaryColor = '#2D2D2D'
    }
    if( displayName === "Eagles"){
        secondaryColor = "#38df26"
    }
    let darkerPrimaryColor = chroma(primaryColor).darken(1).hex();

    return {'primaryColor': primaryColor, 'secondaryColor': secondaryColor, "darkerPrimaryColor": darkerPrimaryColor}
}