import React from 'react';
import { FaMinusCircle } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import SpiderChart from "./SpiderChart";
import { Tabs, Tab, Box } from '@mui/material';
import { useState } from 'react';
import ProspectTableRow from './ProspectTableRow';
import { FaKitMedical } from "react-icons/fa6";
import { FaBalanceScaleLeft } from "react-icons/fa";
import { TbRulerMeasure } from "react-icons/tb";
import QBStats from './QBStats.json';
import RBStats from './RBStats.json';
import WRStats from './WRStats.json';
import DBStats from './DBStats.json';
import DLStats from './DLStats.json';
import LBStats from './LBStats.json';
import OLStats from './OLStats.json';
import { MdInfoOutline } from "react-icons/md";
import { FaUserInjured } from "react-icons/fa";
import { PiChairFill } from "react-icons/pi";
import './dr.css'; // or import './styles.scss';
import { IoIosLock } from "react-icons/io";

const ScoutingReport = ({ prospect, index }) => {
  const [tabValue, setTabValue] = useState(0);
  const {positives, negatives} = getDraftNotes(prospect)
  const sparqColor = "#d9e346"
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const proConStyle={
    display:'flex', 
    alignItems: 'center',
    paddingLeft: '10px',
    height:'30px'
  }
  const lockedStyle = {
    fontSize: '20px',
    padding: '5px'
  }
  const positiveDivs = positives.map((positiveItem, index) => (
    <div key={index} style={proConStyle}>
      {/* You can customize the content of each div based on the positive value */}
      <FaCirclePlus style={{color:'green', marginRight: '5px'}}/><p>{positiveItem}</p>
    </div>
  ));
  const negativeDivs = negatives.map((negativeItem, index) => (
    <div key={index} style={proConStyle}>
      {/* You can customize the content of each div based on the positive value */}
      <FaMinusCircle style={{color:'red', marginRight: '5px'}}/><p>{negativeItem}</p>
    </div>
  ));
  let spiderData=getSpiderData(prospect)


  return (
    <div>
     <Tabs value={tabValue} onChange={handleChange} aria-label="Prospect Tabs">
        <Tab label="Scouting Report" />
        <Tab label="SPARQ" />
        <Tab label="Physical Exam" />
        <Tab label="College Stats" />
        {/* <Tab label="Stories" /> */}
      </Tabs>
      <div style={{ width: '80vw', overflowX:'auto' }}>
        <div style={{ width: '870px' }}>
        <ProspectTableRow key={index} prospect={prospect} index={index} viewOption={'large'} isPopup={true}/>
        </div>
      </div>
      <TabPanel value={tabValue} index={0}>
      <h4>Scouting Report</h4>
      {positiveDivs}
      {negativeDivs}
      </TabPanel>
     
      <TabPanel value={tabValue} index={1}>
      <>
      <div style={{display: 'flex', marginTop: '10px', marginBottom: '-10px'}} >
      <img
            style={{ width: '200px', height: '50px'}}
            src={`/Images/ICONS/sparq.png`}
            />
      <h4 style={{ fontStyle: 'italic', color: sparqColor, fontSize:'55px', marginTop:'-5px', fontWeight:'bold'}}> // {(prospect.relativeAthleticScore*10).toFixed(2)}</h4>
      </div>

      <div style={{display: 'flex'}}>
      <MdInfoOutline style={{marginRight: '5px'}}/>
      <div>SPARQ scores takes the overall athleticism of a prospect into account to develop an overarching rating. A chart with less variation suggests a more balanced and well-rounded athlete. A chart with more variation can help to find proficiencies and deficiencies in a prospect.</div>
      </div>
      <div style={{marginBottom:'-10px', display: 'flex', alignItems:'center'}}>
      <SpiderChart ratings={spiderData}
          width={550}
          height={350}/>
      <div style={{ fontStyle: 'italic', color: sparqColor, fontSize:'35px', marginTop:'-5px', fontWeight:'bold', textAlign:"right"}}>
        <div style={{padding: '10px'}}>Speed</div>
        <div style={{padding: '10px'}}>Power</div>
        <div style={{padding: '10px'}}>Agility</div>
        <div style={{padding: '10px'}}>Reaction</div>
        <div style={{padding: '10px'}}>Quickness</div>
      </div>
      <div style={{ fontStyle: 'italic', color: sparqColor, fontSize:'35px', marginTop:'-5px', fontWeight:'bold', margin: '0px 10px'}}>
        <div style={{padding: '10px'}}>//</div>
        <div style={{padding: '10px'}}>//</div>
        <div style={{padding: '10px'}}>//</div>
        <div style={{padding: '10px'}}>//</div>
        <div style={{padding: '10px'}}>//</div>
      </div>
      <div style={{ fontStyle: 'italic', color: sparqColor, fontSize:'35px', marginTop:'-5px', fontWeight:'bold'}}>
        <div style={{padding: '10px'}}>{getSparqRange(prospect.stats.speed)}</div>
        <div style={{padding: '10px'}}>{getSparqRange(prospect.stats.strength)}</div>
        <div style={{padding: '10px'}}>{getSparqRange(prospect.stats.agility)}</div>
        <div style={{padding: '10px'}}>{getSparqRange(prospect.stats.awareness)}</div>
        <div style={{padding: '10px'}}>{getSparqRange(prospect.stats.agility)}</div>
      </div>
      </div> </>
      {/* <div>Athleticism</div>
      <div>Pro Day</div>
      <div>Combine</div> */}
      </TabPanel>
      
      <TabPanel value={tabValue} index={2}>
        <><div>{physical(prospect.stats.injury, prospect)}</div>
        </>
      </TabPanel>

    <TabPanel value={tabValue} index={3}>
     
      <> <h4>College Stats</h4><div><FaUserInjured style={{color:'red'}}/>* Missed substantial time with injury</div>
      <div><PiChairFill style={{color:'grey'}}/>* Bench player</div>
      <div style={{marginTop: '10px'}}>{getStats(prospect)}</div></>
      </TabPanel>

      {/* <TabPanel value={tabValue} index={4}>
      <h4>Stories</h4>
      <div>idk how we will do this</div>
      </TabPanel> */}

    </div>
  );
};
const TabPanel = (props) => {
    const { children, value, index } = props;
  
    return (
      <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`}>
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  };
function getDraftNotes(player){
    let positives=[];
    let negatives=[];

    let college = "college"
    if(player.college){
        college=player.college
    }
  
    //things that matter for different positions
        //QB Throw stats
        //RB all carrying stats
        //WR RB stats less + catching
        //TE Blocking and WR
        //OL Blocking
        //DT/DE pass rushing + stuffing
        //OLB Pass Rushing + MLB
        //MLB Run stopping + coverage
        //CB coverage plus press
        //FS Range
        //SS inside the box + FS
    if(player.position==="QB"){
        let throwpower = player.stats.throwPower
        if(throwpower>97){
            positives.push(throwpowerNotes[0])
        } else if(throwpower>94){
            positives.push(throwpowerNotes[1])
        } else if(throwpower>90){
           // notes.push(throwpowerNotes[2])
        } else if(throwpower>87){
            //notes.push(throwpowerNotes[3])
        } else if(throwpower>84){
            negatives.push(throwpowerNotes[4])
        } else {
            negatives.push(throwpowerNotes[5])
        } 
        let deepBall = player.stats.throwAccuracyDeep
        if(deepBall>85){
            positives.push("Throws one of the best deep balls I've seen coming out of college")
        }else if(deepBall>80){
            positives.push("Had great chemistry throwing down the feild with his recievers at " + college)
        }else if(deepBall>75){
           // notes.push("Throws an average deep ball for a qaurterback with his experience")
        } else {
            negatives.push("Did not show much down the feild at " + college)
        }
        let accuracy = (player.stats.throwAccuracyDeep+player.stats.throwAccuracyMid+player.stats.throwAccuracyShort)/3
        if(accuracy>85){
            positives.push("Elite accuracy, hardly misses his recievers and can throw any route in the game")
        }else if(accuracy>80){
            positives.push("Very consistent passing the ball, the staff at " + college + " helped highlight " +player.name+"'s abilities")
        }else if(accuracy>75){
            //notes.push("Accuracy is workable at the next level given his coaches play to his strengths")
        } else {
            negatives.push("Does not have NFL caliber accuracy, would take a lot of coaching to get " +player.name+" to where he needs to be")
        }
        let handlePressure = player.stats.ThrowUnderPressure
        if(handlePressure>85){
            positives.push("Unphased when defenders are in his face")
        }else if(handlePressure>80){
            positives.push("Very good at handling pressure for a " +player.age+" year old")
        }else if(handlePressure>75){
            //average
        } else if(handlePressure>69){
            negatives.push("May be problematic whith pressure in his face")
        } else {
            negatives.push("Has shown that he cannot handle pressure well")
        }
        let speed = player.stats.speed
        if(speed>89){
            positives.push("Could be an elite rusher at the position")
        } else if(speed>84){
            positives.push("Very good at extending plays and playing out of structure")
        } else if(speed>79){
            //average
        } else if(speed>74){
            negatives.push("Will probably not be able to add much to an NFL offense outside of the pocket")
        } else {
            negatives.push("A statue in the pocket")
        }
        if(player.stats.throwAccuracyShort<71){negatives.push("Sometimes has trouble hitting short routes outside the numbers")}

        if(player.stats.throwAccuracyMid<71){negatives.push("Showed poor accuracy in the intermediate range at " + college)}
        else if(player.stats.throwAccuracyMid<74){negatives.push("Has trouble hitting recievers near the sideline at 10-20 yards out")}

        if(player.stats.breakSack>79){positives.push("Consistently got off blitzing defenders when the pocket collapsed at " + college)}
        else if(player.stats.breakSack>74){positives.push("Is able to occasionally evade  and get off of defenders when the pocket collapses")}

        if(player.stats.throwOnTheRun>79){positives.push(player.name+" has shown that he can throw the ball while getting out of the pocket while maintaining consistent accuracy")}
    }

    if(player.position=="HB" || player.position=="WR" || player.position=="CB" || player.position=="FS" || player.position=="SS" || player.relativeAthleticScore>7.9)
    if(player.relativeAthleticScore>8.6){
        positives.push("Generational athlete")
    }else if(player.relativeAthleticScore>8.5){
        positives.push(player.name+" is a flat out elite athlete")
    }else if(player.relativeAthleticScore>8.2){
        positives.push("Displayed outstanding athleticism at " + player.college)
    }else if(player.relativeAthleticScore>7.9){
        positives.push("Great athlete at the " + player.position + " position")
    }else if(player.relativeAthleticScore>7.4){
        //
    }else if(player.relativeAthleticScore>7.0){
        negatives.push("Not an exciting athlete")
    }else {
        negatives.push("Might not be athletic enough to play at the next level")
    }

    if(player.stats.injury<90){
        negatives.push("Injury concerns")
    }
    if(player.stats.toughness>84){
        positives.push("Gritty player, will always get back up after taking or recieving a hit")
    }

    if(player.stats.awareness>76){
        positives.push("Has great awareness of whats going on around him")
    }
    if(player.stats.awareness<63){
        negatives.push("Sometimes looks lost on the feild")
    }

    if(isPowerHouse(college)){
        positives.push('Played against great competition at ' + college)
    }else{
        negatives.push('Did not have great competition playing at ' + college)
    }

    if(player.stats.kickReturn>=90){ positives.push("Elite kick/punt returner, could project as a player like Devin Hester or Tavon Austin if things don't work out at "+player.position)}
    else if(player.stats.kickReturn>=80){positives.push("Can return kicks and punts if needed") }
    if(player.stats.kickReturn<=80){
        if(player.position==="HB"||player.position==="WR"){negatives.push("Does not bring much as a returner")}
    }
    if(player.position==="HB"){
        if(player.weight+160>=220){positives.push("With a " +(player.weight+160)+ " pound frame, " +player.name+" can power through NFL sized defenders")}
        if(player.weight+160<=190){negatives.push("Size is a potential issue, " +player.name+" will need minimize contact with defenders to make the most of his touches")}
        if(player.stats.carrying>=90){positives.push("Has great ball security")}
        if(player.stats.carrying<=70){negatives.push("Has put the ball on the ground at times")}

        if(player.stats.speed>=96){positives.push("Has breakaway speed like prime Chris Johnson")}
        else if(player.stats.speed>=93){positives.push("Elite straightline speed")}
        else if(player.stats.speed>=90){ positives.push("Ideal speed for a runningback at the next level")}
        else if(player.stats.speed>=89){ }
        else if(player.stats.speed>=85){negatives.push("Will struggle to break away on runs at the next level")}
        else {negatives.push("Does not have the speed to be a successful player at the next level")}

        if(player.stats.acceleration>=93){positives.push("Extremley quick in and out of cuts")}
        else if(player.stats.acceleration>=90){positives.push("Is able to explode and get up to speed quickly on breakaway runs")}
        else if(player.stats.acceleration<=85){positives.push("Needs to work on his quickness")}

        if(player.stats.stiffArm>=88){positives.push("Shades of Derrick Henry in his stiff arm")}
        else if(player.stats.stiffArm>=80){positives.push("Has a strong stiff arm")}
        if(player.stats.stiffArm<=72){negatives.push("Does not have a strong stiff arm")}

        if(player.stats.shortRouteRunning>=75){positives.push("Ran routes out of the backfield at "+college)}
        if(player.stats.shortRouteRunning<=65){negatives.push("Was not utilized in the passing game at "+college+", may not be able to do much recievng at the next level")}
        if(player.stats.ballCarrierVision>=83){positives.push("Is able to see the field really well when he has the ball in his hands")}
        if(player.stats.ballCarrierVision<=78){negatives.push("Needs to improve his vision")}
    }
    if(player.position==="WR"){
        if(player.stats.speed>=98){positives.push("In the conversation to be on of the fastest players in the league right out of the gate")}
        else if(player.stats.speed>=95){positives.push("Elite straightline speed, will not have a problem stretching the field")}
        else if(player.stats.speed>=93){ positives.push("Great speed for a wide reciever at the next level")}
        else if(player.stats.speed>=91){ }
        else if(player.stats.speed>=89){negatives.push("Will struggle to create seperation with speed")}
        else {negatives.push("Not fast enough to play reciever in the NFL, " +player.name+" needs his other traits to carry him to be successful")}

        let routes=(player.stats.shortRouteRunning+player.stats.mediumRouteRunning+player.stats.deepRouteRunning)/3
        if(routes>=85){positives.push("Runs crisp routes all around, looks like Jerry Rice at sometimes in his highlights")}
        else if(routes>=77){positives.push("A solid starting point as a route runner, can be great at the next level with the right coaching")}
        else if(routes<=70){negatives.push(player.name+" was not a very good route runner at " + college+". He will need to rely on his other attributes to carry him at the next level")}

        
        if(player.stats.catch>=90){positives.push("Rarely drops the ball, he has shades of Michael Thomas and DeAndre Hopkins in his game")}
        else if(player.stats.catch>=85){positives.push("Excellent hands, should not have a problem catching balls thrown his way")}
        else if(player.stats.catch<=70){positives.push("Cannot catch well enough to be an NFL reciever")}
        else if(player.stats.catch<=77){negatives.push("Needs to improve his hands at the next level")}

        if(player.stats.catch>=90 && player.height.substring(0,1)==="6"){positives.push("Is able to battle for and win jump balls like Mike Williams")}
        else if(player.stats.catch>=85 && player.height.substring(0,1)==="6"){positives.push("Consistently won jump balls at " + college)}
        else if(player.stats.catch<=73){negatives.push("Jump balls are not a part of " +player.name+"'s game")}

        if(player.stats.shortRouteRunning>=90){positives.push("Runs elite drags, whips and flat routes")}
        if(player.stats.mediumRouteRunning>=90){positives.push("Consistently gets seperation on rotues 10-20 yards from the line of scrimmage")}
        if(player.stats.mediumRouteRunning>=90){positives.push("Found himself wide open down the field during his career at "+college)}

        if(player.stats.release>=87){positives.push("College corners could not press him, should be able to consistently win against top NFL cornerbacks")}
        else if(player.stats.release>=82){positives.push("Is able to get off the line against corners"); negatives.push("Does not show the consistency getting off the ball that you would want in a number 1 reciever")}
        else if(player.stats.release>75){negatives.push("Does not show the consistency getting off the ball that you would want in a number 1 reciever")}
        else if(player.stats.release<=69){negatives.push("Has trouble getting off the line against corners")}

    }
    if(player.position==="HB"||player.position==="WR"){
        if(player.stats.breakTackle>=90){positives.push("Is able to slip off of defenders like Alvin Kamara")}
        else if(player.stats.breakTackle>=85){positives.push("Exceeds at getting off defenders and creating yardage")}
        else if(player.stats.breakTackle>=78){}
        else if(player.stats.breakTackle>=73){negatives.push("Does not break tackles at the consistency you would like to see for a player at the next level")}
        else {negatives.push("Does not exceed at breaking tackles")}

        if(player.stats.trucking>=88){positives.push("Shows insane power when running between the tackles")}
        else if(player.stats.trucking>=80){positives.push("Can run over defenders when needed")}
        if(player.stats.trucking<=72 && player.position=="HB"){negatives.push("Does not have enough strength to run over defenders")}

        if(player.stats.jukeMove>=88){positives.push("Is able to make defenders look silly like prime Lesean McCoy")}
        else if(player.stats.jukeMove>=80){positives.push("Showed that he could juke defenders out of their shoes at " +college)}
        if(player.stats.jukeMove<=72 && player.position=="HB"){negatives.push("Not very smooth at juking out defenders")}
        
        if(player.stats.spinMove>=88){positives.push("At times looks like Barry Sanders putting defenders on his mixtape")}
        else if(player.stats.spinMove>=80){positives.push("Loves to spin out defenders when the opportunity presents itself")}
        if(player.stats.spinMove<=72 && player.position=="HB"){negatives.push("Would like to see him improve on his spin move")}
    }
    if(player.position==="TE"){
        if(player.stats.speed>=90){positives.push("Elite speed for a tight end")}
        else if(player.stats.speed>=85){ positives.push("Ideal speed for a recieving tight end")}
        else if(player.stats.speed>80){ }
        else {negatives.push("Not fast enough to consistently send out on routes")}

        if(player.stats.strength>=80){positives.push("Can out body corners and linebackers with his strength")}
        else if(player.stats.strength>=70){}
        else {negatives.push("Not strong for the position")}

        if(player.stats.runBlock>=65){positives.push("Valuable piece in the run game")}
        else if(player.stats.runBlock<60){negatives.push("Not a good run blocker")}

        let routes=(player.stats.shortRouteRunning+player.stats.mediumRouteRunning+player.stats.deepRouteRunning)/3
        if(routes>=80){positives.push("Great route runner")}
        else if(routes>=70){positives.push("Solid route runner")}
        else if(routes<=65){negatives.push("Not a great route runner")}

        
        if(player.stats.catch>=90){positives.push("Rarely drops the ball, he has shades of Michael Thomas and DeAndre Hopkins in his game")}
        else if(player.stats.catch>=85){positives.push("Excellent hands, should not have a problem catching balls thrown his way")}
        else if(player.stats.catch<=70){positives.push("Cannot catch well enough to be an NFL reciever")}
        else if(player.stats.catch<=77){negatives.push("Needs to improve his hands at the next level")}

        if(player.stats.catchInTraffic>=90 && player.height.substring(0,1)==="6"){positives.push("Is able to battle for and win jump balls like Mike Williams")}
        else if(player.stats.catchInTraffic>=85 && player.height.substring(0,1)==="6"){positives.push("Consistently won jump balls at " + college)}
        else if(player.stats.catchInTraffic<=73){negatives.push("Jump balls are not a part of " +player.name+"'s game")}

        if(player.stats.shortRouteRunning>=80){positives.push("Runs great drags, whips and flat routes")}
        if(player.stats.mediumRouteRunning>=80){positives.push("Finds himself open often on rotues 10-20 yards from the line of scrimmage")}
        if(player.stats.deepRouteRunning>=80){positives.push("The defense will occasionally lose him downfield")}

    }
    if(player.position==="CB"){
        if(player.stats.speed>=97){positives.push("Generational speed at the position")}
        else if(player.stats.speed>=94){positives.push("Elite speed for a defensive back, can run with just about any reciever")}
        else if(player.stats.speed>=91){ positives.push("Has the speed GMs want at the next level")}
        else if(player.stats.speed>=89){ }
        else {negatives.push("Not fast enough to play corner, might translate better to saftey with his speed concerns")}

        if(player.stats.press>=80){positives.push("Exceeds at pressing his reciever at the line of scrimmage")}
        else if(player.stats.press>=74){positives.push("Can press his reciever when needed, but will lose occaisonally")}
        if(player.stats.press<70 ){negatives.push("Is not good in press coverage")}
    }
    if(player.position==="FS"||player.position==="SS"){
        if(player.stats.speed>=94){positives.push("Safties this fast are hard to find, GMs will be jumping to get this guy come draft day")}
        else if(player.stats.speed>=91){positives.push("Has great range for a saftey")}
        else if(player.stats.speed>=89){ positives.push("Will be able to cover the field")}
        else if(player.stats.speed>=87){ }
        else {negatives.push("Will be a liability in deep zones, a player with his speed projects better as a box saftey")}
    }
    if(player.position==="CB"||player.position==="FS"||player.position==="SS"){
        if(player.stats.manCoverage>=80){positives.push("Potentially elite man coverage")}
        else if(player.stats.manCoverage>=75){positives.push("Great man to man abilities for a player coming out of the draft")}
        else if(player.stats.manCoverage<70 && player.position==="CB"){negatives.push("Gets lost easily in man coverage")}

        if(player.stats.zoneCoverage>=80){positives.push("Very instinctual in zone coverage")}
        else if(player.stats.zoneCoverage>=75){positives.push("Did not struggle to play zone at "+college)}
        else if(player.stats.zoneCoverage<70){negatives.push("Struggles in zone coverage")}

    }
    if(player.position==="LOLB"||player.position==="ROLB"
    ||player.position==="LE"||player.position==="DT"||player.position==="RE"){
        if(player.stats.speed>=90){positives.push("Elite speed at " +player.position)}
        else if(player.stats.speed>=85){positives.push("Great speed")}
        else if(player.position!=="DT") {negatives.push("Might not be fast enough to make plays on the line or in the box")}
        
        if(player.stats.powerMove>=85){positives.push("Has elite bull and club rush moves")}
        else if(player.stats.powerMove>=78){positives.push("Shows great power vs offensive lineman")}
        else if(player.stats.powerMove<70){negatives.push("Needs to work on his power vs lineman")}
        
        if(player.stats.finesseMove>=78){positives.push("Shows flashes of what his ceiling could be with his speed, spin, and swim moves while rushing")}
        else if(player.stats.finesseMove<70){negatives.push("Speed while rushing is not a strong suit")}
    }

    if(player.position==="MLB"){
        if(player.stats.speed>=90){positives.push("Has the speed to meet the ballcarrier in the backfield")}
        else if(player.stats.speed>=85){positives.push("Above average speed for a pure linebacker")}
        else if(player.stats.speed<=80){negatives.push("Might have trouble getting to a play ran away from his side of the field")}

        if(player.stats.zoneCoverage>=80){positives.push("Very instinctual in zone coverage")}
        else if(player.stats.zoneCoverage>=75){positives.push("Did not struggle to play zone at "+college)}
        else if(player.stats.zoneCoverage<70){negatives.push("Struggles in zone coverage")}
    }

    if(player.position==="CB"||player.position==="FS"||player.position==="SS"
    ||player.position==="LOLB"||player.position==="MLB"||player.position==="ROLB"
    ||player.position==="LE"||player.position==="DT"||player.position==="RE"){
        if(player.stats.pursuit>=80){positives.push("Takes great angles while pursuing ball carriers")}
        else if(player.stats.pursuit<70){negatives.push("Struggles in zone coverage")}

        // if(player.stats.PRC>=80){positives.push("Spends hours each week on film study outside of regular team hours")}
        // else if(player.stats.PRC<70){negatives.push("Has a hard time recognizing what the offense is doing")}

        if(player.stats.tackle>=80){positives.push("Very good tackler")}
        else if(player.stats.tackle<70){negatives.push("Tackling is a concern")}

        if(player.stats.hitPower>=85){positives.push("Consistently forced fumbles at "+ college)}
        else if(player.stats.hitPower>=80){positives.push("Will occasionaly force a fumble when he has an edge on the ball carrier")}
        else if(player.stats.hitPower>=75){}
        else if(player.stats.hitPower<70 && (player.position==="SS"||player.position==="MLB")){negatives.push("Not aggressive while hitting ball carriers")}

        if(player.stats.blockShedding>=80){positives.push("Was a menace to blockers at "+ college+", "+player.name+" could not be blocked")}
        else if(player.stats.blockShedding>=75){positives.push("Was able to get off of blocks in college")}
        else if(player.stats.blockShedding<70 && !(player.position==="CB"||player.position==="FS")){negatives.push("Struggles getting off blocks")}
    }

    if(player.position==="LT"||player.position==="LG"||player.position==="C"
    ||player.position==="RG"||player.position==="RT"){
        if(player.stats.strength>=95){positives.push("One of the strongest offensive lineman we have seen come into the draft")}
        else if(player.stats.strength>=90){positives.push("Will be able to hold defenders off using his strength")}
        else if(player.stats.strength>=85){negatives.push("Marginal strength for the position")}
        else {negatives.push("Not strong enough to go up against NFL defensive lineman")}

        if(player.stats.passBlock>=80){positives.push("Elite pass blocker")}
        else if(player.stats.passBlock<70){negatives.push("Terrible pass sets")}
        if(player.stats.passBlockPower>=75){positives.push("Holds down against bull rushes and club moves")}
        else if(player.stats.passBlockPower<70){negatives.push("Struggles against power in the pass game")}
        if(player.stats.passBlockFinesse>=75){positives.push("Does well against speed during his pass sets")}
        else if(player.stats.passBlockFinesse<70){negatives.push("Needs to work on countering speed moves in his pass set")}

        if(player.stats.runBlock>=80){positives.push("Mauler in the run game")}
        else if(player.stats.runBlock<70){negatives.push("Not a good run blocker")}
        if(player.stats.runBlockPower>=75){positives.push("Would fit well in a power run scheme")}
        else if(player.stats.runBlockPower<70){negatives.push("Not great at blocking up gap runs")}
        if(player.stats.runBlockFinesse>=75){positives.push("Great zone run blocker")}
        else if(player.stats.runBlockFinesse<70){negatives.push("Gets beat easily during zone runs")}
    }
   // console.log(physicals)
    return {positives, negatives}
}

let throwpowerNotes=[
    "Generational arm strength, can squeeze balls into small windows and throw the ball over 65 yards in the air", 
    "Has zip behind his passes, exciting to watch this guy throw the ball", 
    "NFL caliper arm, will not struggle to make passes at the next level", 
    "Will be able to get by, but overall marginal arm strength", 
    "Not ideal arm strength, his other attributes will carry his game at the next level", 
    "Will struggle passing the ball if he finds his way onto an NFL field", 
]

function isPowerHouse(college){
    switch(college){
        case 'Alabama': 
        case 'Georgia': 
        case 'Oklahoma': 
        case 'Texas': 
        case 'Nebraska': 
        case 'Ohio State': 
        case 'Florida': 
        case 'Tennesse':
        case 'Oregon': 
        case 'Texas A&M': 
        case 'Penn State': 
        case 'LSU': 
        case 'Michigan': 
        case 'Wisconson': 
        case 'Washington': 
        case 'Minnesota': 
        case 'Utah': 
        case 'Florida State': 
        case 'Arkansas': 
        case 'Auburn': 
        case 'Iowa': 
        case 'USC': 
        case 'Notre Dame': 
        case 'West Virginia': 
        case 'Virginia Tech': 
        case 'Pittsburgh': 
        case 'Michigan State': 
        case 'Boston College': 
        case 'Clemson': return true
    }

    return false;
}
function getSpiderData(player){
    let position = player.position
  
      return[
        //{ label: 'Passing', value: ratingsArray[1].passing.OVR },
        { label: 'Speed', value: player.stats.speed },
        { label: 'Power', value: player.stats.strength },
        { label: 'Agility', value: player.stats.agility },
        { label: 'Reaction', value: player.stats.awareness },
        { label: 'Quickness', value: player.stats.acceleration },
        // { label: 'Vertical', value: player.stats.jumping },
  
        // { label: 'Rushing', value: ratingsArray[2].rushing.OVR },
        // { label: 'Recieving', value: ratingsArray[6].recieving.OVR },
      ]
    
  }

function physical(injuryRating, player){
    const proConStyle={
        display:'flex', 
        alignItems: 'center',
        paddingLeft: '10px',
        height:'30px'
      }
    let concerns =  <div style={proConStyle}><FaKitMedical style={{color: 'green', marginRight:'5px'}}/> {'No pressing injury concerns after physical evaluation of ' + player.name +'.'}</div>;
    if (injuryRating <= 50) {
        concerns = <div style={proConStyle}><FaKitMedical style={{color: 'red', marginRight:'5px'}}/> {player.name  + ' has never completed a full season of play. After further medical review picking this player is not adviseable.'} </div> 
      } else if (injuryRating > 50 && injuryRating <= 60) {
        concerns = <div style={proConStyle}><FaKitMedical style={{color: 'red', marginRight:'5px'}}/> {player.name  + ' could be a big injury risk; being able to stay on the field might hold him back.' } </div>
      }else if (injuryRating > 60 && injuryRating <= 70) {
        concerns = <div style={proConStyle}><FaKitMedical style={{color: 'red', marginRight:'5px'}}/> {player.name  + ' will most likely miss some time due to lingering injuries from college.' } </div>
      }else if (injuryRating > 70 && injuryRating <= 80) {
        concerns = <div style={proConStyle}><FaKitMedical style={{color: 'red', marginRight:'5px'}}/> {player.name + ' has a higher risk of injury - Careful monitoring and management are recommended if drafted.'} </div>;
    }else if (injuryRating > 80 && injuryRating <= 90) {
        concerns = <div style={proConStyle}><FaKitMedical style={{color: 'grey', marginRight:'5px'}}/> {player.name  + ' has a moderate risk of injury. Additional precautions should be taken.' } </div>;
      }else if (injuryRating > 90 && injuryRating <= 95) {
        concerns = <div style={proConStyle}><FaKitMedical style={{color: 'green', marginRight:'5px'}}/> {player.name + ' has an exceptionally low risk of injury. He should not have trouble staying on the field.'} </div>;
    }


    let flexibility = <div style={proConStyle}><FaKitMedical style={{color: 'green', marginRight:'5px'}}/> {'Some of the best flexibility and range of motion ever. Could translate to being one of the most athletic players in the league right away.'} </div>
    let flexibilityRating = (player.stats.changeOfDirection + player.stats.agility)/2
    if (flexibilityRating <= 50) {
        flexibility = <div style={proConStyle}><FaKitMedical style={{color: 'red', marginRight:'5px'}}/> {'Very poor flexibility, would not encourage putting in a position to play at the next level.'} </div>;
      } else if (flexibilityRating > 50 && flexibilityRating <= 60) {
        flexibility = <div style={proConStyle}><FaKitMedical style={{color: 'red', marginRight:'5px'}}/> {'Very stiff athlete. Might be a concern if drafted.'} </div>;
      }else if (flexibilityRating > 60 && flexibilityRating <= 70) {
        flexibility = <div style={proConStyle}><FaKitMedical style={{color: 'red', marginRight:'5px'}}/> {'Range of motion and flexibility could be a concern for ' +player.name+'.'} </div>;
      }else if (flexibilityRating > 70 && flexibilityRating <= 80) {
        flexibility = <div style={proConStyle}><FaKitMedical style={{color: 'grey', marginRight:'5px'}}/> {'Decent flexibility, should not be an issue at the next level.'} </div>;
    }else if (flexibilityRating > 80 && flexibilityRating <= 90) {
        flexibility = <div style={proConStyle}><FaKitMedical style={{color: 'green', marginRight:'5px'}}/> {'Has some of the best range of motion out of any prospect.'} </div> 
      }else if (flexibilityRating > 90 && flexibilityRating <= 95) {
        flexibility = <div style={proConStyle}><FaKitMedical style={{color: 'green', marginRight:'5px'}}/> {'Great flexibility and range of motion, should translate to being an elite athlete on the field.'} </div>;
    }

    let neurological = '';
    let neurologicalRating = player.stats.awareness;
    if (neurologicalRating <= 50) {
        neurological = <div style={proConStyle}><FaKitMedical style={{color: 'red', marginRight:'5px'}}/> {player.name + ' has substantial brain damage, would not suggest drafting.'} </div>;
    } else if (neurologicalRating > 50 && neurologicalRating <= 60) {
        neurological = <div style={proConStyle}><FaKitMedical style={{color: 'red', marginRight:'5px'}}/> {'Has poor neurological scans and cognitive awareness.'} </div>;
    } else if (neurologicalRating > 60 && neurologicalRating <= 70) {
        neurological = <div style={proConStyle}><FaKitMedical style={{color: 'red', marginRight:'5px'}}/> {'Has below average neurological scans.'} </div>;
    } else if (neurologicalRating > 70 && neurologicalRating <= 80) {
        neurological = <div style={proConStyle}><FaKitMedical style={{color: 'grey', marginRight:'5px'}}/> {'Has expected cognitive ability for a rookie. Surely will improve over time as well.'} </div>;
    } else if (neurologicalRating > 80 && neurologicalRating <= 90) {
        neurological = <div style={proConStyle}><FaKitMedical style={{color: 'green', marginRight:'5px'}}/> {player.name + ' has very good cognitive skills.'} </div>;
    } else if (neurologicalRating > 90 ){
        neurological = <div style={proConStyle}><FaKitMedical style={{color: 'green', marginRight:'5px'}}/> {'No neurological concerns.'} </div>;
    }
    
    let height = Math.floor(player.height / 12)
    let inches = player.height%12
    let heightColor = 'grey'
    let weight = player.weight+ 160
    let weightColor = 'grey'

    if(player.position == 'QB'){
        if(height>=6 && inches>=3){
            heightColor='green'
        }
        if(height<6){
            heightColor='red'
        }
        if(weight>=225){
            weightColor='green'
        }
        if(weight<=215){
            weightColor='red'
        }
    }
    if(player.position == 'HB' || player.position == 'FB' || player.position == 'WR' || player.position == 'CB' || player.position == 'FS' || player.position == 'SS'){
        if(height>=6){
            heightColor='green'
        }
        if(height<6 && inches<=9){
            heightColor='red'
        }
        if(player.position == 'HB'){
            if(weight>=215){
                weightColor='green'
            }
            if(weight<=199){
                weightColor='red'
            }
        }
        if(player.position == 'FB'){
            if(weight>=240){
                weightColor='green'
            }
            if(weight<=225){
                weightColor='red'
            }
        }
        if(player.position == 'WR' || player.position == 'CB' || player.position == 'FS' || player.position == 'SS'){
            if(weight>=195){
                weightColor='green'
            }
            if(weight<=185){
                weightColor='red'
            }
        }
      
    }
    if(player.position == 'TE'){
        if(height>=6 && inches>=4){
            heightColor='green'
        }
        if(height<=6 && inches<=1){
            heightColor='red'
        }
        if(weight>=250){
            weightColor='green'
        }
        if(weight<=235){
            weightColor='red'
        }
    }
    if(player.position == 'LT' || player.position == 'LG' || player.position == 'C' || player.position == 'RG' || player.position == 'RT'){
        if(height>=6 && inches>=4){
            heightColor='green'
        }
        if(height<=6 && inches<=2){
            heightColor='red'
        }
        if(weight>=320){
            weightColor='green'
        }
        if(weight<300){
            weightColor='red'
        }
    }
    if(player.position == 'DT' || player.position == 'LE' || player.position == 'RE'){
        if(height>=6 && inches>=3){
            heightColor='green'
        }
        if(height<=6 && inches<=1){
            heightColor='red'
        }
        if(weight>=260){
            weightColor='green'
        }
        if(weight<=249){
            weightColor='red'
        }
        if(player.position == 'DT'){
            if(weight>=305){
                weightColor='green'
            }
            if(weight<=280){
                weightColor='red'
            }
        }
    }
    if(player.position == 'LOLB' || player.position == 'ROLB' || player.position == 'MLB'){
        if(height>=6 && inches>=2){
            heightColor='green'
        }
        if(height<6 && inches<=11){
            heightColor='red'
        }
        if(weight>=245){
            weightColor='green'
        }
        if(weight<225){
            weightColor='red'
        }
    }

    return (
        <div>
            <h4>Medical Report</h4>
            <div>{concerns}</div>
            <div>{flexibility}</div>
            <div>{neurological}</div>

            <h4>Official Measurements</h4>
            <div style={proConStyle}><TbRulerMeasure style={{color: heightColor, marginRight:'5px'}}/> Height: {Math.floor(player.height / 12)}'{player.height%12}"</div>
            <div style={proConStyle}><FaBalanceScaleLeft style={{color:weightColor, marginRight:'5px'}}/> Weight: {weight}lbs</div>
        </div>

    )
}

function getStats(prospect){
    let years = parseClass(prospect.age, prospect.portrait)
    let stats=[];
    let ret = <></>
    let dev = prospect.development
    let inj = prospect.stats.injury
    let ovr = prospect.overall
    let isPowerHouseTeam = isPowerHouse(prospect.college)
    let multiplier = 1

    const thStyle = {
        padding: '10px',
    }
    if(prospect.position=="QB"){
        if(dev=="Normal"){
            multiplier=multiplier-0.025
        } else if(dev=="Superstar"){
            multiplier=multiplier+0.075
        } else if(dev=="XFactor"){
            multiplier=multiplier+0.15
        }
        multiplier=multiplier+ (ovr/2 - 74/2)/125
        if(isPowerHouse){
            multiplier=multiplier+0.075
        }
        let yardMultiplier=1+(prospect.stats.throwAccuracyDeep - 75)*5/100
        let completionsMultiplier=1+ (prospect.stats.throwAccuracyDeep - 75)/100 + (prospect.stats.throwAccuracyMid - 75)/100 + (prospect.stats.throwAccuracyShort - 75)/100
        let attemptsMultiplier=1+ (prospect.stats.throwAccuracyDeep - 70)/2/100 + (prospect.stats.throwAccuracyMid - 70)/2/100 + (prospect.stats.throwAccuracyShort - 70)/2/100
        let tdOffset= (prospect.stats.throwAccuracyDeep - 78) + (prospect.stats.throwAccuracyMid - 77) + (prospect.stats.throwAccuracyShort - 76) + (prospect.stats.throwPower - 92)
        let intOffset= (prospect.stats.throwAccuracyDeep + prospect.stats.throwAccuracyMid + prospect.stats.throwAccuracyShort)/3 - 79
        let rushingYardMultiplier= 500 * (1 + (prospect.stats.speed - 84)/20  + (prospect.stats.spinMove - 79)/40  + (prospect.stats.jukeMove - 79)/40  + (prospect.stats.playAction - 79)/100)
        let rushingTDMultiplier= 2.5 * (1 + (prospect.weight - 60)/10  + (prospect.stats.speed - 84)/10  + (prospect.stats.spinMove - 79)/40  + (prospect.stats.jukeMove - 79)/40  + (prospect.stats.playAction - 79)/100)

        if(rushingYardMultiplier<0){
            rushingYardMultiplier = 0-prospect.overall-(100-prospect.stats.speed)
        }


        for(let i=0; i<years; i++){
        //figure randomly alter stats based on i and injury
        let classModifier = 1;
        let injuryModifier = 1;
        if(i === 0 && years>1){
            //player has a good chance of not playing freshman season or not appearing in alot of games
            if(prospect.overall<77 && !isPowerHouse(prospect.college)){
                classModifier = 0.1
            } else if(prospect.overall<77 && isPowerHouse(prospect.college)){
                classModifier = 0
            } else {
                //player always started
                classModifier = 0.7321
            }
        }
        if(i === 1 && years>1){
            classModifier = 0.83123  
        }
        if(i === 2 && years>2){
            classModifier = 0.9321
        }

        if(prospect.stats.injury<50){
            if(i===0){
                injuryModifier=0.521
            }
            if(i===1){
                injuryModifier=0.53
            }
            if(i===2 && years>3){
                injuryModifier=0
            } else if(i===2){
                injuryModifier=0.600321
            }
            if(i===3){
                injuryModifier=0.6312
            }
        }else if(prospect.stats.injury<60){
            if(i===0){
                injuryModifier=0.184
            }
            if(i===1){
                injuryModifier=0.675
            }
            if(i===2){
                injuryModifier=0.721
            }
            if(i===3){
                injuryModifier=0.778
            }
        }else if(prospect.stats.injury<70){
            if(i===0){
                injuryModifier=0.332
            }
            if(i===1){
                injuryModifier=0.732
            }
            if(i===2){
                injuryModifier=0.752
            }
            if(i===3){
                injuryModifier=0.8123
            }
        }else if(prospect.stats.injury<80){
            if(i===0){
                injuryModifier=0.67
            }            
            if(i===1){
                injuryModifier=0.8321
            }
            if(i===2){
                injuryModifier=0.9123
            }
            if(i===3){
                injuryModifier=0.9445
            }
        }

        stats.push({
                "icon":  classModifier<=0.5 ? <PiChairFill style={{color:'grey'}}/>: injuryModifier<=0.62 ? <FaUserInjured style={{color:'red'}}/>: null,
                "passYards":  classModifier==0 || injuryModifier==0 ? '-' : Math.trunc(QBStats[0].passYards * multiplier * yardMultiplier * classModifier * injuryModifier),
                "completions":  classModifier==0 || injuryModifier==0 ? '-' : Math.trunc(QBStats[0].completions * completionsMultiplier * classModifier * injuryModifier),
                "attempts":  classModifier==0 || injuryModifier==0 ? '-' :Math.trunc(QBStats[0].attempts * 1/multiplier * attemptsMultiplier  * classModifier * injuryModifier),
                "long":  classModifier==0 || injuryModifier==0 ? '-' :Math.trunc(QBStats[0].long * multiplier  * classModifier * injuryModifier),
                "touchdowns":  classModifier==0 || injuryModifier==0 ? '-' :Math.trunc((QBStats[0].touchdowns  * multiplier + tdOffset) * classModifier * injuryModifier),
                "interceptions": classModifier==0 || injuryModifier==0 ? '-' :Math.abs(Math.trunc((QBStats[0].interceptions * (1/multiplier) - intOffset)  * classModifier * injuryModifier)),
                "sacks":  classModifier==0 || injuryModifier==0 ? '-' :Math.trunc(QBStats[0].sacks * (1/multiplier) * classModifier * injuryModifier),
                "rushingYards": classModifier==0 || injuryModifier==0 ? '-' :Math.trunc((QBStats[0].rushingYards + rushingYardMultiplier) * classModifier * injuryModifier),
                "rushingTouchdowns": classModifier==0 || injuryModifier==0 ? '-' :Math.trunc((QBStats[0].rushingTouchdowns + rushingTDMultiplier*multiplier) * classModifier * injuryModifier),
            })
        }
        
        
        ret = 
        <div>
        <tr>
        <th style={thStyle}></th>
        <th style={thStyle}></th>
          <th style={thStyle}>Pass Yards</th>
          <th style={thStyle}>Completions</th>
          <th style={thStyle}>Attempts</th>
          <th style={thStyle}>Long</th>
          <th style={thStyle}>Touchdowns</th>
          <th style={thStyle}>Interceptions</th>
          <th style={thStyle}>Sacks</th>
          <th style={thStyle}>Rush Yards</th>
          <th style={thStyle}>Rush Touchdowns</th>
        </tr>
          {stats.map((stat, index) => (
        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fdfdfd' }}>
         <td style={thStyle}> <img
              style={{width:'25px'}}
              src={`/Images/CollegeLogos/${prospect.college}.svg`}
            /></td>
        <td style={thStyle}>{stat.icon}</td>
            <td style={thStyle}>{stat.passYards}</td>
            <td style={thStyle}>{stat.completions}</td>
            <td style={thStyle}>{stat.attempts}</td>
            <td style={thStyle}>{stat.long}</td>
            <td style={thStyle}>{stat.touchdowns}</td>
            <td style={thStyle}>{stat.interceptions}</td>
            <td style={thStyle}>{stat.sacks}</td>
            <td style={thStyle}>{stat.rushingYards}</td>
            <td style={thStyle}>{stat.rushingTouchdowns}</td>

          </tr>
        ))}
        </div>

    }
    if(prospect.position=="HB"){
        if(dev=="Normal"){
            multiplier=multiplier-0.025
        } else if(dev=="Superstar"){
            multiplier=multiplier+0.075
        } else if(dev=="XFactor"){
            multiplier=multiplier+0.15
        }
        multiplier=multiplier+ (ovr/2 - 74/2)/125
        if(isPowerHouse){
            multiplier=multiplier+0.075
        }
        let attemptsMultiplier=1+ (prospect.stats.trucking - 70)/2/100 + (prospect.stats.spinMove - 70)/2/100 + (prospect.stats.jukeMove - 70)/2/100
        let rushingYardMultiplier= 500 * (1 + (prospect.stats.speed - 84)/20  + (prospect.stats.spinMove - 79)/40  + (prospect.stats.jukeMove - 79)/40  + (prospect.stats.playAction - 79)/100)
        let rushingTDMultiplier= 2.5 * (1 + (prospect.weight - 60)/10  + (prospect.stats.speed - 84)/10  + (prospect.stats.spinMove - 79)/40  + (prospect.stats.jukeMove - 79)/40  + (prospect.stats.playAction - 79)/100)
        let receivingYardMultiplier= 500 * (1 + (prospect.stats.speed - 84)/20 + (prospect.stats.catching - 70)/30 + (prospect.stats.spinMove - 79)/40  + (prospect.stats.jukeMove - 79)/40  + (prospect.stats.playAction - 79)/100)
        let receivingTDMultiplier= 2.5 * (1 + (prospect.stats.catching - 70)/30 + (prospect.stats.speed - 84)/10  + (prospect.stats.spinMove - 79)/40  + (prospect.stats.jukeMove - 79)/40  + (prospect.stats.playAction - 79)/100)
        
        if(rushingYardMultiplier<0){
            rushingYardMultiplier = 0-prospect.overall-(100-prospect.stats.speed)
        }


        for(let i=0; i<years; i++){
        //figure randomly alter stats based on i and injury
        let classModifier = 1;
        let injuryModifier = 1;
        if(i === 0 && years>1){
            //player has a good chance of not playing freshman season or not appearing in alot of games
            if(prospect.overall<76 && isPowerHouse(prospect.college)){
                classModifier = 0.1
            } else if(prospect.overall<76 && !isPowerHouse(prospect.college)){
                classModifier = 0
            } else {
                //player always started
                classModifier = 0.7321
            }
        }
        if(i === 1 && years>1){
            classModifier = 0.83123  
        }
        if(i === 2 && years>2){
            classModifier = 0.9321
        }

        if(prospect.stats.injury<75){
            if(i===0){
                injuryModifier=0.521
            }
            if(i===1){
                injuryModifier=0.53
            }
            if(i===2 && years>3){
                injuryModifier=0
            } else if(i===2){
                injuryModifier=0.600321
            }
            if(i===3){
                injuryModifier=0.6312
            }
        }else if(prospect.stats.injury<85){
            if(i===0){
                injuryModifier=0.184
            }
            if(i===1){
                injuryModifier=0.615
            }
            if(i===2){
                injuryModifier=0.721
            }
            if(i===3){
                injuryModifier=0.778
            }
        }

        stats.push({
                "icon":  classModifier<=0.5 ? <PiChairFill style={{color:'grey'}}/>: injuryModifier<=0.62 ? <FaUserInjured style={{color:'red'}}/>: null,
                "attempts":  classModifier==0 || injuryModifier==0 ? '-' :Math.trunc(RBStats[0].attempts * 1/multiplier * attemptsMultiplier  * classModifier * injuryModifier),
                "rushingYards": classModifier==0 || injuryModifier==0 ? '-' :Math.trunc((RBStats[0].rushingYards + rushingYardMultiplier) * classModifier * injuryModifier),
                "rushingTouchdowns": classModifier==0 || injuryModifier==0 ? '-' :Math.trunc((RBStats[0].rushingTouchdowns + rushingTDMultiplier*multiplier) * classModifier * injuryModifier),
                "recievingYards": classModifier==0 || injuryModifier==0 ? '-' :Math.trunc((RBStats[0].recievingYards + receivingYardMultiplier*multiplier) * classModifier * injuryModifier),
                "recievingTouchdowns": classModifier==0 || injuryModifier==0 ? '-' :Math.trunc((RBStats[0].recievingTouchdowns + receivingTDMultiplier*multiplier) * classModifier * injuryModifier)
            })
        }
        
        ret = 
        <div>
        <tr>
        <th style={thStyle}></th>
        <th style={thStyle}></th>

          <th style={thStyle}>Attempts</th>
          <th style={thStyle}>Rush Yards</th>
          <th style={thStyle}>Rush Touchdowns</th>
          <th style={thStyle}>Recieiving Yards</th>
          <th style={thStyle}>Recieiving Touchdowns</th>

        </tr>
          {stats.map((stat, index) => (
        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fdfdfd' }}>
         <td style={thStyle}> <img
              style={{width:'25px'}}
              src={`/Images/CollegeLogos/${prospect.college}.svg`}
            /></td>
        <td style={thStyle}>{stat.icon}</td>
            <td style={thStyle}>{stat.attempts}</td>
            <td style={thStyle}>{stat.rushingYards}</td>
            <td style={thStyle}>{stat.rushingTouchdowns}</td>
            <td style={thStyle}>{stat.recievingYards}</td>
            <td style={thStyle}>{stat.recievingTouchdowns}</td>
          </tr>
        ))}
        </div>

    }
    if(prospect.position=="WR" || prospect.position=="TE"){
        let index = 0
        if(prospect.position=="TE"){
            index = 1
        }
        if(dev=="Normal"){
            multiplier=multiplier-0.025
        } else if(dev=="Superstar"){
            multiplier=multiplier+0.075
        } else if(dev=="XFactor"){
            multiplier=multiplier+0.15
        }
        multiplier=multiplier+ (ovr/2 - 74/2)/125
        if(isPowerHouse){
            multiplier=multiplier+0.075
        }
        let attemptsMultiplier=1+ (prospect.stats.catching - 70)/2/100 + (prospect.stats.shortRouteRunning - 70)/2/100 + (prospect.stats.mediumRouteRunning - 70)/2/100
         let receivingYardMultiplier= 500 * (1 + (prospect.stats.speed - 84)/20 + (prospect.stats.catching - 70)/30 + (prospect.stats.spinMove - 79)/40  + (prospect.stats.jukeMove - 79)/40  + (prospect.stats.playAction - 79)/100)
        let receivingTDMultiplier= 2.5 * (1 + (prospect.stats.catching - 70)/30 + (prospect.stats.speed - 84)/10  + (prospect.stats.spinMove - 79)/40  + (prospect.stats.jukeMove - 79)/40  + (prospect.stats.playAction - 79)/100)
        

        for(let i=0; i<years; i++){
        //figure randomly alter stats based on i and injury
        let classModifier = 1;
        let injuryModifier = 1;
        if(i === 0 && years>1){
            //player has a good chance of not playing freshman season or not appearing in alot of games
            if(prospect.overall<76 && isPowerHouse(prospect.college)){
                classModifier = 0.1
            } else if(prospect.overall<76 && !isPowerHouse(prospect.college)){
                classModifier = 0
            } else {
                //player always started
                classModifier = 0.7321
            }
        }
        if(i === 1 && years>1){
            classModifier = 0.83123  
        }
        if(i === 2 && years>2){
            classModifier = 0.9321
        }

        if(prospect.stats.injury<75){
            if(i===0){
                injuryModifier=0.521
            }
            if(i===1){
                injuryModifier=0.53
            }
            if(i===2 && years>3){
                injuryModifier=0
            } else if(i===2){
                injuryModifier=0.600321
            }
            if(i===3){
                injuryModifier=0.6312
            }
        }else if(prospect.stats.injury<85){
            if(i===0){
                injuryModifier=0.184
            }
            if(i===1){
                injuryModifier=0.615
            }
            if(i===2){
                injuryModifier=0.721
            }
            if(i===3){
                injuryModifier=0.778
            }
        }

        stats.push({
                "icon":  classModifier<=0.5 ? <PiChairFill style={{color:'grey'}}/>: injuryModifier<=0.62 ? <FaUserInjured style={{color:'red'}}/>: null,
                "catches":  classModifier==0 || injuryModifier==0 ? '-' :Math.trunc(WRStats[index].catches * 1/multiplier * attemptsMultiplier  * classModifier * injuryModifier),
                "recievingYards": classModifier==0 || injuryModifier==0 ? '-' :Math.trunc((WRStats[index].recievingYards + receivingYardMultiplier*multiplier) * classModifier * injuryModifier),
                "recievingTouchdowns": classModifier==0 || injuryModifier==0 ? '-' :Math.trunc((WRStats[index].recievingTouchdowns + receivingTDMultiplier*multiplier) * classModifier * injuryModifier),
                "long":  classModifier==0 || injuryModifier==0 ? '-' :Math.trunc(WRStats[index].long * multiplier  * classModifier * injuryModifier),
            })
        }
        
        ret = 
        <div>
        <tr>
        <th style={thStyle}></th>
        <th style={thStyle}></th>

          <th style={thStyle}>Catches</th>
          <th style={thStyle}>Recieiving Yards</th>
          <th style={thStyle}>Recieiving Touchdowns</th>
          <th style={thStyle}>Long</th>

        </tr>
          {stats.map((stat, index) => (
        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fdfdfd' }}>
         <td style={thStyle}> <img
              style={{width:'25px'}}
              src={`/Images/CollegeLogos/${prospect.college}.svg`}
            /></td>
        <td style={thStyle}>{stat.icon}</td>
            <td style={thStyle}>{stat.catches}</td>
            <td style={thStyle}>{stat.recievingYards}</td>
            <td style={thStyle}>{stat.recievingTouchdowns}</td>
            <td style={thStyle}>{stat.long}</td>
          </tr>
        ))}
        </div>

    }
    if(prospect.position=="CB" || prospect.position=="FS" || prospect.position=="SS"){
        let index = 0
        if(prospect.position=="FS"){
            index = 1
        }
        if(prospect.position=="SS"){
            index = 2
        }
        if(dev=="Normal"){
            multiplier=multiplier-0.025
        } else if(dev=="Superstar"){
            multiplier=multiplier+0.075
        } else if(dev=="XFactor"){
            multiplier=multiplier+0.15
        }
        multiplier=multiplier+ (ovr/2 - 74/2)/125
        if(isPowerHouse){
            multiplier=multiplier+0.075
        }

        let attemptsMultiplier=1+ (prospect.stats.zoneCoverage - 76)/2/100 + (prospect.stats.manCoverage - 76)/2/100 + (prospect.stats.press - 76)/2/100
        let receivingYardMultiplier= 1 * (1 + (prospect.stats.speed - 90)/20 + (prospect.stats.manCoverage - 76)/30 + (prospect.stats.zoneCoverage - 76)/40  + (prospect.stats.jukeMove - 79)/40 )
        let receivingTDMultiplier= 30 * (1 + (prospect.stats.tackle - 70)/30 + (prospect.stats.pursuit - 79)/40  + (prospect.stats.speed - 90)/40)
        

        for(let i=0; i<years; i++){
        //figure randomly alter stats based on i and injury
        let classModifier = 1;
        let injuryModifier = 1;
        if(i === 0 && years>1){
            //player has a good chance of not playing freshman season or not appearing in alot of games
            if(prospect.overall<76 && isPowerHouse(prospect.college)){
                classModifier = 0.1
            } else if(prospect.overall<76 && !isPowerHouse(prospect.college)){
                classModifier = 0
            } else {
                //player always started
                classModifier = 0.7321
            }
        }
        if(i === 1 && years>1){
            classModifier = 0.83123  
        }
        if(i === 2 && years>2){
            classModifier = 0.9321
        }

        if(prospect.stats.injury<75){
            if(i===0){
                injuryModifier=0.521
            }
            if(i===1){
                injuryModifier=0.53
            }
            if(i===2 && years>3){
                injuryModifier=0
            } else if(i===2){
                injuryModifier=0.600321
            }
            if(i===3){
                injuryModifier=0.6312
            }
        }else if(prospect.stats.injury<85){
            if(i===0){
                injuryModifier=0.184
            }
            if(i===1){
                injuryModifier=0.615
            }
            if(i===2){
                injuryModifier=0.721
            }
            if(i===3){
                injuryModifier=0.778
            }
        }

        stats.push({
                "icon":  classModifier<=0.5 ? <PiChairFill style={{color:'grey'}}/>: injuryModifier<=0.62 ? <FaUserInjured style={{color:'red'}}/>: null,
                "passBreakups":  classModifier==0 || injuryModifier==0 ? '-' :Math.trunc(DBStats[index].passBreakups * multiplier * attemptsMultiplier  * classModifier * injuryModifier),
                "interceptions": classModifier==0 || injuryModifier==0 ? '-' :Math.trunc((DBStats[index].interceptions + receivingYardMultiplier*multiplier) * classModifier * injuryModifier),
                "tackles": classModifier==0 || injuryModifier==0 ? '-' :Math.trunc((DBStats[index].tackles + receivingTDMultiplier*multiplier) * classModifier * injuryModifier),
            })
        }
        
        ret = 
        <div>
        <tr>
        <th style={thStyle}></th>
        <th style={thStyle}></th>

          <th style={thStyle}>Pass Breakups</th>
          <th style={thStyle}>Interceptions</th>
          <th style={thStyle}>Tackles</th>
        </tr>
          {stats.map((stat, index) => (
        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fdfdfd' }}>
         <td style={thStyle}> <img
              style={{width:'25px'}}
              src={`/Images/CollegeLogos/${prospect.college}.svg`}
            /></td>
        <td style={thStyle}>{stat.icon}</td>
            <td style={thStyle}>{stat.passBreakups}</td>
            <td style={thStyle}>{stat.interceptions}</td>
            <td style={thStyle}>{stat.tackles}</td>
          </tr>
        ))}
        </div>

    }
    if(prospect.playerType.toLowerCase().includes('rusher') || prospect.position=="LE" || prospect.position=="RE" || prospect.position=="DT"){
        let index = 0
        if(prospect.position=='DT'){
            index = 1
        }
        if(dev=="Normal"){
            multiplier=multiplier-0.025
        } else if(dev=="Superstar"){
            multiplier=multiplier+0.075
        } else if(dev=="XFactor"){
            multiplier=multiplier+0.15
        }
        multiplier=multiplier+ (ovr/2 - 74/2)/125
        if(isPowerHouse){
            multiplier=multiplier+0.075
        }

        let attemptsMultiplier=1+ (prospect.stats.finesseMove - 77)/50 + (prospect.stats.powerMove - 77)/50 + (prospect.stats.blockShedding - 75)/80 + (prospect.stats.speed - 82)/100
        let receivingYardMultiplier=30 * (1 + (prospect.stats.tackle - 70)/30 + (prospect.stats.pursuit - 79)/40  + (prospect.stats.speed - 79)/40 + (prospect.stats.blockShedding - 78)/40)
        let receivingTDMultiplier= 30 * (1 + (prospect.stats.tackle - 70)/30 + (prospect.stats.pursuit - 79)/40  + (prospect.stats.speed - 79)/40 + (prospect.stats.blockShedding - 78)/40)
        
        for(let i=0; i<years; i++){
        //figure randomly alter stats based on i and injury
        let classModifier = 1;
        let injuryModifier = 1;
        if(i === 0 && years>1){
            //player has a good chance of not playing freshman season or not appearing in alot of games
            if(prospect.overall<76 && isPowerHouse(prospect.college)){
                classModifier = 0.1
            } else if(prospect.overall<76 && !isPowerHouse(prospect.college)){
                classModifier = 0
            } else {
                //player always started
                classModifier = 0.7321
            }
        }
        if(i === 1 && years>1){
            classModifier = 0.83123  
        }
        if(i === 2 && years>2){
            classModifier = 0.9321
        }

        if(prospect.stats.injury<75){
            if(i===0){
                injuryModifier=0.521
            }
            if(i===1){
                injuryModifier=0.53
            }
            if(i===2 && years>3){
                injuryModifier=0
            } else if(i===2){
                injuryModifier=0.600321
            }
            if(i===3){
                injuryModifier=0.6312
            }
        }else if(prospect.stats.injury<85){
            if(i===0){
                injuryModifier=0.184
            }
            if(i===1){
                injuryModifier=0.615
            }
            if(i===2){
                injuryModifier=0.721
            }
            if(i===3){
                injuryModifier=0.778
            }
        }

        stats.push({
                "icon":  classModifier<=0.5 ? <PiChairFill style={{color:'grey'}}/>: injuryModifier<=0.62 ? <FaUserInjured style={{color:'red'}}/>: null,
                "sacks":  classModifier==0 || injuryModifier==0 ? '-' :Math.trunc(DLStats[index].sacks * multiplier * attemptsMultiplier  * classModifier * injuryModifier),
                "tacklesForLoss": classModifier==0 || injuryModifier==0 ? '-' :Math.trunc((DLStats[index].tacklesForLoss + receivingYardMultiplier*multiplier) * classModifier * injuryModifier),
                "tackles": classModifier==0 || injuryModifier==0 ? '-' :Math.trunc((DLStats[index].tackles + receivingTDMultiplier*multiplier) * classModifier * injuryModifier),
            })
        }
        
        ret = 
        <div>
        <tr>
        <th style={thStyle}></th>
        <th style={thStyle}></th>

          <th style={thStyle}>Sacks</th>
          <th style={thStyle}>Tackles</th>
          <th style={thStyle}>Tackles For Loss</th>
        </tr>
          {stats.map((stat, index) => (
        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fdfdfd' }}>
         <td style={thStyle}> <img
              style={{width:'25px'}}
              src={`/Images/CollegeLogos/${prospect.college}.svg`}
            /></td>
        <td style={thStyle}>{stat.icon}</td>
            <td style={thStyle}>{stat.sacks}</td>
            <td style={thStyle}>{stat.tackles}</td>
            <td style={thStyle}>{stat.tacklesForLoss}</td>
          </tr>
        ))}
        </div>

    }
    if((!prospect.playerType.toLowerCase().includes('rusher') && (prospect.position=="LOLB" || prospect.position=="ROLB")) || prospect.position=="MLB"){
        let index = 0
        if(dev=="Normal"){
            multiplier=multiplier-0.025
        } else if(dev=="Superstar"){
            multiplier=multiplier+0.075
        } else if(dev=="XFactor"){
            multiplier=multiplier+0.15
        }
        multiplier=multiplier+ (ovr/2 - 74/2)/125
        if(isPowerHouse){
            multiplier=multiplier+0.075
        }

        let attemptsMultiplier=1+ (prospect.stats.finesseMove - 77)/50 + (prospect.stats.powerMove - 77)/50 + (prospect.stats.blockShedding - 75)/80 + (prospect.stats.speed - 84)/100
        let receivingYardMultiplier=20 * (1 + (prospect.stats.tackle - 70)/30 + (prospect.stats.pursuit - 79)/40  + (prospect.stats.speed - 84)/40 + (prospect.stats.blockShedding - 78)/40)
        let receivingTDMultiplier= 30 * (1 + (prospect.stats.tackle - 70)/30 + (prospect.stats.pursuit - 79)/40  + (prospect.stats.speed - 84)/40 + (prospect.stats.blockShedding - 78)/40)
        let interceptionMultiplier =  (1 + (prospect.stats.zoneCoverage - 69)/40 + (prospect.stats.speed - 84)/70)
        for(let i=0; i<years; i++){
        //figure randomly alter stats based on i and injury
        let classModifier = 1;
        let injuryModifier = 1;
        if(i === 0 && years>1){
            //player has a good chance of not playing freshman season or not appearing in alot of games
            if(prospect.overall<76 && isPowerHouse(prospect.college)){
                classModifier = 0.1
            } else if(prospect.overall<76 && !isPowerHouse(prospect.college)){
                classModifier = 0
            } else {
                //player always started
                classModifier = 0.7321
            }
        }
        if(i === 1 && years>1){
            classModifier = 0.83123  
        }
        if(i === 2 && years>2){
            classModifier = 0.9321
        }

        if(prospect.stats.injury<75){
            if(i===0){
                injuryModifier=0.521
            }
            if(i===1){
                injuryModifier=0.53
            }
            if(i===2 && years>3){
                injuryModifier=0
            } else if(i===2){
                injuryModifier=0.600321
            }
            if(i===3){
                injuryModifier=0.6312
            }
        }else if(prospect.stats.injury<85){
            if(i===0){
                injuryModifier=0.184
            }
            if(i===1){
                injuryModifier=0.615
            }
            if(i===2){
                injuryModifier=0.721
            }
            if(i===3){
                injuryModifier=0.778
            }
        }

        stats.push({
                "icon":  classModifier<=0.5 ? <PiChairFill style={{color:'grey'}}/>: injuryModifier<=0.62 ? <FaUserInjured style={{color:'red'}}/>: null,
                "sacks":  classModifier==0 || injuryModifier==0 ? '-' :Math.trunc(LBStats[index].sacks * multiplier * attemptsMultiplier  * classModifier * injuryModifier),
                "tacklesForLoss": classModifier==0 || injuryModifier==0 ? '-' :Math.trunc((LBStats[index].tacklesForLoss + receivingYardMultiplier*multiplier) * classModifier * injuryModifier),
                "tackles": classModifier==0 || injuryModifier==0 ? '-' :Math.trunc((LBStats[index].tackles + receivingTDMultiplier*multiplier) * classModifier * injuryModifier),
                "interceptions": classModifier==0 || injuryModifier==0 ? '-' :Math.trunc((LBStats[index].interceptions + interceptionMultiplier*multiplier) * classModifier * injuryModifier),
            })
        }
        
        ret = 
        <div>
        <tr>
        <th style={thStyle}></th>
        <th style={thStyle}></th>

          <th style={thStyle}>Sacks</th>
          <th style={thStyle}>Tackles</th>
          <th style={thStyle}>Tackles For Loss</th>
          <th style={thStyle}>Interceptions</th>
        </tr>
          {stats.map((stat, index) => (
        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fdfdfd' }}>
         <td style={thStyle}> <img
              style={{width:'25px'}}
              src={`/Images/CollegeLogos/${prospect.college}.svg`}
            /></td>
        <td style={thStyle}>{stat.icon}</td>
            <td style={thStyle}>{stat.sacks}</td>
            <td style={thStyle}>{stat.tackles}</td>
            <td style={thStyle}>{stat.tacklesForLoss}</td>
            <td style={thStyle}>{stat.interceptions}</td>
          </tr>
        ))}
        </div>

    }
    if(prospect.position=="LT" || prospect.position=="LG" || prospect.position=="C" || prospect.position=="RG" || prospect.position=="RT"){
        let index = 0
        if(dev=="Normal"){
            multiplier=multiplier-0.025
        } else if(dev=="Superstar"){
            multiplier=multiplier+0.075
        } else if(dev=="XFactor"){
            multiplier=multiplier+0.15
        }
        multiplier=multiplier+ (ovr/2 - 74/2)/125
        if(isPowerHouse){
            multiplier=multiplier+0.075
        }

        let attemptsMultiplier= (prospect.stats.passBlock - 74) + (prospect.stats.strength - 90) + (prospect.stats.passBlockFinesse - 70) + (prospect.stats.passBlockPower - 70)
        if(attemptsMultiplier>=3){
            attemptsMultiplier=3
        }
        for(let i=0; i<years; i++){
        //figure randomly alter stats based on i and injury
        let classModifier = 1;
        let injuryModifier = 1;
        if(i === 0 && years>1){
            //player has a good chance of not playing freshman season or not appearing in alot of games
            if(prospect.overall<76 && isPowerHouse(prospect.college)){
                classModifier = 0.1
            } else if(prospect.overall<76 && !isPowerHouse(prospect.college)){
                classModifier = 0
            } else {
                //player always started
                classModifier = 0.7321
            }
        }
        if(i === 1 && years>1){
            classModifier = 0.83123  
        }
        if(i === 2 && years>2){
            classModifier = 0.9321
        }

        if(prospect.stats.injury<75){
            if(i===0){
                injuryModifier=0.521
            }
            if(i===1){
                injuryModifier=0.53
            }
            if(i===2 && years>3){
                injuryModifier=0
            } else if(i===2){
                injuryModifier=0.600321
            }
            if(i===3){
                injuryModifier=0.6312
            }
        }else if(prospect.stats.injury<85){
            if(i===0){
                injuryModifier=0.184
            }
            if(i===1){
                injuryModifier=0.615
            }
            if(i===2){
                injuryModifier=0.721
            }
            if(i===3){
                injuryModifier=0.778
            }
        }

        stats.push({
                "icon":  classModifier<=0.5 ? <PiChairFill style={{color:'grey'}}/>: injuryModifier<=0.62 ? <FaUserInjured style={{color:'red'}}/>: null,
                "sacksAllowed":  classModifier==0 || injuryModifier==0 ? '-' :Math.abs(Math.trunc(OLStats[index].sacksAllowed * 1/multiplier  * classModifier * injuryModifier - attemptsMultiplier)),
            })
        }
        
        ret = 
        <div>
        <tr>
        <th style={thStyle}></th>
          <th style={thStyle}></th>
          <th style={thStyle}>Sacks Allowed</th>
        </tr>
          {stats.map((stat, index) => (
        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f2f2f2' : '#fdfdfd' }}>
             <td style={thStyle}> <img
              style={{width:'25px'}}
              src={`/Images/CollegeLogos/${prospect.college}.svg`}
            /></td>
        <td style={thStyle}>{stat.icon}</td>
            <td style={thStyle}>{stat.sacksAllowed}</td>
          </tr>
        ))}
        </div>

    }

    return ret
}

const parseClass = (age, randomRedshirt) =>{
    let c = 1;//prospect.age<=20 && 'So.'}{prospect.age==21 && 'Jr.'}{prospect.age>=22 && 'Sr.'
    if(randomRedshirt%6===0){
        age--
    }
    if(age<=20){
        c = 2
    } else if(age==21){
        c = 3
    } else if(age>=22){
        c = 4
    }
    return c
}

function getSparqRange(rating){
    let ret = "Poor - Marginal"
    if(rating>57){
        ret = "Poor - Marginal"
    }if(rating>66){
        ret = "Marginal - Average"
    }if(rating>73){
        ret = "Average - Good"
    }if(rating>78){
        ret = "Good - Great"
    }if(rating>84){
        ret = "Great - Elite"
    }if(rating>90){
        ret = "Great - Elite"
    }
    return ret
}


export default ScoutingReport;
