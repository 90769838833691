import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoginForm from './SkillSpotter/LoginForm';
import Navbar from './SkillSpotter/Navbar';
import LeagueList from './SkillSpotter/LeagueList';

const SkillSpotter = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [userProfile, setUserProfile] = useState('');

  const handleLogout = () => {
    // Perform any logout logic here
    setAuthenticated(false);
  };

  useEffect(() => {
    // Check if the user is authenticated, then redirect to /leagues
    if (authenticated) {
      window.location.href = '/skillspotter/leagues';
    }
  }, [authenticated]);

  return (
    <div style={{marginTop:'50px'}}>
     

        <div>
          <h1>SkillSpotter</h1>
        <LoginForm setAuthenticated={setAuthenticated} setUserProfile={setUserProfile} />
        </div>

    </div>
  );
};

export default SkillSpotter;
