import React, { useState, useParams } from 'react';
import { useEffect } from 'react';
import Archetype from './Archetype';
import './Row.css'; // Import the CSS file
import { TbStar, TbStarFilled } from "react-icons/tb";
import { RiTShirt2Fill } from "react-icons/ri";
import PercentageBar from './PercentageBar';
import ScoutingReport from './ScoutingReport';
import scouts from './Scouts.json';
import ProgressChart from './Progress';
import { IoIosUnlock } from "react-icons/io";
import { FaGem } from "react-icons/fa";
import { TbBadgesFilled } from "react-icons/tb";
import { HiBadgeCheck } from "react-icons/hi";
import { GiPaperBomb } from "react-icons/gi";

const ProspectTableRow = ({ prospect, index, viewOption, isPopup, uniqueId, setIsStarredOverride, favoritesOnly, progress, setProgressOverride, selectedArchetype}) => {
  const [user, setUser] = useState([]);

  const [scoutProgress, setProgress] = useState(progress);

  
  const [showPopup, setShowPopup] = useState(false);
  const handleClosePopup = () => {
    setShowPopup(false);
  };
  const handleOpenPopup = () => {
    if(!isPopup && scoutProgress>=50){
      setShowPopup(true);
    }
  };

  useEffect(() => {
    const fetchLeagues = async () => {
      let dataUser;
      try {
        const responseUser = await fetch('http://localhost:8000/api/current_user/', {
          credentials: 'include', // Include credentials (cookies) in the request
        });
        dataUser = await responseUser.json();
        setUser(dataUser)
      } catch (error) {
        console.log('could not get curr user')
      }
    };

    fetchLeagues();
  }, []);


  const topSectionStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 8px',
    fontSize: '16px', // Adjust the font size as needed
    width: '800px',
  };

  const bottomSectionStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '6px',
    paddingTop: '10px',
    padding: '5px',
    borderTop: '1px solid #ddd',

  };

  const collegeLogoStyle = {
    width: '75px',
    marginRight: '5px',
    marginLeft: '-5px',
  };

  const collegeLogoThinStyle = {
    width: '45px',
    marginRight: '20px',
    marginLeft: '-8px',
    marginTop:'-5px',
    marginBottom:'-10px',
  };

  const portraitStyle = {
    width: '100px',
    marginRight: '10px',
    marginBottom: '-30px',
    marginTop:'-30px',
    marginLeft: '-20px'
  };
  const portraitThinStyle = {
    width: '50px',
    marginRight: '10px',
    marginBottom: '-30px',
    marginTop:'-25px',
    marginLeft: '-20px',

  };
  const nameStyle = {
    fontWeight: 'bold',
    fontSize: '30px', // Adjust the font size as needed
    marginRight: '8px',
    marginLeft: '8px',
  };
  const nameStyleThin = {
    fontWeight: 'bold',
    fontSize: '20px', // Adjust the font size as needed
    marginRight: '8px',
    marginLeft: '8px',
  };

  const bigBoardStyle = {
    // color: '#007bff',
    fontWeight: 'bold',
    fontSize: '40px', // Adjust the font size as needed
    marginRight: '40px',
    marginLeft: '8px',
    paddingRight: '40px',
    borderRight: '1px solid #ddd'
  };
  const bigBoardThinStyle = {
    // color: '#007bff',
    fontWeight: 'bold',
    fontSize: '20px', // Adjust the font size as needed
    marginRight: '40px',
    marginLeft: '8px',
    paddingRight: '40px',
    borderRight: '1px solid #ddd'
  };


  const bottomColumnStyle = {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '10px',
  };

  
  const progressStyle = {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '40px',
    marginLeft: '-30px',
  };

  const progressStyleThin = {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '50px',
    marginLeft: '-30px',
    marginBottom: '-20px',
    marginTop: '-20px'
  };

  const progressRatingStyle={
    color:'green',
    fontWeight: 'bold',
    fontStyle: 'italic',
    position: 'absolute',
    top: '25px',
    fontSize: '15px',
    width: '37px',
    textAlign: 'center',
  }
  const progressRatingStyleThin={
    color:'green',
    fontWeight: 'bold',
    fontStyle: 'italic',
    position: 'absolute',
    top: '8px',
    fontSize: '8px',
    width: '18px',
    textAlign: 'center',

  }

  const bottomColumnStyleThin = {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '-10px',
    marginBottom: '-10px',
    marginTop:'-10px'
  };

  const bottomHeading ={
    fontSize: '10px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: '5px'
  }
  const bottomHeadingThin ={
    fontSize: '10px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: '5px',
    marginTop: '-5px'
  }
  const smallCollegeLogo = {
    width: '25px',
    marginRight: '5px',
    marginLeft: '5px',
  };

  const bottomCollege = {
    display: 'flex'
  }

  const overlayStyle = {
    position: 'absolute',
    top: '0',
    right: '0',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: '7.5px',
    borderRadius: '4px',
    marginRight: '15px',
    marginTop: '25px',
    width: '55px',
    height: '35px',
  };
  const overlayStyle2 = {
    position: 'absolute',
    top: '0',
    right: '85px',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: '7.5px',
    borderRadius: '4px',
    marginRight: '15px',
    marginTop: '25px',
    width: '45px',
    height: '35px',
  };

  const overlayStyleThin = {
    position: 'absolute',
    top: '0',
    right: '0',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: '7.5px',
    borderRadius: '4px',
    marginRight: '15px',
    marginTop: '5px',
    width: '45px',
    height: '25px',
  };


  const overlayStyle2Thin = {
    position: 'absolute',
    top: '0',
    right: '85px',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: '7.5px',
    borderRadius: '4px',
    marginRight: '0px',
    marginTop: '5px',
    width: '45px',
    height: '25px',
  };

  const overlayStyleProgress = {
    position: 'absolute',
    top: '0',
    right: '150px',
    padding: '7.5px',
    borderRadius: '4px',
    marginRight: '15px',
    marginTop: '25px',
    width: '45px',
    height: '35px',
  };

  const starred = {

    fontSize: '25px',
    borderRadius: '4px',
    marginTop: '-25px',
    marginRight: '5px',
    marginLeft: '-25px',
    width: '25px',
    height: '25px',
    zIndex: 1, // Set the desired z-index value

  };

  const hovered = {
    ...starred, // Include the existing styles
    color: '#FFD900', // Change this to your desired hover color
  };

  const starredFilled = {
    ...starred,
    color: '#FFD900'
  };

  const starredThin = {

    fontSize: '25px',
    borderRadius: '4px',
    marginTop: '0px',
    marginRight: '25px',
    marginLeft: '-40px',
    width: '25px',
    height: '25px',
    zIndex: 1, // Set the desired z-index value

  };

  const hoveredThin = {
    ...starredThin, // Include the existing styles
    color: '#FFD900', // Change this to your desired hover color
  };

  const starredThinFilled = {
    ...starredThin,
    color: '#FFD900'
  };


  const bigBoardHeading ={
    fontSize: '10px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginLeft: '10px'
  }

  
  const popupContainerStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000,
  };

  const popupStyle = {
    // Your popup styles
    padding: '20px',
    background: '#fff',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
    height: '80vh',
    overflowY:'scroll',
    width: '80vw'
  };

  const backdropStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)', // Adjust the opacity as needed
    backdropFilter: 'blur(5px)', // Adjust the blur intensity as needed
    zIndex: 999,
    display: showPopup ? 'block' : 'none',
  };

  const closeButton = {
    position: 'absolute',
    top: '5px', /* Adjust the top value based on your preference */
    right: '25px', /* Adjust the right value based on your preference */
    padding: '10px',
    backgroundColor: 'red',
    color:'white',
    border: 'none',
    cursor: 'pointer',
    borderRadius: '5px'
  }

  const handleImageError = (event) => {
    event.target.src = 'https://madden-assets-cdn.pulse.ea.com/madden24/portraits/256/0.png'; // Replace with your default image URL
  };
  const handleCollegeImageError = (event) => {
    event.target.src = '/Images/CollegeLogos/NCAA.png'; // Replace with your default image URL
  };
  
  const toggleStar = (event) => {
    event.stopPropagation();
  };
  
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const buttonStyle = {
    border: 'none', 
    padding: '5px', 
    borderRadius:'7px',
    cursor: 'pointer',
    marginTop: '0px',
    marginLeft: '-2px',
    zIndex: 1, // Set the desired z-index value
  }
  const buttonStyleThin = {
    border: 'none', 
    padding: '5px', 
    borderRadius:'7px',
    cursor: 'pointer',
    marginTop: '0px',
    marginLeft: '-8px',
    fontSize: '10px',
    zIndex: 1, // Set the desired z-index value
  }

  const gemStyle = {
    position: 'absolute',
    left: '5px',
    fontSize: '15px',
    top:'20px'
  }
  const gemStyleThin = {
    position: 'absolute',
    left: '5px',
    fontSize: '15px',
    top:'10px'
  }
 


  //TODO: fix this

  if(selectedArchetype!='ALL' && scoutProgress<10){
    return
  }
  return (
    <>{(!favoritesOnly || favoritesOnly)  && <>
           {showPopup && (
        <>
          <div style={backdropStyle}></div>
          <div style={popupContainerStyle}>
            <div style={popupStyle}>
              {/* Content for your popup */}
              <ScoutingReport prospect={prospect} index={index} />
             
              <button style={closeButton} onClick={handleClosePopup}>Close Popup</button>
            </div>
          </div>
        </>
      )}
    <div key={index} className={"rowComponent"} onClick={handleOpenPopup}>
      <td colSpan="6">
        {viewOption === 'large' || viewOption === 'medium' ?(<div style={overlayStyle}>
        <div style={bottomColumnStyle}>
          <span style={bottomHeading}>Position</span>
          <div>{prospect.position}</div>
        </div>
        </div>):null}
        {viewOption === 'thin' ?(<div style={overlayStyleThin}>
        <div style={bottomColumnStyle}>
          <span style={bottomHeadingThin}>Position</span>
          <div>{prospect.position}</div>
        </div>
        </div>):null}
        {viewOption === 'large' || viewOption === 'medium' ?(<div style={overlayStyle2}>
         <div style={bottomColumnStyle}>
          <span style={bottomHeading}>Class</span>
          <div>{parseClass(prospect.age, prospect.portrait)}</div>
        </div>
        </div>):null}
        {viewOption === 'thin' ?(<div style={overlayStyle2Thin}>
         <div style={bottomColumnStyle}>
          <span style={bottomHeadingThin}>Class</span>
          <div>{parseClass(prospect.age, prospect.portrait)}</div>
        </div>
        </div>):null}
        {viewOption === 'large' || viewOption === 'medium' ?(<div style={overlayStyleProgress}>
         {/* <PercentageBar percentage={Math.floor(Math.random() * 100) + 1}/> */}
        </div>):null}
        <div style={topSectionStyle}>
        
        {/* {(viewOption === 'large' || viewOption === 'medium') && scoutProgress>=100 && parseGem(prospect, gemStyle)}
        {(viewOption === 'thin') && scoutProgress>=100 && parseGem(prospect, gemStyleThin)} */}
       
        {viewOption === 'large' || viewOption === 'medium' ? (<div style={bottomColumnStyle}>
          <span style={bigBoardHeading}>Rank</span>
          <div style={bigBoardStyle}>{prospect.bigBoard<=200 && prospect.bigBoard}{prospect.bigBoard>200 && "N/A"}</div>
        </div>):null}
        {viewOption === 'thin' ? (<div style={bottomColumnStyle}>
          <span style={bigBoardHeading}>Rank</span>
          <div style={bigBoardThinStyle}>{prospect.bigBoard<=200 && prospect.bigBoard}{prospect.bigBoard>200 && "N/A"}</div>
        </div>):null}
       
        {viewOption === 'large' || viewOption === 'medium' ? ( <div>
            <img
              style={portraitStyle}
              src={`https://madden-assets-cdn.pulse.ea.com/madden24/portraits/256/${prospect.portrait}.png`}
              onError={handleImageError}
            />
          </div>): null}
          {viewOption === 'thin' ? (<div style={bottomColumnStyle}>
            <img
              style={portraitThinStyle}
              src={`https://madden-assets-cdn.pulse.ea.com/madden24/portraits/256/${prospect.portrait}.png`}
              onError={handleImageError}
              />
        </div>):null}
          {viewOption=='large' || viewOption=='medium' ? ( <div>
            <img
              style={collegeLogoStyle}
              src={`/Images/CollegeLogos/${prospect.college}.svg`}
              onError={handleCollegeImageError}
            />
          </div>):null}
          {viewOption=='thin' ? ( <div>
            <img
              style={collegeLogoThinStyle}
              src={`/Images/CollegeLogos/${prospect.college}.svg`}
              onError={handleCollegeImageError}
            />
          </div>):null}
          {viewOption === 'large' || viewOption === 'medium' ?(<div style={bottomColumnStyle}>
          <div style={nameStyle}>{prospect.name}</div>
          {scoutProgress>=10 && <Archetype prospect={prospect}/>}
          {scoutProgress<10 && <div style={{marginLeft:'10px'}}><IoIosUnlock /> 10%</div>}
          </div>):null}
          {viewOption === 'thin' ?(<div style={bottomColumnStyleThin}>
          <div style={nameStyleThin}>{prospect.name}</div>
          {scoutProgress>=10 && <Archetype prospect={prospect}/>}
          {scoutProgress<10 && <div style={{marginLeft:'10px'}}><IoIosUnlock /> 10%</div>}
          </div>):null}

         
        </div>
        {viewOption=='large' && <div style={bottomSectionStyle}>
        <div style={bottomColumnStyle}>
          <span style={bottomHeading}>College</span>
          <div style={bottomCollege}>
            <img
              style={smallCollegeLogo}
              src={`/Images/CollegeLogos/${prospect.college}.svg`}
              onError={handleCollegeImageError}
            />
          <div style={{marginTop: '5px'}}>{prospect.college}</div>
        </div>
        </div>
        <div style={bottomColumnStyle}>
          <span style={bottomHeading}>Age</span>
          <div>{prospect.age}</div>
        </div>
        <div style={bottomColumnStyle}>
          <span style={bottomHeading}>Height</span>
          <div>{Math.floor(prospect.height / 12)}'{prospect.height%12}"</div>
        </div>
        <div style={bottomColumnStyle}>
          <span style={bottomHeading}>Weight</span>
          <div>{prospect.weight + 160}lbs</div>
        </div>
        <div style={bottomColumnStyle}>
          <span style={bottomHeading}>SPARQ</span>
          {scoutProgress>=80 && <div>{(prospect.relativeAthleticScore*10).toFixed(2)}</div>}
          {scoutProgress<80 && <div style={{marginLeft:'-5px'}}><IoIosUnlock /> 80%</div>}
        </div>
        <div style={bottomColumnStyle}>
          <span style={bottomHeading}>Motivation</span>
          {scoutProgress>=30 && <div>{parseMotivation(prospect.motivation1)}</div>}
          {scoutProgress<30 && <div style={{marginLeft:'2.5px'}}><IoIosUnlock /> 30%</div>}
        </div>
        {/* <div style={bottomColumnStyle}>
          <span style={bottomHeading}>Tag</span>
          <div>{parseTag(prospect.tag1, prospect.portrait)}</div>
        </div> */}
      </div>}
      </td>
    </div>
    </>}</>
  );
};

const parseClass = (age, randomRedshirt) =>{
    let c = '';//prospect.age<=20 && 'So.'}{prospect.age==21 && 'Jr.'}{prospect.age>=22 && 'Sr.'
    if(randomRedshirt%6===0){
        age--
    }
    if(age<=20){
        c = 'So.'
    } else if(age==21){
        c = 'Jr.'
    } else if(age>=22){
        c = 'Sr.'
    }
    return   <div style={{ display: 'inline',}}>{c}</div>
//     (
// {/* <div style={{width: '50px', }}>
//   <div  style={{  width: '20px', height: '20px',  display: 'inline-block' }}>{randomRedshirt % 6 === 0 && (
//     <RiTShirt2Fill style={{  color: 'red'}} />
//   )}</div> */}
// // </div>

//       );
}

const parseMotivation = (motivation) => {
    let parsed = ''
    switch(motivation){
        case 'HighestOffer':
            parsed = 'Financials'
            break
        case 'TeamHasFranchiseQB':
            parsed = 'Team Has Franchise QB'
            break
        case 'MentoratPosition':
            parsed = 'Mentor at Position'
            break
        default:
            parsed = motivation.replace(/([A-Z])/g, ' $1').trim()
            break
    }
    return parsed
}

const parseTag = (tag, randomoffset) => {
    let parsed = ''
    switch(tag){
        case 'Day1Starter':
            parsed = 'Day 1 Starter'
            break
        case 'FranchiseQB':
            parsed = 'Franchise QB'
            break
        case 'QBofTheFuture':
            parsed = 'QB of The Future'
            break
        case 'NoRole':
            const uncertaintyOptions = [
                'Unknown', 
                'Backup', 
                'Boom or Bust', 
                'Uncertain', 
                'Wildcard', 
                'Variable', 
                'High Ceiling', 
                'Low Floor', 
                'Developmental', 
                'Project Player',
                'Sleeper', 
                'Underrated', 
                'Raw Talent', 
                'Risky Bet', 
                'Promising', 
                'Intriguing Prospect', 
                'Wildcard', 
                'Under-the-Radar', 
                'Mystery Box'
              ];
            const randomIndex = randomoffset%uncertaintyOptions.length;
            parsed = uncertaintyOptions[randomIndex];
            break
        default:
            parsed = tag.replace(/([A-Z])/g, ' $1').trim()
            break
    }
    return parsed
}

const parseGem = (prospect, gemStyle) => {
  let ovr = prospect.overall
  let round = prospect.roundProjection
  let gem = <HiBadgeCheck  style={{color:'#89cff0'}}/>
  if(round==1){
    if(ovr>78)
      gem = <FaGem style={{color:'#00CC00'}}/>//GiPaperBomb 
    if(ovr<72)
      gem = <GiPaperBomb style={{color:'#CC0000'}}/>//GiPaperBomb 
  } else if(round==2){
    if(ovr>75)
      gem = <FaGem style={{color:'#00CC00'}}/>//GiPaperBomb 
    if(ovr<69)
      gem = <GiPaperBomb style={{color:'#CC0000'}}/>//GiPaperBomb 
  } else if(round==3){
    if(ovr>73)
      gem = <FaGem style={{color:'#00CC00'}}/>//GiPaperBomb 
    if(ovr<68)
      gem = <GiPaperBomb style={{color:'#CC0000'}}/>//GiPaperBomb 
  } else if(round==4){
    if(ovr>72)
      gem = <FaGem style={{color:'#00CC00'}}/>//GiPaperBomb 
    if(ovr<67)
      gem = <GiPaperBomb style={{color:'#CC0000'}}/>//GiPaperBomb 
  } else if(round==5){
    if(ovr>71)
      gem = <FaGem style={{color:'#00CC00'}}/>//GiPaperBomb 
    if(ovr<65)
      gem = <GiPaperBomb style={{color:'#CC0000'}}/>//GiPaperBomb 
  } else if(round==6){
    if(ovr>71)
      gem = <FaGem style={{color:'#00CC00'}}/>//GiPaperBomb 
    if(ovr<65)
      gem = <GiPaperBomb style={{color:'#CC0000'}}/>//GiPaperBomb 
  } else if(round==7){
    if(ovr>71)
      gem = <FaGem style={{color:'#00CC00'}}/>//GiPaperBomb 
  } else if(round==63){ //UDFAS
    if(ovr>69)
      gem = <FaGem style={{color:'#00CC00'}}/>//GiPaperBomb 
  }


  return <div style={gemStyle}>{gem}</div>
}
export default ProspectTableRow;
