import React, { useState, useEffect, useMemo, useRef,useCallback } from 'react';
import * as THREE from 'three';
import { Canvas, useLoader } from 'react-three-fiber';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import AssetOptions from './AssetOptions.jsx';
import BrandOptions from './BrandOptions.jsx';
import NumberOptions from './NumberOptions.jsx';
import './KitCreator.css';
import Color from './Color.js'
import Solver from './Solver.js'
import { OrbitControls } from '@react-three/drei'
import { FaTshirt } from 'react-icons/fa';
import {GiUnderwearShorts} from 'react-icons/gi'
import JSZip from 'jszip';
import {BiReset} from 'react-icons/bi';
import {BsFillFileEarmarkZipFill, BsFillShieldFill} from 'react-icons/bs';
import {GiAmericanFootballHelmet} from 'react-icons/gi';
import {MdNumbers} from 'react-icons/md'
import {FaSocks} from 'react-icons/fa';
import { gsap } from 'gsap';
import { allLogosData, allJerseyAssetsData, allPantAssetsData,allJerseyNormsData,allPantsNormsData, allHelmetAssetsData, allSockAssetsData } from './Assets.js';
import { hexToRgb } from './HexToRGB.js';
import { Helmet } from 'react-helmet';

import Delerium from './Fonts/Delerium.ttf';
import DeleriumNeon from './Fonts/Delirium_Neon.ttf';
import Dragz from './Fonts/Dragz.otf';
import Jurasick from './Fonts/Jurasick.ttf';
import Patched from './Fonts/Patched.otf';
import SablonUp from './Fonts/Sablon_Up.otf';
import SportsNight from './Fonts/Sports_Night.ttf';
import CollegeAlt from './Fonts/College_Alt.ttf';
import CollegeBold from './Fonts/College_Bold.ttf';
import CollegeCondensed from './Fonts/College_Condensed.ttf';
import College from './Fonts/College.ttf';
import Glitch from './Fonts/Glitch.ttf';
import Haettenschweiler from './Fonts/Haettenschweiler.ttf';
import NikeItalic from './Fonts/Nike_Italic.otf';
import Nike from './Fonts/Nike.otf';
import Philly from './Fonts/Philly.otf';
import StillTime from './Fonts/Still_Time.otf';
import Adidas from './Fonts/Adidas.otf';
import AdidasItalic from './Fonts/Adidas_Italic.otf';
import Cowboy from './Fonts/Cowboy.ttf';
import Dragna from './Fonts/Dragna.ttf';
import Pirate from './Fonts/Pirate.ttf';
import PirateItalic from './Fonts/Pirate_Italic.ttf';
import Renegade from './Fonts/Renegade.ttf';
import Anton from './Fonts/Anton.ttf';
import Jockey from './Fonts/Jockey.ttf';
import LexendPeta from './Fonts/Lexend_Peta.ttf';
import Racing from './Fonts/Racing.ttf';
import Russo from './Fonts/Russo.ttf';
import Academic from './Fonts/Academic.ttf';
import Athletic from './Fonts/Athletic.ttf';
import Freshman from './Fonts/Freshman.ttf';
import Jersey from './Fonts/Jersey.ttf';
import Sportzan from './Fonts/Sportzan.ttf';
import Superstar from './Fonts/Superstar.ttf';
import Beantown from './Fonts/Beantown.ttf';
import RunningStart from './Fonts/Running_Start.ttf';
import UK from './Fonts/UK.otf';
import Microsport from './Fonts/Microsport.ttf';
import Redzone from './Fonts/Redzone.ttf';

function KitCreator() {

  //localStorage.clear();
  const[saving,setSaving] = useState(false);

  const [brandTabOpen, setBrandTabOpen] = useState(() => {
    const storedAssets = localStorage.getItem('brandTabOpen');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return true;
    }
  });
  const [jerseyTabOpen, setJerseyTabOpen] = useState(() => {
    const storedAssets = localStorage.getItem('jerseyTabOpen');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return false;
    }
  });
  const [pantsTabOpen, setPantsTabOpen] = useState(() => {
    const storedAssets = localStorage.getItem('pantsTabOpen');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return false;
    }
  });
  const [helmetTabOpen, setHelmetTabOpen] = useState(() => {
    const storedAssets = localStorage.getItem('helmetTabOpen');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return false;
    }
  });
  const [socksTabOpen, setSocksTabOpen] = useState(() => {
    const storedAssets = localStorage.getItem('socksTabOpen');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return false;
    }
  });

  const [numbersTabOpen, setNumbersTabOpen] = useState(() => {
    const storedAssets = localStorage.getItem('numbersTabOpen');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return false;
    }
  });
  
  const [showInstructions, setShowInstructions] = useState(false);
  useEffect(() => {
    //setShowInstructions(true)
  }, []);

  const [showChestLogo, setShowChestLogo]  = useState(() => {
    const storedAssets = localStorage.getItem('showChestLogo');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return false;
    }
  });

  const [showHelmetLogo, setShowHelmetLogo]  = useState(() => {
    const storedAssets = localStorage.getItem('showHelmetLogo');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return false;
    }
  });
  
  const [flipHelmetLogo, setFlipHelmetLogo]  = useState(() => {
    const storedAssets = localStorage.getItem('flipHelmetLogo');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return false;
    }
  });

  const [showBackLogo, setShowBackLogo]  = useState(() => {
    const storedAssets = localStorage.getItem('showBackLogo');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return false;
    }
  });

  const [showSleeveLogo, setShowSleeveLogo]  = useState(() => {
    const storedAssets = localStorage.getItem('showSleeveLogo');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return false;
    }
  });

  const [jerseyFont, setJerseyFont] = useState(() => {
    const storedAssets = localStorage.getItem('jerseyFont');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return SablonUp;
    }
  });

  const [numberFont, setNumberFont] = useState(() => {
    const storedAssets = localStorage.getItem('numberFont');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return SablonUp;
    }
  });


  const [logoUrl, setLogoUrl] = useState(() => {
    const storedAssets = localStorage.getItem('logoUrl');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return '/KitCreator/jerseyForgeLogo.png';
    }
  });

  const [teamName, setTeamName] = useState(() => {
    const storedAssets = localStorage.getItem('teamName');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return 'Team Name';
    }
  });

  const [cityName, setCityName] = useState(() => {
    const storedAssets = localStorage.getItem('cityName');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return 'City Name';
    }
  });

  const [primaryColor, setPrimaryColor] = useState(() => {
    const storedAssets = localStorage.getItem('primaryColor');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return '#800080';
    }
  });

  const [secondaryColor, setSecondaryColor] = useState(() => {
    const storedAssets = localStorage.getItem('secondaryColor');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return '#2F4F4F';
    }
  });

  const [numberColor, setNumberColor] = useState(() => {
    const storedAssets = localStorage.getItem('numberColor');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return '#F3F3F3';
    }
  });

  const [showNumber, setShowNumber] = useState(() => {
    const storedAssets = localStorage.getItem('showNumber');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return '88';
    }
  });

  const allFonts=[Academic, Adidas, AdidasItalic,  Anton, Athletic, College, CollegeBold, CollegeCondensed, CollegeAlt, Cowboy, Delerium, DeleriumNeon, Dragna, Dragz, Freshman, Glitch, Haettenschweiler, Jersey, Jockey, Jurasick, LexendPeta,Microsport,  Nike, NikeItalic, Patched, Philly, Pirate, PirateItalic, Racing, Redzone, Renegade, Russo, RunningStart, SablonUp, SportsNight, Sportzan, StillTime, Superstar, UK];
  const allNumberFonts=[ Academic, Athletic, Beantown,  College, CollegeBold, Dragna, Freshman, Glitch, Haettenschweiler, Jersey, Jockey, Microsport, Nike, NikeItalic,  Racing, Redzone, Renegade, Russo, SablonUp, SportsNight, Sportzan, Superstar, UK];

  const [fontSize, setFontSize] = useState(() => {
    const storedAssets = localStorage.getItem('fontSize');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return 80;
    }
  });
  const [yOffset, setYOffset] = useState(() => {
    const storedAssets = localStorage.getItem('yOffset');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return 0;
    }
  });

  
  const [logoHelmetSize, setLogoHelmetSize] = useState(() => {
    const storedAssets = localStorage.getItem('logoHelmetSize');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return 100;
    }
  });
  const [logoHelmetX, setLogoHelmetX] = useState(() => {
    const storedAssets = localStorage.getItem('logoHelmetX');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return 0;
    }
  });
  const [logoHelmetY, setLogoHelmetY] = useState(() => {
    const storedAssets = localStorage.getItem('logoHelmetY');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return 0;
    }
  });
  const [logoHelmetRotate, setLogoHelmetRotate] = useState(() => {
    const storedAssets = localStorage.getItem('logoHelmetY');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return 0;
    }
  });

  const [jerseyNormUrl, setJerseyNormUrl] = useState(() => {
    const storedAssets = localStorage.getItem('jerseyNormUrl');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return '/KitCreator/jersey/norm/norm.png'
    }
  });
  
  const [pantsNormUrl, setPantsNormUrl] = useState(() => {
    const storedAssets = localStorage.getItem('pantsNormUrl');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return '/KitCreator/pants/norm/norm.png'
    }
  });
  
  const kitPreviewRef = useRef(null); // Reference to the kit preview container
  const kitPreviewPantsRef = useRef(null); // Reference to the kit preview container
  const kitPreviewBrandingRef = useRef(null); // Reference to the kit preview container
  const kitPreviewHelmetRef = useRef(null); // Reference to the kit preview container
  const kitPreviewSockRef = useRef(null); // Reference to the kit preview container
  const orbitControlsRef = useRef();

  const [currentAsset, setCurrentAsset] = useState(null); 
 // const [uploadedImage, setUploadedImage] = useState(null);

 const [selectedAssets, setSelectedAssets] = useState(() => {
  const storedSelectedAssets = localStorage.getItem('selectedAssets');
  if (storedSelectedAssets) {
    return JSON.parse(storedSelectedAssets);
  } else {
    return [
      1
    ];
  }
});
  
  const [selectedPantAssets, setSelectedPantAssets] = useState(() => {
    const storedSelectedAssets = localStorage.getItem('selectedPantAssets');
    if (storedSelectedAssets) {
      return JSON.parse(storedSelectedAssets);
    } else {
      return [
        500
      ];
    }  }); 

    const [selectedHelmetAssets, setSelectedHelmetAssets] = useState(() => {
      const storedSelectedAssets = localStorage.getItem('selectedHelmetAssets');
      if (storedSelectedAssets) {
        return JSON.parse(storedSelectedAssets);
      } else {
        return [
          1500, 1501, 1503
        ];
      }  }); 

      const [selectedSockAssets, setSelectedSockAssets] = useState(() => {
        const storedSelectedAssets = localStorage.getItem('selectedSockAssets');
        if (storedSelectedAssets) {
          return JSON.parse(storedSelectedAssets);
        } else {
          return [
            2000
          ];
        }  }); 
  

  const [selectedColor, setSelectedColor] = useState(() => {
    const storedColor = localStorage.getItem('selectedColor');
    return storedColor ? storedColor : '#000000'; 
  });

  const [assets, setAssets] = useState(() => {
    const storedAssets = localStorage.getItem('assets');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return [
        { id: 1, name: 'Base', subType: 'Jersey', type: 'Jersey', imageUrl: '/KitCreator/jersey/base/base.png', color: '#800080', visible: true, noDelete: true}
      ];
    }
  });

  const [pantAssets, setPantAssets] = useState(() => {
    const storedAssets = localStorage.getItem('pantAssets');
    if(storedAssets){
      return JSON.parse(storedAssets);
    } else{
      return [
        { id: 500, name: 'Base', subType:'Pants', type: 'Pants', imageUrl: '/KitCreator/pants/base/base.png', color: '#800080', visible: true, noDelete: true},
      ];
    }  });

    const [helmetAssets, setHelmetAssets] = useState(() => {
      const storedAssets = localStorage.getItem('helmetAssets');
      if(storedAssets){
        return JSON.parse(storedAssets);
      } else{
        return [
          { id: 1503, name: 'Chinstrap', subType:'Base', type: 'Helmet', imageUrl: '/KitCreator/helmet/base/chinstrap.png', color: '#F3F3F3',  visible: true,noDelete: true},
          { id: 1502, name: 'Facemask', subType:'Base', type: 'Helmet', imageUrl: '', color: '#F3F3F3', visible: true, noDelete: true},
          { id: 1501, name: 'Accessories', subType:'Base', type: 'Helmet', imageUrl: '/KitCreator/helmet/base/acc.png', color: '#F3F3F3', visible: true,noDelete: true},
          { id: 1500, name: 'Shell', subType:'Base', type: 'Helmet', imageUrl: '/KitCreator/helmet/base/base.png', color: '#800080', visible: true, noDelete: true},
        ];
      }  });
  

      const [sockAssets, setSockAssets] = useState(() => {
        const storedAssets = localStorage.getItem('sockAssets');
        if(storedAssets){
          return JSON.parse(storedAssets);
        } else{
          return [
            { id: 2000, name: 'Base', subType:'Socks', type: 'Socks', imageUrl: '/KitCreator/socks/base/base.png', color: '#800080', visible: true, noDelete: true},
          ];
        }  });

  const [assetVisibility, setAssetVisibility] = useState(() => {
    const storedAssetVisibility = localStorage.getItem('assetVisibility');
    if(storedAssetVisibility){
      return JSON.parse(storedAssetVisibility);
    } else{
      return [
        {1: true}
      ];
    }
  });

  const [assetPantVisibility, setAssetPantVisibility] = useState(() => {
    const storedAssetVisibility = localStorage.getItem('assetPantVisibility');
    if(storedAssetVisibility){
      return JSON.parse(storedAssetVisibility);
    } else{
      return [
        {500: true}
      ];
    }  });

    const [assetHelmetVisibility, setAssetHelmetVisibility] = useState(() => {
      const storedAssetVisibility = localStorage.getItem('assetHelmetVisibility');
      if(storedAssetVisibility){
        return JSON.parse(storedAssetVisibility);
      } else{
        return [
          {1500: true, 1501: true, 1503: true}
        ];
      }  });

      const [assetSockVisibility, setAssetSockVisibility] = useState(() => {
        const storedAssetVisibility = localStorage.getItem('assetSockVisibility');
        if(storedAssetVisibility){
          return JSON.parse(storedAssetVisibility);
        } else{
          return [
            {1500: true, 1501: true, 1503: true}
          ];
        }  });

   
    useEffect(() => {
      if (helmetTabOpen && orbitControlsRef.current) {
        const targetPosition = new THREE.Vector3(0, 2, 0);
        const cameraPosition = new THREE.Vector3(1.5, 2, 1);
        const cameraUp = new THREE.Vector3(0, 1, 0);
        setTimeout(() => {

        gsap.to(orbitControlsRef.current.target, { duration: 2, delay: 0.5, x: targetPosition.x, y: targetPosition.y, z: targetPosition.z });
        gsap.to(orbitControlsRef.current.object.position, { duration: 2, delay: 0.5, x: cameraPosition.x, y: cameraPosition.y, z: cameraPosition.z });
        gsap.to(orbitControlsRef.current.object.up, { duration: 2,delay: 0.5,  x: cameraUp.x, y: cameraUp.y, z: cameraUp.z });
      }, 200);

        orbitControlsRef.current.object.lookAt(orbitControlsRef.current.target);
      }
    }, [helmetTabOpen]);

    useEffect(() => {
      if ((jerseyTabOpen || numbersTabOpen) && orbitControlsRef.current) {
        const targetPosition = new THREE.Vector3(0, 0.75, 0);
        const cameraPosition = new THREE.Vector3(0, 0.75, 2.5);
        const cameraUp = new THREE.Vector3(0, 0.75, 0);
        setTimeout(() => {

        gsap.to(orbitControlsRef.current.target, { duration: 2,delay: 0.5,  x: targetPosition.x, y: targetPosition.y, z: targetPosition.z });
        gsap.to(orbitControlsRef.current.object.position, { duration: 2,delay: 0.5,  x: cameraPosition.x, y: cameraPosition.y, z: cameraPosition.z });
        gsap.to(orbitControlsRef.current.object.up, { duration: 2,delay: 0.5,  x: cameraUp.x, y: cameraUp.y, z: cameraUp.z });
      }, 200);

        orbitControlsRef.current.object.lookAt(orbitControlsRef.current.target);
      }
    }, [jerseyTabOpen, numbersTabOpen]);

    useEffect(() => {
      if (pantsTabOpen && orbitControlsRef.current) {
        const targetPosition = new THREE.Vector3(0, -1.25, 0);
        const cameraPosition = new THREE.Vector3(0, -1.25, 2);
        const cameraUp = new THREE.Vector3(0, 1.25, 0);
        setTimeout(() => {

        gsap.to(orbitControlsRef.current.target, { duration: 2,delay: 0.5,  x: targetPosition.x, y: targetPosition.y, z: targetPosition.z });
        gsap.to(orbitControlsRef.current.object.position, { duration: 2,delay: 0.5,  x: cameraPosition.x, y: cameraPosition.y, z: cameraPosition.z });
        gsap.to(orbitControlsRef.current.object.up, { duration: 2, delay: 0.5, x: cameraUp.x, y: cameraUp.y, z: cameraUp.z });
      }, 200);

        orbitControlsRef.current.object.lookAt(orbitControlsRef.current.target);
      }
    }, [pantsTabOpen]);

    useEffect(() => {
      if (socksTabOpen && orbitControlsRef.current) {
        const targetPosition = new THREE.Vector3(0, -2.75, 0);
        const cameraPosition = new THREE.Vector3(0, -2.75, 2);
        const cameraUp = new THREE.Vector3(0, 2.75, 0);
        setTimeout(() => {
        gsap.to(orbitControlsRef.current.target, { duration: 2, delay: 0.5, x: targetPosition.x, y: targetPosition.y, z: targetPosition.z });
        gsap.to(orbitControlsRef.current.object.position, { duration: 2,delay: 0.5,  x: cameraPosition.x, y: cameraPosition.y, z: cameraPosition.z });
        gsap.to(orbitControlsRef.current.object.up, { duration: 2, delay: 0.5, x: cameraUp.x, y: cameraUp.y, z: cameraUp.z });
      }, 200);

        orbitControlsRef.current.object.lookAt(orbitControlsRef.current.target);
      
      }
    }, [socksTabOpen]);

    useEffect(() => {
      if (brandTabOpen && orbitControlsRef.current) {
        const targetPosition = new THREE.Vector3(0, 0, 0);
        const cameraPosition = new THREE.Vector3(0, 0, 5);
        const cameraUp = new THREE.Vector3(0, 1, 0); // Adjust the camera's up vector as needed
      
        setTimeout(() => {
          gsap.to(orbitControlsRef.current.target, { duration: 2, delay: 0.5, x: targetPosition.x, y: targetPosition.y, z: targetPosition.z });
          gsap.to(orbitControlsRef.current.object.position, { duration: 2,delay: 0.5,  x: cameraPosition.x, y: cameraPosition.y, z: cameraPosition.z });
          gsap.to(orbitControlsRef.current.object.up, { duration: 2, delay: 0.5, x: cameraUp.x, y: cameraUp.y, z: cameraUp.z });
      
          // Update orbit controls after changing positions
          orbitControlsRef.current.update();
      
          gsap.to(orbitControlsRef.current.object.rotation, { duration: 2, delay: 0.5, x: 0, y: 0, z: 0 });
        }, 200);
      }
    }, [brandTabOpen]);

    useEffect(()=>{
      localStorage.setItem('jerseyFont', JSON.stringify(jerseyFont));
    },[jerseyFont]);

    useEffect(()=>{
      localStorage.setItem('numberFont', JSON.stringify(numberFont));
    },[numberFont]);

    useEffect(()=>{
      localStorage.setItem('logoUrl', JSON.stringify(logoUrl));
    },[logoUrl]);

    useEffect(()=>{
      localStorage.setItem('showChestLogo', JSON.stringify(showChestLogo));
    },[showChestLogo]);

    useEffect(()=>{
      localStorage.setItem('showHelmetLogo', JSON.stringify(showHelmetLogo));
    },[showHelmetLogo]);

    useEffect(()=>{
      localStorage.setItem('flipHelmetLogo', JSON.stringify(flipHelmetLogo));
    },[flipHelmetLogo]);
    
    useEffect(()=>{
      localStorage.setItem('showBackLogo', JSON.stringify(showBackLogo));
    },[showBackLogo]);

    useEffect(()=>{
      localStorage.setItem('showSleeveLogo', JSON.stringify(showSleeveLogo));
    },[showSleeveLogo]);

    useEffect(()=>{
      localStorage.setItem('teamName', JSON.stringify(teamName));
    },[teamName]);

    useEffect(()=>{
      localStorage.setItem('cityName', JSON.stringify(cityName));
    },[cityName]);

    useEffect(()=>{
      localStorage.setItem('primaryColor', JSON.stringify(primaryColor));
    },[primaryColor]);

    useEffect(()=>{
      localStorage.setItem('numberColor', JSON.stringify(numberColor));
    },[numberColor]);
    useEffect(()=>{
      localStorage.setItem('showNumber', JSON.stringify(showNumber));
    },[showNumber]);

    useEffect(()=>{
      localStorage.setItem('secondaryColor', JSON.stringify(secondaryColor));
    },[secondaryColor]);

  useEffect(() => {
    localStorage.setItem('jerseyNormUrl', JSON.stringify(jerseyNormUrl));
  }, [jerseyNormUrl]);

  useEffect(() => {
    localStorage.setItem('pantsNormUrl', JSON.stringify(pantsNormUrl));
  }, [pantsNormUrl]);

  useEffect(() => {
    localStorage.setItem('assets', JSON.stringify(assets));
  }, [assets]);

  useEffect(() => {
    localStorage.setItem('selectedAssets', JSON.stringify(selectedAssets));
  }, [selectedAssets]);

  useEffect(() => {
    localStorage.setItem('assetVisibility', JSON.stringify(assetVisibility));
  }, [assetVisibility]);

  useEffect(() => {
    localStorage.setItem('fontSize', JSON.stringify(fontSize));
  }, [fontSize]);
  useEffect(() => {
    localStorage.setItem('yOffset', JSON.stringify(yOffset));
  }, [yOffset]);
  useEffect(() => {
    localStorage.setItem('logoHelmetSize', JSON.stringify(logoHelmetSize));
  }, [logoHelmetSize]);
  useEffect(() => {
    localStorage.setItem('logoHelmetX', JSON.stringify(logoHelmetX));
  }, [logoHelmetX]);
  useEffect(() => {
    localStorage.setItem('logoHelmetY', JSON.stringify(logoHelmetY));
  }, [logoHelmetY]);
  useEffect(() => {
    localStorage.setItem('logoHelmetRotate', JSON.stringify(logoHelmetRotate));
  }, [logoHelmetRotate]);

  useEffect(() => {
    localStorage.setItem('selectedColor', selectedColor);
    localStorage.setItem('assets', JSON.stringify(assets));
  }, [selectedColor, assets]);


  useEffect(() => {
    localStorage.setItem('pantAssets', JSON.stringify(pantAssets));
  }, [pantAssets]);

  useEffect(() => {
    localStorage.setItem('selectedPantAssets', JSON.stringify(selectedPantAssets));
  }, [selectedPantAssets]);

  useEffect(() => {
    localStorage.setItem('assetPantVisibility', JSON.stringify(assetPantVisibility));
  }, [assetPantVisibility]);

  
  useEffect(() => {
    localStorage.setItem('helmetAssets', JSON.stringify(helmetAssets));
  }, [helmetAssets]);

  useEffect(() => {
    localStorage.setItem('selectedHelmetAssets', JSON.stringify(selectedHelmetAssets));
  }, [selectedHelmetAssets]);

  useEffect(() => {
    localStorage.setItem('assetHelmetVisibility', JSON.stringify(assetHelmetVisibility));
  }, [assetHelmetVisibility]);


  useEffect(() => {
    localStorage.setItem('sockAssets', JSON.stringify(sockAssets));
  }, [sockAssets]);

  useEffect(() => {
    localStorage.setItem('selectedSockAssets', JSON.stringify(selectedSockAssets));
  }, [selectedSockAssets]);

  useEffect(() => {
    localStorage.setItem('assetSockVisibility', JSON.stringify(assetSockVisibility));
  }, [assetSockVisibility]);

  useEffect(()=>{
    localStorage.setItem('brandTabOpen', JSON.stringify(brandTabOpen));
  },[brandTabOpen]);

  useEffect(()=>{
    localStorage.setItem('jerseyTabOpen', JSON.stringify(jerseyTabOpen));
  },[jerseyTabOpen]);

  useEffect(()=>{
    localStorage.setItem('helmetTabOpen', JSON.stringify(helmetTabOpen));
  },[helmetTabOpen]);

  useEffect(()=>{
    localStorage.setItem('pantsTabOpen', JSON.stringify(pantsTabOpen));
  },[pantsTabOpen]);

  useEffect(()=>{
    localStorage.setItem('socksTabOpen', JSON.stringify(socksTabOpen));
  },[socksTabOpen]);

  useEffect(()=>{
    localStorage.setItem('numbersTabOpen', JSON.stringify(numbersTabOpen));
  },[numbersTabOpen]);

  function useTextureLoader(url) {
    return useMemo(() => new THREE.TextureLoader().load(url), [url]);
  }
  
  function Model() {
    generatePNGImage();
    const [texturesLoaded, setTexturesLoaded] = useState(false);
  
    const texture = useTextureLoader(localStorage.getItem('assetTextureJerseyWithNumbers'));
    const normalMap = useTextureLoader(jerseyNormUrl);
    const texturePants = useTextureLoader(localStorage.getItem('assetTexturePants'));
    const normalMapPants = useTextureLoader(pantsNormUrl);
    const textureHelmet = useTextureLoader(localStorage.getItem('assetTextureHelmet'));
    const textureSock = useTextureLoader(localStorage.getItem('assetTextureSock'));
    const normalMapSocks = useTextureLoader('/KitCreator/socks/norm/socks_normal.png');
    const mannequinTexture = useTextureLoader('/KitCreator/mannequin_color.png');
  
    const accessoriesAsset = helmetAssets.find((asset) => asset.name === 'Accessories');
    const accessoriesColor = accessoriesAsset.color;
  
    const facemaskAsset = helmetAssets.find((asset) => asset.name === 'Facemask');
    const facemaskColor = facemaskAsset.color;
  
    const chinstrapAsset = helmetAssets.find((asset) => asset.name === 'Chinstrap');
    const chinstrapColor = chinstrapAsset.color;
  
    const [material, setMaterial] = useState(null);
    const [materialPants, setMaterialPants] = useState(null);
    const [materialHelmet, setMaterialHelmet] = useState(null);
    const [materialSock, setMaterialSock] = useState(null);
    const [materialHelmetAcc, setMaterialHelmetAcc] = useState(null);
    const [materialHelmetChin, setMaterialHelmetChin] = useState(null);
    const [materialHelmetMask, setMaterialHelmetMask] = useState(null);
    const [materialMannequin, setMaterialMannequin] = useState(null);
  
    useEffect(() => {
      if (
        texture &&
        normalMap &&
        texturePants &&
        normalMapPants &&
        textureHelmet &&
        textureSock &&
        normalMapSocks &&
        mannequinTexture
      ) {
        const material = new THREE.MeshStandardMaterial({ map: texture, normalMap });
        const materialPants = new THREE.MeshStandardMaterial({ map: texturePants, normalMap: normalMapPants });
        const materialHelmet = new THREE.MeshStandardMaterial({ map: textureHelmet });
        const materialSock = new THREE.MeshStandardMaterial({ map: textureSock, normalMap: normalMapSocks });
        const materialHelmetAcc = new THREE.MeshStandardMaterial({ color: accessoriesColor });
        const materialHelmetChin = new THREE.MeshStandardMaterial({ color: chinstrapColor });
        const materialHelmetMask = new THREE.MeshStandardMaterial({ color: facemaskColor });
        const materialMannequin = new THREE.MeshStandardMaterial({ map: mannequinTexture });
  
        setMaterial(material);
        setMaterialPants(materialPants);
        setMaterialHelmet(materialHelmet);
        setMaterialSock(materialSock);
        setMaterialHelmetAcc(materialHelmetAcc);
        setMaterialHelmetChin(materialHelmetChin);
        setMaterialHelmetMask(materialHelmetMask);
        setMaterialMannequin(materialMannequin);
        setTexturesLoaded(true);
      }
    }, [
      texture,
      normalMap,
      texturePants,
      normalMapPants,
      textureHelmet,
      textureSock,
      normalMapSocks,
      mannequinTexture,
      accessoriesColor,
      chinstrapColor,
      facemaskColor,
    ]);
  
    const fbx = useLoader(FBXLoader, '/KitCreator/UniModel2.fbx');
  
    useEffect(() => {
      if (texturesLoaded) {
        fbx.traverse((child) => {
          if (child.isMesh && (child.name.toLowerCase().includes('jersey') || child.name.toLowerCase().includes('sleeve') || child.name.toLowerCase().includes('player_2'))) {
            child.material = material;
          } else if (child.isMesh && (child.name.toLowerCase().includes('pant') || child.name.toLowerCase().includes('player_3'))) {
            child.material = materialPants;
          } else if (
            child.isMesh &&
            (child.name.includes('RevoSpeed') ||
              child.name.includes('schutt_shell') ||
              child.name.includes('speed_helmet_shell') ||
              child.name.includes('revo_shell'))
          ) {
            child.material = materialHelmet;
          } else if (child.isMesh && child.name.includes('chin')) {
            child.material = materialHelmetChin;
          } else if (child.isMesh && child.name.includes('acc')) {
            child.material = materialHelmetAcc;
          } else if (child.isMesh && (child.name.includes('facemask') || child.name.includes('clip'))) {
            child.material = materialHelmetMask;
          } else if (child.isMesh && child.name.includes('sock')) {
            child.material = materialSock;
          } else {
            child.material = materialMannequin;
          }
        });
      }
    }, [
      fbx,
      material,
      materialPants,
      materialHelmet,
      materialHelmetChin,
      materialHelmetAcc,
      materialHelmetMask,
      materialSock,
      materialMannequin,
      texturesLoaded,
    ]);
  
    fbx.scale.set(0.035, 0.035, 0.035);
    fbx.position.set(0, -4, 0);
  
    return <primitive object={fbx} />;
  }
  
  const handleFileUpload = (event) => {

    const file = event.target.files[0];

    if (!file) {
      // No file selected, handle the error or show a message to the user
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const uploadedImageUrl = e.target.result;
      const newAsset = {
        id: Math.random().toString(), // Generate a unique ID for the uploaded asset
        name: file.name,
        subType: 'Custom Asset',
        type: 'Custom',
        imageUrl: uploadedImageUrl,
        color: selectedColor,
      };

      setAssets([...assets, newAsset]);

      // Set the uploaded image as the current asset
      setCurrentAsset(newAsset);
    };
    reader.readAsDataURL(file);

  };


  const handleLogoUpload = (event) => {

    const file = event.target.files[0];

    if (!file) {
      // No file selected, handle the error or show a message to the user
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const uploadedImageUrl = e.target.result;
      
    setLogoUrl(uploadedImageUrl)
    };
    reader.readAsDataURL(file);

  };

  const allLogos=useMemo(()=>allLogosData,[])
  const allAssets = useMemo(() => allJerseyAssetsData,[]);
  const allPantAssets = useMemo(() => allPantAssetsData,[]);
  const allJerseyNorms = useMemo(() => allJerseyNormsData,[]);
  const allPantsNorms = useMemo(() => allPantsNormsData,[]);
  const allHelmetAssets = useMemo(() => allHelmetAssetsData,[]);
  const allSockAssets = useMemo(() => allSockAssetsData,[]);

  const handleAssetSelection = (assetId, selAssets, setSelAssets) => {
    const isAssetSelected = selAssets.includes(assetId);
    if (isAssetSelected) {
      setSelAssets(selAssets.filter((id) => id !== assetId));
    } else {
      setSelAssets([...selAssets, assetId]);
    }
  };
  
  const getAssetStyle = (assetId, assList) => {
    const selectedAsset = assList.find((asset) => asset.id === assetId);
  
    if (selectedAsset) {
      if (selectedAsset.type === 'Custom' || selectedAsset.type === 'Texture' || (selectedAsset.type === 'League' && selectedAsset.canColor===undefined)) {
        const zIndex = -1 * assList.indexOf(selectedAsset);
        return { zIndex };
      } else {
        const rgb = hexToRgb(selectedAsset.color);
        if (rgb.length !== 3) {
          console.error('Invalid color format!');
          return '';
        }
  
        const color = new Color(rgb[0], rgb[1], rgb[2]);
        const solver = new Solver(color);
        const result = solver.solve();
  
        return { zIndex: -1 * assList.indexOf(selectedAsset), filter: result.filter };
      }
    }
    return {};
  };

  const generatePNGImage = useCallback(() => {
    const kitPreview = kitPreviewRef.current;
    const kitPreviewPants = kitPreviewPantsRef.current;
    const kitPreviewHelmet = kitPreviewHelmetRef.current;
    const kitPreviewSock = kitPreviewSockRef.current;

    const kitPreviewRect = kitPreview.getBoundingClientRect();
    const kitPreviewPantsRect = kitPreviewPants.getBoundingClientRect();
    const kitPreviewHelmetRect = kitPreviewHelmet.getBoundingClientRect();
    const kitPreviewSockRect = kitPreviewSock.getBoundingClientRect();

    const canvas = document.createElement('canvas');
    canvas.width = 2048;
    canvas.height = 4096;

    const canvasJerseyWithNumbers = document.createElement('canvas');
    canvasJerseyWithNumbers.width = 2048;
    canvasJerseyWithNumbers.height = 4096;

    const canvasPants = document.createElement('canvas');
    canvasPants.width = 2048;
    canvasPants.height = 2048;

    const canvasHelmet = document.createElement('canvas');
    canvasHelmet.width = 2048;
    canvasHelmet.height = 1024;

    const canvasSock = document.createElement('canvas');
    canvasSock.width = 512;
    canvasSock.height = 512;
  
    const ctx = canvas.getContext('2d');
    const ctxJerseyWithNumbers = canvasJerseyWithNumbers.getContext('2d');
    const ctxPants = canvasPants.getContext('2d');
    const ctxHelmet = canvasHelmet.getContext('2d');
    const ctxSock = canvasSock.getContext('2d');

    const assetElements = kitPreview.querySelectorAll('.kit-layer, .kit-custom-layer');
    const assetElementsPants = kitPreviewPants.querySelectorAll('.kit-layer, .kit-custom-layer');
    const assetElementsHelmet = kitPreviewHelmet.querySelectorAll('.kit-layer, .kit-custom-layer');
    const assetElementsSock = kitPreviewSock.querySelectorAll('.kit-layer, .kit-custom-layer');

    // Filter the asset elements based on visibility
    const visibleAssets = Array.from(assetElements).filter((assetElement) => {
      const assetVisibility = assetElement.style.visibility;
      return assetVisibility !== 'hidden';
    });

    const visibleAssetsPants = Array.from(assetElementsPants).filter((assetElement) => {
      const assetVisibility = assetElement.style.visibility;
      return assetVisibility !== 'hidden';
    });
  
    const visibleAssetsHelmet = Array.from(assetElementsHelmet).filter((assetElement) => {
      const assetVisibility = assetElement.style.visibility;
      return assetVisibility !== 'hidden';
    });

    const visibleAssetsSocks = Array.from(assetElementsSock).filter((assetElement) => {
      const assetVisibility = assetElement.style.visibility;
      return assetVisibility !== 'hidden';
    });

    // Sort the visible asset elements based on their computed z-index
    const sortedAssets = visibleAssets.sort(
      (a, b) => Number(getComputedStyle(a).zIndex) - Number(getComputedStyle(b).zIndex)
    );
    const sortedAssetsPants = visibleAssetsPants.sort(
      (a, b) => Number(getComputedStyle(a).zIndex) - Number(getComputedStyle(b).zIndex)
    );

    const sortedAssetsHelmet = visibleAssetsHelmet.sort(
      (a, b) => Number(getComputedStyle(a).zIndex) - Number(getComputedStyle(b).zIndex)
    );
    const sortedAssetsSock = visibleAssetsSocks.sort(
      (a, b) => Number(getComputedStyle(a).zIndex) - Number(getComputedStyle(b).zIndex)
    );
    // Draw each visible asset onto the canvas in the sorted order
    sortedAssets.forEach((assetElement) => {
      
      const assetImage = new Image();
      assetImage.src = assetElement.src;
      const assetRect = assetElement.getBoundingClientRect();
  
      // Apply hue, saturation, and assetElement.style)
      const filter = assetElement.style.filter;
      ctx.filter = filter;

      ctx.drawImage(assetImage, assetRect.x - kitPreviewRect.x, assetRect.y - kitPreviewRect.y);

      const customFont = new FontFace('CustomFont', `url(${jerseyFont})`);
      document.fonts.add(customFont);
      ctx.font = `${fontSize}px CustomFont`;

      // Draw the text on the canvas
     // ctx.font = '80px Arial';

      const computedColor = getComputedStyle(assetElement).color;

      ctx.fillStyle = computedColor;
      ctx.textAlign = "center";
      let x =2048/2;
      let y=4096*(3/4) - yOffset;
      ctx.fillText(
        assetElement.textContent,
        x,
        y + 150
      );

      // Reset the filter for the next asset

      //ctxJerseyWithNumbers.font = `400px CustomFont`;

      ctx.filter = 'none';

      const image = new Image();
      image.src = logoUrl;
      if(showChestLogo){
      ctx.drawImage(image, (2048/2)+175, 3040, image.width / 8, image.height / 8);
      }
      if(showBackLogo){
      const centerX = 2048 / 2;
      const centerY = 3040 - 530;
      const imageWidth = image.width / 14;
      const imageHeight = image.height / 14;
      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate(Math.PI);
      ctx.drawImage(image, -imageWidth / 2, -imageHeight / 2, imageWidth, imageHeight);
      ctx.restore();
      }
      if(showSleeveLogo){
      //tight
      let centerX = (2048 / 2)-200;
      let centerY = 675;
      const imageWidth = image.width / 5;
      const imageHeight = image.height / 5;
      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate(Math.PI/2);
      ctx.drawImage(image, -imageWidth / 2, -imageHeight / 2, imageWidth, imageHeight);
      ctx.restore();
      
      //long
      centerX = (2048 / 2)-825;
      centerY = 1050;
      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate(Math.PI/2);
      ctx.drawImage(image, -imageWidth / 2, -imageHeight / 2, imageWidth, imageHeight);
      ctx.restore();

      //standard
      centerX = (2048 / 2)-650;
      centerY = 2750;
      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate(Math.PI/2);
      ctx.drawImage(image, -imageWidth / 2, -imageHeight / 2, imageWidth, imageHeight);
      ctx.restore();

      centerX=(2048 / 2)+200;
      centerY = 675;
      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate(Math.PI+(Math.PI/2));
      if (flipHelmetLogo) {
        ctx.scale(-1, 1);
      }
      ctx.drawImage(image, -imageWidth / 2, -imageHeight / 2, imageWidth, imageHeight);
      ctx.restore();

      centerX = (2048 / 2)+825;
      centerY = 1050;
      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate(Math.PI+(Math.PI/2));
      if (flipHelmetLogo) {
        ctx.scale(-1, 1);
      }
      ctx.drawImage(image, -imageWidth / 2, -imageHeight / 2, imageWidth, imageHeight);
      ctx.restore();

      centerX = (2048 / 2)+650;
      centerY = 2750;
      ctx.save();
      ctx.translate(centerX, centerY);
      ctx.rotate(Math.PI+(Math.PI/2));
      if (flipHelmetLogo) {
        ctx.scale(-1, 1);
      }
      ctx.drawImage(image, -imageWidth / 2, -imageHeight / 2, imageWidth, imageHeight);
      ctx.restore();

      }
    });

    sortedAssets.forEach((assetElement) => {
      
      const assetImage = new Image();
      assetImage.src = assetElement.src;
      const assetRect = assetElement.getBoundingClientRect();
  
      // Apply hue, saturation, and assetElement.style)
      const filter = assetElement.style.filter;
      ctxJerseyWithNumbers.filter = filter;

      ctxJerseyWithNumbers.drawImage(assetImage, assetRect.x - kitPreviewRect.x, assetRect.y - kitPreviewRect.y);

      const customFont = new FontFace('CustomFont', `url(${jerseyFont})`);
      document.fonts.add(customFont);
      const customNumberFont = new FontFace('NumberFont', `url(${numberFont})`);
      document.fonts.add(customNumberFont);
      ctxJerseyWithNumbers.font = `${fontSize}px CustomFont`;


      const computedColor = getComputedStyle(assetElement).color;
      
      
      ctxJerseyWithNumbers.fillStyle = computedColor;
      ctxJerseyWithNumbers.textAlign = 'center';
      let x = 2048 / 2;
      let y = 4096 * (3 / 4) - yOffset;
      ctxJerseyWithNumbers.fillText(assetElement.textContent, x, y + 150);

      ctxJerseyWithNumbers.filter = 'none';
      // Reset the filter for the next asset
      y = 4096 * (3 / 4);
      ctxJerseyWithNumbers.font = '600px NumberFont';
      ctxJerseyWithNumbers.fillStyle = numberColor;
      ctxJerseyWithNumbers.textAlign = 'center';
      ctxJerseyWithNumbers.fillText(showNumber, x, y + 650);
      
      ctxJerseyWithNumbers.save(); // Save the current state of the context

// Mirror the text vertically using the scale method
ctxJerseyWithNumbers.scale(-1, -1);

ctxJerseyWithNumbers.font = `700px NumberFont`;
ctxJerseyWithNumbers.fillStyle = numberColor;
ctxJerseyWithNumbers.textAlign = "center";
ctxJerseyWithNumbers.fillText(
  showNumber,
  -x,
  -y + 1350 // Negate the y-coordinate to reflect vertically
);


ctxJerseyWithNumbers.restore();
      const image = new Image();
      image.src = logoUrl;
      if(showChestLogo){
        ctxJerseyWithNumbers.drawImage(image, (2048/2)+175, 3040, image.width / 8, image.height / 8);
      }
      if(showBackLogo){
      const centerX = 2048 / 2;
      const centerY = 3040 - 530;
      const imageWidth = image.width / 14;
      const imageHeight = image.height / 14;
      ctxJerseyWithNumbers.save();
      ctxJerseyWithNumbers.translate(centerX, centerY);
      ctxJerseyWithNumbers.rotate(Math.PI);
      ctxJerseyWithNumbers.drawImage(image, -imageWidth / 2, -imageHeight / 2, imageWidth, imageHeight);
      ctxJerseyWithNumbers.restore();
      }
      if(showSleeveLogo){
      //tight
      let centerX = (2048 / 2)-200;
      let centerY = 675;
      const imageWidth = image.width / 5;
      const imageHeight = image.height / 5;
      ctxJerseyWithNumbers.save();
      ctxJerseyWithNumbers.translate(centerX, centerY);
      ctxJerseyWithNumbers.rotate(Math.PI/2);
      ctxJerseyWithNumbers.drawImage(image, -imageWidth / 2, -imageHeight / 2, imageWidth, imageHeight);
      ctxJerseyWithNumbers.restore();
      
      //long
      centerX = (2048 / 2)-825;
      centerY = 1050;
      ctxJerseyWithNumbers.save();
      ctxJerseyWithNumbers.translate(centerX, centerY);
      ctxJerseyWithNumbers.rotate(Math.PI/2);
      ctxJerseyWithNumbers.drawImage(image, -imageWidth / 2, -imageHeight / 2, imageWidth, imageHeight);
      ctxJerseyWithNumbers.restore();

      //standard
      centerX = (2048 / 2)-650;
      centerY = 2750;
      ctxJerseyWithNumbers.save();
      ctxJerseyWithNumbers.translate(centerX, centerY);
      ctxJerseyWithNumbers.rotate(Math.PI/2);
      ctxJerseyWithNumbers.drawImage(image, -imageWidth / 2, -imageHeight / 2, imageWidth, imageHeight);
      ctxJerseyWithNumbers.restore();
      ctxJerseyWithNumbers.save();
      //can be flipped
      centerX=(2048 / 2)+200;
      centerY = 675;
      ctxJerseyWithNumbers.save();
      ctxJerseyWithNumbers.translate(centerX, centerY);
      ctxJerseyWithNumbers.rotate(Math.PI + (Math.PI / 2));
      if (flipHelmetLogo) {
        ctxJerseyWithNumbers.scale(-1, 1);
      }
      ctxJerseyWithNumbers.drawImage(image, -imageWidth / 2, -imageHeight / 2, imageWidth, imageHeight);
      ctxJerseyWithNumbers.restore();

      centerX = (2048 / 2)+825;
      centerY = 1050;
      ctxJerseyWithNumbers.save();
      ctxJerseyWithNumbers.translate(centerX, centerY);
      ctxJerseyWithNumbers.rotate(Math.PI+(Math.PI/2));
      if (flipHelmetLogo) {
        ctxJerseyWithNumbers.scale(-1, 1);
      }
      ctxJerseyWithNumbers.drawImage(image, -imageWidth / 2  , -imageHeight / 2  , imageWidth, imageHeight);
      ctxJerseyWithNumbers.restore();

      centerX = (2048 / 2)+650;
      centerY = 2750;
      ctxJerseyWithNumbers.save();
      ctxJerseyWithNumbers.translate(centerX, centerY);
      ctxJerseyWithNumbers.rotate(Math.PI+(Math.PI/2));
      if (flipHelmetLogo) {
        ctxJerseyWithNumbers.scale(-1, 1);
      }
      ctxJerseyWithNumbers.drawImage(image, -imageWidth / 2, -imageHeight / 2  , imageWidth  , imageHeight);
      ctxJerseyWithNumbers.restore();
      }
    });

    sortedAssetsPants.forEach((assetElement) => {
      const assetImage = new Image();
      assetImage.src = assetElement.src;
      const assetRect = assetElement.getBoundingClientRect();
  
      // Apply hue, saturation, and assetElement.style)
      const filter = assetElement.style.filter;
      ctxPants.filter = filter;
  
      ctxPants.drawImage(assetImage, assetRect.x - kitPreviewPantsRect.x, assetRect.y - kitPreviewPantsRect.y);
  
      // Reset the filter for the next asset
      ctxPants.filter = 'none';
    });
    
    sortedAssetsHelmet.forEach((assetElement) => {

      const assetImage = new Image();
      assetImage.src = assetElement.src;
      const assetRect = assetElement.getBoundingClientRect();
  
      // Apply hue, saturation, and assetElement.style)
      const filter = assetElement.style.filter;
      ctxHelmet.filter = filter;
  
      ctxHelmet.drawImage(assetImage, assetRect.x - kitPreviewHelmetRect.x, assetRect.y - kitPreviewHelmetRect.y);
  
      // Reset the filter for the next asset
      ctxHelmet.filter = 'none';

      const image = new Image();
image.src = logoUrl;
if (showHelmetLogo) {
  let imageWidth = image.width / 3.5;
  let imageHeight = image.height / 3.5;

  if (logoHelmetSize !== 0) {
    const scaleFactor = logoHelmetSize / 100;
    const prevImageWidth = imageWidth;
    const prevImageHeight = imageHeight;
    
    imageWidth *= scaleFactor;
    imageHeight *= scaleFactor;

    // Calculate the offset to keep the image centered
    const widthDiff = prevImageWidth - imageWidth;
    const heightDiff = prevImageHeight - imageHeight;
    let offsetX = widthDiff / 2;
    let offsetY = heightDiff / 2;
    //offsetX+=logoHelmetX;
    offsetY-=logoHelmetY;
    const angleInRadians = (logoHelmetRotate * Math.PI) / 180;
    ctxHelmet.save();
    let centerX = (2048 / 9) + offsetX - logoHelmetX + imageWidth / 2;
    let centerY = (1024 / 2) - 100 + offsetY + imageHeight / 2;
    ctxHelmet.translate(centerX, centerY);
    ctxHelmet.rotate(angleInRadians);
    ctxHelmet.drawImage(image, -imageWidth / 2, -imageHeight / 2, imageWidth, imageHeight);
    ctxHelmet.restore();

    ctxHelmet.save();

let offset = 550;
let multiplier = 1;
if (flipHelmetLogo) {
  offset = 550;
  multiplier = -1;
}

// Translate the context to the center of the image
 centerX = (2048 / 9) + offset + 325 + offsetX + (imageWidth) / 2;
 centerY = (1024 / 2) - 100 + offsetY + imageHeight / 2;
ctxHelmet.translate(centerX, centerY);

// Apply scaling if needed
if (flipHelmetLogo) {
  ctxHelmet.scale(-1, 1);
}

ctxHelmet.rotate(-angleInRadians * multiplier);

// Draw the image with the adjusted position and size
ctxHelmet.drawImage(
  image,
  -multiplier * imageWidth / 2 + logoHelmetX,
  -imageHeight / 2,
  multiplier * imageWidth,
  imageHeight
);

ctxHelmet.restore();
  } else {
    // If logoHelmetSize is 0, set imageWidth and imageHeight to 0
    imageWidth = 0;
    imageHeight = 0;
  }
}
    });

    sortedAssetsSock.forEach((assetElement) => {

      const assetImage = new Image();
      assetImage.src = assetElement.src;
      const assetRect = assetElement.getBoundingClientRect();
  
      // Apply hue, saturation, and assetElement.style)
      const filter = assetElement.style.filter;
      ctxSock.filter = filter;
  
      ctxSock.drawImage(assetImage, assetRect.x - kitPreviewSockRect.x, assetRect.y - kitPreviewSockRect.y);
  
      // Reset the filter for the next asset
      ctxSock.filter = 'none';
    });

    // Convert the canvas to a data URL
    const dataUrl = canvas.toDataURL('image/png');
    const dataUrlJerseyWithNumbers = canvasJerseyWithNumbers.toDataURL('image/png');
    const dataUrlPants = canvasPants.toDataURL('image/png');
    const dataUrlHelmet = canvasHelmet.toDataURL('image/png');
    const dataUrlSock = canvasSock.toDataURL('image/png');

    // Store the data URL in local storage
    localStorage.setItem('assetTexture', dataUrl);
    localStorage.setItem('assetTextureJerseyWithNumbers', dataUrlJerseyWithNumbers);
    localStorage.setItem('assetTexturePants', dataUrlPants);
    localStorage.setItem('assetTextureHelmet', dataUrlHelmet);
    localStorage.setItem('assetTextureSock', dataUrlSock);
},[jerseyFont, fontSize, yOffset, logoUrl, showChestLogo, showBackLogo, showSleeveLogo, showHelmetLogo, flipHelmetLogo, logoHelmetSize, numberFont, numberColor, showNumber, logoHelmetX, logoHelmetY, logoHelmetRotate]);

  const saveKitAsImage = async () => {
    setSaving(true);

    const zip = new JSZip();
  
    const assetTexture = localStorage.getItem('assetTexture');
    const assetTexturePants = localStorage.getItem('assetTexturePants');
    const assetTextureHelmet = localStorage.getItem('assetTextureHelmet');
    const assetTextureSock = localStorage.getItem('assetTextureSock');

    const fetchBlobFromDataURL = async (dataURL) => {
      const response = await fetch(dataURL);
      const blob = await response.blob();
      return blob;
    };

    // Convert the data URLs to Blobs and add them to the zip file
    const generatedTextureBlob = await fetchBlobFromDataURL(assetTexture);
    const generatedTexturePantsBlob = await fetchBlobFromDataURL(assetTexturePants);
    const generatedTextureHelmetBlob = await fetchBlobFromDataURL(assetTextureHelmet);
    const generatedTextureSockBlob = await fetchBlobFromDataURL(assetTextureSock);
    
    zip.file('Jersey_COL.png', generatedTextureBlob);
    zip.file('Pants_COL.png', generatedTexturePantsBlob);
    zip.file('Helmet_COL.png', generatedTextureHelmetBlob);
    zip.file('Sock_COL.png', generatedTextureSockBlob);

    const numberFolder = zip.folder('numbers');

    
    for (let i = 0; i <= 9; i++) {
      const canvas = document.createElement('canvas');
      canvas.width = 512;
      canvas.height = 512;
      const ctx = canvas.getContext('2d');
    
      // Clear the canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    
      const text = i.toString();
      const fontSize = calculateFontSizeToFit(text, 'NumberFont', canvas.width, canvas.height);
    
      ctx.font = `${fontSize}px NumberFont`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle'; // Align text at the vertical middle
      ctx.fillStyle = numberColor;
      const yPosition = canvas.height / 2 + 50;
      ctx.fillText(text, canvas.width / 2, yPosition);
        
      const dataUrl = canvas.toDataURL('image/png');
      const base64Data = dataUrl.split(',')[1];
      const blob = base64toBlob(base64Data, 'image/png');
    
      numberFolder.file(`${i}.png`, blob);
    }

    function calculateFontSizeToFit(text, fontName, maxWidth, maxHeight) {
      let fontSize = 1;
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
    
      while (true) {
        ctx.font = `${fontSize}px ${fontName}`;
        const textWidth = ctx.measureText(text).width;
        const textHeight = fontSize * 1.2; // Rough estimate of text height
    
        if (textWidth > maxWidth || textHeight > maxHeight) {
          return fontSize - 1;
        }
    
        fontSize++;
      }
    }
    
    // Function to convert base64 data to Blob
    function base64toBlob(base64Data, contentType) {
      const sliceSize = 1024;
      const byteCharacters = atob(base64Data);
      const byteArrays = [];
    
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
    
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
    
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
    
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    }

    // Generate the zip file
    const content = await zip.generateAsync({ type: 'blob' });
  
    // Save the zip file
    const link = document.createElement('a');
    link.href = URL.createObjectURL(content);

    setSaving(false);

    link.download = 'kit_assets.zip';
    link.click();
  };
  
 
  return (
<div
  className="asset-options-container"
  style={{
    background: `
    linear-gradient(to right, #f0f0f7 1px, transparent 1px) 0 0 / 10px 100% repeat-x,
    linear-gradient(to bottom, #f0f0f7 1px, transparent 1px) 0 0 / 100% 10px repeat-y,
    linear-gradient(to right, #f0f0f7, #f0f0f7) 0 0 / 100% 1px no-repeat,
    linear-gradient(to bottom, #f0f0f7, #f0f0f7) 0 0 / 1px 100% no-repeat,
    linear-gradient(to right, #fff, #fff) 10px 0 / 1px 100% repeat-y,
    linear-gradient(to bottom, #fff, #fff) 0 10px / 100% 1px repeat-x
  `,
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: '0',
    zIndex: '-9999',
    userSelect: 'none'
  }}
>
<Helmet>
  <title>JerseyForge</title>
  <link rel="icon" type="image/png" href="/KitCreator/jerseyForgeLogo.png" />
</Helmet>
{saving && (
        <div className="loading-overlay">
          <div className="loading-message">Saving...</div>
        </div>
      )}
        <div className='asset-options'>
      <h2 style={{fontSize:'35px'}}><img alt='jerseyforge logo' src='/KitCreator/jerseyForgeLogo.png' style={{height:'35px', width:'35px'}}/> JerseyForge</h2>
      <h3 style={{marginTop:'-8px', color:'rgba(0,0,0,0.5)'}}>BETA</h3>
      <div style={{marginBottom:'10px'}}>


    </div>
      <div className="save-button-container">
      <button onClick={saveKitAsImage} className="save-button">
        <BsFillFileEarmarkZipFill/> Save Jersey as Zip
      </button>
    </div>
    
 
 <div className='tab-container'>
 <div className="tab">
  <button onClick={() => {
    setBrandTabOpen(!brandTabOpen);
    if(pantsTabOpen && !brandTabOpen){
      setPantsTabOpen(false)
    }
    if(jerseyTabOpen && !brandTabOpen){
      setJerseyTabOpen(false)
    }
    if(helmetTabOpen && !brandTabOpen){
      setHelmetTabOpen(false)
    }
    if(socksTabOpen && !brandTabOpen){
      setSocksTabOpen(false)
    }
    if(numbersTabOpen && !brandTabOpen){
      setNumbersTabOpen(false)
    }
    }}
    disabled={brandTabOpen}>
    { !brandTabOpen ? <BsFillShieldFill style={{ color: 'rgba(0,0,0,0.5', width: '30px', height: '30px', cursor:'pointer' }} /> :  <BsFillShieldFill style={{ color: 'rgba(128, 0, 128', width: '30px', height: '30px'}}/>}
  </button>
</div>
<div className="tab">
  <button onClick={() => {
    setHelmetTabOpen(!helmetTabOpen);
    if(!helmetTabOpen && pantsTabOpen){
      setPantsTabOpen(false)
    }
    if(!helmetTabOpen && brandTabOpen){
      setBrandTabOpen(false)
    }
    if(!helmetTabOpen && jerseyTabOpen){
      setJerseyTabOpen(false)
    }
    if(socksTabOpen && !helmetTabOpen){
      setSocksTabOpen(false)
    }
    if(numbersTabOpen && !helmetTabOpen){
      setNumbersTabOpen(false)
    }
    }}
    disabled={helmetTabOpen}>
    { !helmetTabOpen ? <GiAmericanFootballHelmet style={{ color: 'rgba(0,0,0,0.5', width: '30px', height: '30px', cursor:'pointer' }} /> :  <GiAmericanFootballHelmet style={{ color: 'rgba(128, 0, 128)', width: '30px', height: '30px' }}/>}
  </button>
</div>
    <div className="tab">
  <button onClick={() => {
    setJerseyTabOpen(!jerseyTabOpen);
    if(!jerseyTabOpen && pantsTabOpen){
      setPantsTabOpen(false)
    }
    if(!jerseyTabOpen && brandTabOpen){
      setBrandTabOpen(false)
    }
    if(helmetTabOpen && !jerseyTabOpen){
      setHelmetTabOpen(false)
    }
    if(socksTabOpen && !jerseyTabOpen){
      setSocksTabOpen(false)
    }
    if(numbersTabOpen && !jerseyTabOpen){
      setNumbersTabOpen(false)
    }
    }}
    disabled={jerseyTabOpen}>
    { !jerseyTabOpen ? <FaTshirt style={{ color: 'rgba(0,0,0,0.5', width: '30px', height: '30px', cursor:'pointer' }} /> :  <FaTshirt style={{ color: 'rgba(128, 0, 128', width: '30px', height: '30px' }}/>}
  </button>
</div>
<div className="tab">
  <button onClick={() => {
    setPantsTabOpen(!pantsTabOpen)
    if(jerseyTabOpen && !pantsTabOpen){
      setJerseyTabOpen(false)
    }
    if(brandTabOpen && !pantsTabOpen){
      setBrandTabOpen(false)
    } 
    if(helmetTabOpen && !pantsTabOpen){
      setHelmetTabOpen(false)
    }
    if(socksTabOpen && !pantsTabOpen){
      setSocksTabOpen(false)
    }
    if(numbersTabOpen && !pantsTabOpen){
      setNumbersTabOpen(false)
    }
     }}
     disabled={pantsTabOpen}>
    { !pantsTabOpen ? <GiUnderwearShorts style={{ color: 'rgba(0,0,0,0.5', width: '30px', height: '30px', cursor:'pointer' }} /> : <GiUnderwearShorts style={{ color: 'rgba(128, 0, 128', width: '30px', height: '30px'}}/>}
  </button>
</div>
<div className="tab">
  <button onClick={() => {
    setSocksTabOpen(!socksTabOpen)
    if(jerseyTabOpen && !socksTabOpen){
      setJerseyTabOpen(false)
    }
    if(brandTabOpen && !socksTabOpen){
      setBrandTabOpen(false)
    } 
    if(helmetTabOpen && !socksTabOpen){
      setHelmetTabOpen(false)
    }
    if(pantsTabOpen && !socksTabOpen){
      setPantsTabOpen(false)
    }
    if(numbersTabOpen && !socksTabOpen){
      setNumbersTabOpen(false)
    }
     }}
     disabled={socksTabOpen}>
    { !socksTabOpen ? <FaSocks style={{ color: 'rgba(0,0,0,0.5', width: '30px', height: '30px', cursor:'pointer' }} /> : <FaSocks style={{ color: 'rgba(128, 0, 128', width: '30px', height: '30px'}}/>}
  </button>
</div>
<div className="tab">
  <button onClick={() => {
    setNumbersTabOpen(!numbersTabOpen)
    if(jerseyTabOpen && !numbersTabOpen){
      setJerseyTabOpen(false)
    }
    if(brandTabOpen && !numbersTabOpen){
      setBrandTabOpen(false)
    } 
    if(helmetTabOpen && !numbersTabOpen){
      setHelmetTabOpen(false)
    }
    if(pantsTabOpen && !numbersTabOpen){
      setPantsTabOpen(false)
    }
    if(socksTabOpen && !numbersTabOpen){
      setSocksTabOpen(false)
    }
     }}
     disabled={numbersTabOpen}>
    { !numbersTabOpen ? <MdNumbers style={{ color: 'rgba(0,0,0,0.5', width: '30px', height: '30px', cursor:'pointer' }} /> : <MdNumbers style={{ color: 'rgba(128, 0, 128', width: '30px', height: '30px' }}/>}
  </button>
</div>
<div className="tab">
<button
  onClick={() => {
    const confirmed = window.confirm(
      'Are you sure you want to reset the Jersey? This action cannot be undone.'
    );
    if (confirmed) {
      localStorage.clear();
      window.location.reload();
    }
  }}
  // className="reset-button"
>
  <BiReset 
    style={{
      color: 'rgba(0,0,0,0.5)',
      width: '30px',
      height: '30px',
      cursor: 'pointer',
      transition: 'color 0.3s', // Add transition for smooth color change
    }}
    onMouseEnter={(e) => {
      e.target.style.color = 'red'; // Change color to red on hover
    }}
    onMouseLeave={(e) => {
      e.target.style.color = 'rgba(0,0,0,0.5)'; // Revert color back to the original on mouse leave
    }}
  />
</button>
</div>
</div>
     {brandTabOpen &&
      <BrandOptions
        tabOpen={brandTabOpen}
        title="Branding"
        logoUrl={logoUrl}
        setLogoUrl={handleLogoUpload}
        changeUrlManual={setLogoUrl}
        teamName={teamName}
        setTeamName={setTeamName}
        cityName={cityName}
        setCityName={setCityName}
        primaryColor={primaryColor}
        setPrimaryColor={setPrimaryColor}
        secondaryColor={secondaryColor}
        setSecondaryColor={setSecondaryColor}
        assets={assets} // Pass the assets to AssetOptions
        setAssets={setAssets}  
        pantAssets={pantAssets} // Pass the assets to AssetOptions
        setPantAssets={setPantAssets}   
        helmetAssets={helmetAssets}
        setHelmetAssets={setHelmetAssets}   
        sockAssets={sockAssets}
        setSockAssets={setSockAssets}
        allLogos={allLogos}
        numberColor={numberColor}
        setNumberColor={setNumberColor}
        flipLogo={flipHelmetLogo}
        setFlipLogo={setFlipHelmetLogo}
      /> }
      {jerseyTabOpen && <AssetOptions
        tabOpen={jerseyTabOpen}
        allAssets={allAssets}
        handleAssetSelection={handleAssetSelection}
        selectedAssets={selectedAssets} // Pass the selectedAssets state as a prop
        assets={assets} // Pass the assets to AssetOptions
        setAssets={setAssets}
        title="Jersey"
        assetVisibility={assetVisibility}
        setAssetVisibility={setAssetVisibility}
        selectedColor={selectedColor} 
        setSelectedColor={setSelectedColor}
        currentAsset={currentAsset}
        setCurrentAsset={setCurrentAsset}
        handleFileUpload={handleFileUpload}
        currNorm={jerseyNormUrl}
        allNorms={allJerseyNorms}
        setNorm={setJerseyNormUrl}
        currFonts={jerseyFont}
        allFonts={allFonts}
        setFont={setJerseyFont}
        fontSize={fontSize}
        setFontSize={setFontSize}
        yOffset={yOffset}
        setYOffset={setYOffset}
        showChestLogo={showChestLogo}
        setShowChestLogo={setShowChestLogo}
        showBackLogo={showBackLogo}
        setShowBackLogo={setShowBackLogo}
        showSleeveLogo={showSleeveLogo}
        setShowSleeveLogo={setShowSleeveLogo}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        cityName={cityName}
        teamName={teamName}
        setSelectedAssets={setSelectedAssets}
        selectedAsset={selectedAssets}
      />}
        {pantsTabOpen && <AssetOptions
        tabOpen={pantsTabOpen}
        allAssets={allPantAssets}
        handleAssetSelection={handleAssetSelection}
        selectedAssets={selectedPantAssets} // Pass the selectedAssets state as a prop
        assets={pantAssets} // Pass the assets to AssetOptions
        setAssets={setPantAssets}
        title="Pants"
        assetVisibility={assetPantVisibility}
        setAssetVisibility={setAssetPantVisibility}
        selectedColor={selectedColor} 
        setSelectedColor={setSelectedColor}
        currentAsset={currentAsset}
        setCurrentAsset={setCurrentAsset}
        handleFileUpload={handleFileUpload}
        currNorm={pantsNormUrl}
        allNorms={allPantsNorms}
        setNorm={setPantsNormUrl}
        currFonts={jerseyFont}
        allFonts={allFonts}
        setFont={setJerseyFont}
        fontSize={fontSize}
        setFontSize={setFontSize}
        yOffset={yOffset}
        setYOffset={setYOffset}
        showChestLogo={showChestLogo}
        setShowChestLogo={setShowChestLogo}
        showBackLogo={showBackLogo}
        setShowBackLogo={setShowBackLogo}
        showSleeveLogo={showSleeveLogo}
        setShowSleeveLogo={setShowSleeveLogo}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        cityName={cityName}
        teamName={teamName}
        logoHelmetSize={logoHelmetSize}
        setLogoHelmetSize={setLogoHelmetSize}
        setSelectedAssets={setSelectedPantAssets}
        selectedAsset={selectedPantAssets}

      />}
        {helmetTabOpen && <AssetOptions
        tabOpen={helmetTabOpen}
        allAssets={allHelmetAssets}
        handleAssetSelection={handleAssetSelection}
        selectedAssets={selectedHelmetAssets} // Pass the selectedAssets state as a prop
        assets={helmetAssets} // Pass the assets to AssetOptions
        setAssets={setHelmetAssets}
        title="Helmet"
        assetVisibility={assetHelmetVisibility}
        setAssetVisibility={setAssetHelmetVisibility}
        selectedColor={selectedColor} 
        setSelectedColor={setSelectedColor}
        currentAsset={currentAsset}
        setCurrentAsset={setCurrentAsset}
        handleFileUpload={handleFileUpload}
        currNorm={[]}
        allNorms={[]}
        setNorm={setPantsNormUrl}
        currFonts={jerseyFont}
        allFonts={allFonts}
        setFont={setJerseyFont}
        fontSize={fontSize}
        setFontSize={setFontSize}
        yOffset={yOffset}
        setYOffset={setYOffset}
        showChestLogo={showHelmetLogo}
        setShowChestLogo={setShowHelmetLogo}
        showBackLogo={flipHelmetLogo}
        setShowBackLogo={setFlipHelmetLogo}
        showSleeveLogo={showHelmetLogo}
        setShowSleeveLogo={setShowHelmetLogo}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        cityName={cityName}
        teamName={teamName}
        logoHelmetSize={logoHelmetSize}
        setLogoHelmetSize={setLogoHelmetSize}
        logoHelmetX={logoHelmetX}
        setLogoHelmetX={setLogoHelmetX}
        logoHelmetY={logoHelmetY}
        setLogoHelmetY={setLogoHelmetY}
        logoHelmetRotate={logoHelmetRotate}
        setLogoHelmetRotate={setLogoHelmetRotate}
        setSelectedAssets={setSelectedHelmetAssets}
        selectedAsset={selectedHelmetAssets}
      />}
       {socksTabOpen && <AssetOptions
        tabOpen={socksTabOpen}
        allAssets={allSockAssets}
        handleAssetSelection={handleAssetSelection}
        selectedAssets={selectedSockAssets} // Pass the selectedAssets state as a prop
        assets={sockAssets} // Pass the assets to AssetOptions
        setAssets={setSockAssets}
        title="Socks"
        assetVisibility={assetSockVisibility}
        setAssetVisibility={setAssetSockVisibility}
        selectedColor={selectedColor} 
        setSelectedColor={setSelectedColor}
        currentAsset={currentAsset}
        setCurrentAsset={setCurrentAsset}
        handleFileUpload={handleFileUpload}
        currNorm={[]}
        allNorms={[]}
        setNorm={setPantsNormUrl}
        currFonts={jerseyFont}
        allFonts={allFonts}
        setFont={setJerseyFont}
        fontSize={fontSize}
        setFontSize={setFontSize}
        yOffset={yOffset}
        setYOffset={setYOffset}
        showChestLogo={showHelmetLogo}
        setShowChestLogo={setShowHelmetLogo}
        showBackLogo={flipHelmetLogo}
        setShowBackLogo={setFlipHelmetLogo}
        showSleeveLogo={showHelmetLogo}
        setShowSleeveLogo={setShowHelmetLogo}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        cityName={cityName}
        teamName={teamName}
        logoHelmetSize={logoHelmetSize}
        setLogoHelmetSize={setLogoHelmetSize}
        setSelectedAssets={setSelectedSockAssets}
        selectedAsset={selectedSockAssets}
      />}
      {numbersTabOpen && <NumberOptions
      tabOpen={numbersTabOpen}
      numberFont={numberFont}
      setNumberFont={setNumberFont}
      allFonts={allNumberFonts}
      numberColor={numberColor}
      setNumberColor={setNumberColor}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      showNumber={showNumber}
      setShowNumber={setShowNumber}
      />}
      </div>

      <div
  style={{
    position: 'absolute',
    top: '10%',
    border:'1px solid purple',
    borderRadius:'10px',
    left: '30%',
    height: '80vh',
    width: '25%',
  }}
  onMouseEnter={() => { document.body.style.cursor = 'grab'; }}
  onMouseLeave={() => { document.body.style.cursor = 'default'; }}
  onMouseDown={() => { document.body.style.cursor = 'grabbing'; }}
  onMouseUp={() => { document.body.style.cursor = 'grab'; }}
>
{showInstructions && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(148, 0, 211, 0.4)', // Light purple background color
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff',
            fontSize: '18px',
            zIndex: 9999,
            cursor:'default',
            backdropFilter: 'blur(8px)', // Apply backdrop filter with blur effect

          }}
        >
          <div>
            <b style={{fontSize:'30px'}}>3D Viewer Instructions:</b>
            <p>Use the mouse to orbit the object:</p>
              <p><b>Left-click</b> and drag to rotate</p>
              <p><b>Scroll</b> to zoom in/out</p>
              <p><b>Right-click</b> and drag to move the view</p>
            <button   className="save-button" onClick={() => setShowInstructions(false)}>Acknowledge</button>
          </div>
        </div>
      )}
    <Canvas>
    <ambientLight intensity={0.2} />
    <pointLight position={[10, 90, 20]} intensity={0.65} />
    <Model />
    <OrbitControls ref={orbitControlsRef}/>
  </Canvas>
</div>

<div id="textContainer" className="saveNumbers"></div>

      <div className="kit-preview-container">
        <div  className={`kit-preview ${numbersTabOpen || socksTabOpen || pantsTabOpen || brandTabOpen || helmetTabOpen? 'off-screen' : ''}`} ref={kitPreviewRef}>
        {showChestLogo&&
 <img
 src={logoUrl}
 alt="logo"
 className="kit-layer"
 style={{
   width: '7%',
   height: 'auto',
   paddingTop: '7%',
   objectFit: 'cover',
   left:'57%',
   top:'70%'
 }}
 
/>        }
{showBackLogo&&
 <img
 src={logoUrl}
 alt="logo"
 className="kit-layer"
 style={{
   width: '5%',
   height: 'auto',
   paddingTop: '5%',
   objectFit: 'cover',
   left:'46%',
   top:'61%',
   transform: 'rotate(180deg)', // Rotate the image by 180 degrees
 }}
/>        }
{showSleeveLogo&& <>
 <img
 src={logoUrl}
 alt="logo"
 className="kit-layer"
 style={{
   width: '7%',
   height: 'auto',
   paddingTop: '7%',
   objectFit: 'cover',
   left:'40%',
   top:'13.5%',
   transform: 'rotate(90deg)', // Rotate the image by 180 degrees
 }}
/>
<img
 src={logoUrl}
 alt="logo"
 className="kit-layer"
 style={{
   width: '7%',
   height: 'auto',
   paddingTop: '7%',
   objectFit: 'cover',
   left:'53%',
   top:'13.5%',
   transform: 'rotate(270deg)', // Rotate the image by 180 degrees
 }}
/>
<img
 src={logoUrl}
 alt="logo"
 className="kit-layer"
 style={{
   width: '7%',
   height: 'auto',
   paddingTop: '7%',
   objectFit: 'cover',
   left:'10%',
   top:'23%',
   transform: 'rotate(90deg)', // Rotate the image by 180 degrees
 }}
/>
<img
 src={logoUrl}
 alt="logo"
 className="kit-layer"
 style={{
   width: '7%',
   height: 'auto',
   paddingTop: '7%',
   objectFit: 'cover',
   left:'83%',
   top:'23%',
   transform: 'rotate(270deg)', // Rotate the image by 180 degrees
 }}
/>
<img
 src={logoUrl}
 alt="logo"
 className="kit-layer"
 style={{
   width: '7%',
   height: 'auto',
   paddingTop: '7%',
   objectFit: 'cover',
   left:'18.5%',
   top:'64%',
   transform: 'rotate(90deg)', // Rotate the image by 180 degrees
 }}
/>
<img
 src={logoUrl}
 alt="logo"
 className="kit-layer"
 style={{
   width: '7%',
   height: 'auto',
   paddingTop: '7%',
   objectFit: 'cover',
   left:'75%',
   top:'64%',
   transform: 'rotate(270deg)', // Rotate the image by 180 degrees
 }}
/>
</>}
        { selectedAssets.map((assetId) => {
  // eslint-disable-next-line array-callback-return
  const selectedAsset = assets.find((asset) => {
    if (asset.id === assetId) {
      return true;
    }
    return false;
  });
  if (selectedAsset!==undefined) {
    if(selectedAsset.type==='Custom'){
      return(
        <img
        key={assetId}
        src={selectedAsset.imageUrl}
        alt={selectedAsset.imageUrl}
        className="kit-custom-layer"
        style={getAssetStyle(assetId, assets)} // Set the style based on the asset ID
      />
      )
    }
    if(selectedAsset.type==='Text'){
      return(
        <div
        key={assetId}
        className="kit-custom-layer"
        style={{
          height: '100%',
          width: '100%',
          fontSize: `${fontSize/100}vw`,
          justifyContent: 'center',
          paddingTop:'154%',
          display:'flex',
          color: selectedAsset.color,
          fontFamily: 'CustomFont', // Replace 'CustomFont' with the desired custom font name
          top: `calc(${-yOffset/5}px)`,
        }}
      >
        {selectedAsset.text}
      </div>
      )
    }
    return (
      <img
        key={assetId}
        src={selectedAsset.imageUrl}
        alt={`Asset ${assetId}`}
        className="kit-layer"
        style={getAssetStyle(assetId, assets)} // Set the style based on the asset ID
      />
    );
  }
  return null; // Add a default return statement if the condition is not met
})}
        </div>
        <div className={`kit-preview-pants ${numbersTabOpen || socksTabOpen || jerseyTabOpen || brandTabOpen || helmetTabOpen ? 'off-screen' : ''}`} ref={kitPreviewPantsRef}>
    
      { selectedPantAssets.map((assetId) => {
// eslint-disable-next-line array-callback-return
const selectedAsset = pantAssets.find((asset) => {
  if (asset.id === assetId) {
    return true;    
  }
  return false;
});
if (selectedAsset!==undefined) {

  if(selectedAsset.type==='Custom'){
    return(
      <img
      key={assetId}
      src={selectedAsset.imageUrl}
      alt={selectedAsset.imageUrl}
      className="kit-custom-layer"
      style={getAssetStyle(assetId, pantAssets)} // Set the style based on the asset ID
    />
    )
  }
  return (
    <img
      key={assetId}
      src={selectedAsset.imageUrl}
      alt={`Asset ${assetId}`}
      className="kit-layer"
      style={getAssetStyle(assetId, pantAssets)} // Set the style based on the asset ID
    />
  );
}
return null; // Add a default return statement if the condition is not met
})}
      </div>
      <div className={`kit-preview-socks ${numbersTabOpen || pantsTabOpen || jerseyTabOpen || brandTabOpen || helmetTabOpen ? 'off-screen' : ''}`} ref={kitPreviewSockRef}>
    
    { selectedSockAssets.map((assetId) => {
// eslint-disable-next-line array-callback-return
const selectedAsset = sockAssets.find((asset) => {
if (asset.id === assetId) {
  return true;    
}
return false;
});
if (selectedAsset!==undefined) {

if(selectedAsset.type==='Custom'){
  return(
    <img
    key={assetId}
    src={selectedAsset.imageUrl}
    alt={selectedAsset.imageUrl}
    className="kit-custom-layer"
    style={getAssetStyle(assetId, sockAssets)} // Set the style based on the asset ID
  />
  )
}
return (
  <img
    key={assetId}
    src={selectedAsset.imageUrl}
    alt={`Asset ${assetId}`}
    className="kit-layer"
    style={getAssetStyle(assetId, sockAssets)} // Set the style based on the asset ID
  />
);
}
return null; // Add a default return statement if the condition is not met
})}
    </div>
      <div className={`kit-preview-helmet ${numbersTabOpen || socksTabOpen || jerseyTabOpen || brandTabOpen || pantsTabOpen? 'off-screen' : ''}`} ref={kitPreviewHelmetRef}>
    
    {showHelmetLogo&&<>
      <img
 src={logoUrl}
 alt="logo"
 className="kit-layer"
 style={{
    width: '17%',
   height: 'auto',
  //  paddingTop: '7%',
  //  objectFit: 'cover',
     left:'13%',
     top:'42%',
  //  transform: 'rotate(90deg)', // Rotate the image by 180 degrees
 }}
/>
{flipHelmetLogo&&
<img
 src={logoUrl}
 alt="logo"
 className="kit-layer"
 style={{
    width: '17%',
   height: 'auto',
  //  paddingTop: '7%',
  //  objectFit: 'cover',
     left:'50%',
     transform: 'scaleX(-1)', // Flip the image horizontally
     top:'42%',

  //  transform: 'rotate(90deg)', // Rotate the image by 180 degrees
 }}
/>
}
{!flipHelmetLogo&&
<img
 src={logoUrl}
 alt="logo"
 className="kit-layer"
 style={{
    width: '17%',
   height: 'auto',
  //  paddingTop: '7%',
  //  objectFit: 'cover',
     left:'50%',
     top:'42%',
  //  transform: 'rotate(90deg)', // Rotate the image by 180 degrees
 }}
/>
}
    </>}
    { selectedHelmetAssets.map((assetId) => {
// eslint-disable-next-line array-callback-return
const selectedAsset = helmetAssets.find((asset) => {
if (asset.id === assetId) {
  return true;    
}
return false;
});
if (selectedAsset!==undefined) {

if(selectedAsset.type==='Custom'){
  return(
    <img
    key={assetId}
    src={selectedAsset.imageUrl}
    alt={selectedAsset.imageUrl}
    className="kit-custom-layer"
    style={getAssetStyle(assetId, helmetAssets)} // Set the style based on the asset ID
  />
  )
}
return (
  <img
    key={assetId}
    src={selectedAsset.imageUrl}
    alt={`Asset ${assetId}`}
    className="kit-layer"
    style={getAssetStyle(assetId, helmetAssets)} // Set the style based on the asset ID
  />
);
}
return null; // Add a default return statement if the condition is not met
})}
    </div>
      <div style={{ border: `4px solid ${secondaryColor}`}} className={`kit-preview-branding ${numbersTabOpen || socksTabOpen || jerseyTabOpen || pantsTabOpen || helmetTabOpen ? 'off-screen' : ''}`} ref={kitPreviewBrandingRef}>
        <div style={{backgroundColor:primaryColor, margin:'-20px', padding:'20px'}}>
        <h1 style={{marginBottom:'-10px', color: 'white'}}><b>{teamName}</b></h1>
        <h2 style={{marginBottom:'10px', color: 'white'}}><b>{cityName}</b></h2>
        <div style={{backgroundColor: secondaryColor, height: '30px', margin:'10px -20px -20px -20px', color: 'white', paddingTop: '10px', paddingLeft: '15px',  fontStyle: 'italic'}}>Custom Logo should take up majority of space</div>
        </div>
        {/* <div style={{marginBottom:'10px'}}><b>Colors</b></div> */}
        <div style={{height:'80%'}}>
        <img src={logoUrl} alt="Team Logo" style={{ objectFit: 'contain', width: '100%', height: '100%', marginTop:'50px' }} />
        </div>
    </div>
      </div>
    </div>

  );
}

export default KitCreator;
