import React, { useState, useEffect } from 'react';
import './AssetOptions.css';
import { CirclePicker } from 'react-color';
import { ChromePicker } from 'react-color';
import {AiOutlineSwap} from 'react-icons/ai';
import {BsFillShieldFill} from 'react-icons/bs';

function BrandOptions({tabOpen, title, logoUrl, setLogoUrl,changeUrlManual, teamName, setTeamName, cityName, setCityName, primaryColor, setPrimaryColor, secondaryColor, setSecondaryColor, assets, setAssets, pantAssets, setPantAssets, helmetAssets, setHelmetAssets, sockAssets, setSockAssets, allLogos, numberColor, setNumberColor, flipLogo, setFlipLogo}) {
  const [isColorPickerOpen, setColorPickerOpen] = useState(false);
  const [isColorPickerSecondaryOpen, setColorPickerSecondaryOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [clearAssetGrid, setClearAssetGrid] = useState(false);
  const [filteredLogos, setFilteredLogos] = useState(
    allLogos.filter((asset) => {
      const nameMatch = asset.name.toLowerCase().includes(searchQuery.toLowerCase());
      const subTypeMatch = asset.subType.toLowerCase().includes(searchQuery.toLowerCase());
      return nameMatch || subTypeMatch;
    })
  )
  useEffect(() => {
    // Clear the asset grid when the searchQuery changes
    setClearAssetGrid(true);

    // Perform the search/filtering based on the new searchQuery
    // Update the filteredLogos array accordingly
    const updatedFilteredLogos = allLogos.filter((asset) => {
      const nameMatch = asset.name.toLowerCase().includes(searchQuery.toLowerCase());
      const subTypeMatch = asset.subType.toLowerCase().includes(searchQuery.toLowerCase());
      return nameMatch || subTypeMatch;
    })/* Your logic to update the filteredLogos array based on searchQuery */;

    setFilteredLogos(updatedFilteredLogos);

    // Reset the clearAssetGrid state after a short delay
    const timeout = setTimeout(() => {
      setClearAssetGrid(false);
    }, 100);

    // Clear the timeout when the component is unmounted or when the searchQuery changes again
    return () => clearTimeout(timeout);
  }, [searchQuery, allLogos]);
  
  const handleCheckboxChange = () => {
    setFlipLogo((prevShowChestLogo) => !prevShowChestLogo);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
 
  const openColorPicker = (color) => {
    setColorPickerOpen(true);
  };
  
  const renderModalContent = () => {
      return (
        <div className="modal-content">
      <div className="modal-content-ontop">
    <button onClick={closeModal} className="modal-close-button">
      Close
    </button>
    <input
        className="modal-search-bar"
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      </div>
      <div className="asset-grid" style={{ marginTop: '30px' }}>
      {clearAssetGrid ? null : (
filteredLogos.map((asset) => {
      console.log(filteredLogos)
      const isAssetSelected = logoUrl === asset.imageUrl;
      return (
        <div
          key={asset.id}
          className={`modal-item${isAssetSelected ? ' selected' : ''}`}
          onClick={() => changeUrlManual(asset.imageUrl)}
        >
          {asset.previewUrl ? (
            <img src={asset.previewUrl} alt={asset.name} className="asset-image" />
          ) : (
            <img src={asset.imageUrl} alt={asset.name} className="asset-image" />
          )}
          <div className="asset-name" style={{ margin: '10px' }}>
            <div>{asset.name}</div>
            <div className="sub-type">{asset.subType}</div>
          </div>
          {isAssetSelected && <div className="selected-overlay"></div>}
        </div>
      );
    }))}
  </div>

      </div>
      
      );
  };

  const openColorPickerSecondary = (color) => {
    setColorPickerSecondaryOpen(true);
  };

  const updateAssetColor = (oldColor, newColor) => {
    if(numberColor === oldColor){
      setNumberColor(newColor)
    }
    
    const updatedAssets = assets.map((asset) => {
      if (asset.color === oldColor) {
        return { ...asset, color: newColor };
      }
      return asset;
    });
  
    // Set the updated assets back to the state or perform any necessary action
    setAssets(updatedAssets);

    const updatedPantAssets = pantAssets.map((asset) => {
      if (asset.color === oldColor) {
        return { ...asset, color: newColor };
      }
      return asset;
    });
  
    // Set the updated assets back to the state or perform any necessary action
    setPantAssets(updatedPantAssets);

    
    const updatedHelmetAssets = helmetAssets.map((asset) => {
      if (asset.color === oldColor) {
        return { ...asset, color: newColor };
      }
      return asset;
    });
  
    // Set the updated assets back to the state or perform any necessary action
    setHelmetAssets(updatedHelmetAssets);

    const updatedSockAssets = sockAssets.map((asset) => {
      if (asset.color === oldColor) {
        return { ...asset, color: newColor };
      }
      return asset;
    });
  
    // Set the updated assets back to the state or perform any necessary action
    setSockAssets(updatedSockAssets);
  };

  const flipColors = async () => {
    console.log('flipping')
    const temp = primaryColor;
    setPrimaryColor(secondaryColor);
    setSecondaryColor(temp);

    if(numberColor === primaryColor){
      setNumberColor(secondaryColor)
    }
    if(numberColor === secondaryColor){
      setNumberColor(primaryColor)
    }

      const updatedAssets = assets.map((asset) => {
        if (asset.color === primaryColor) {
          return { ...asset, color: secondaryColor };
        }else if (asset.color === secondaryColor){
          return { ...asset, color: primaryColor };
        }
        return asset;
      });
    
      // Set the updated assets back to the state or perform any necessary action
      setAssets(updatedAssets);
  
      const updatedPantAssets = pantAssets.map((asset) => {
        if (asset.color === primaryColor) {
          return { ...asset, color: secondaryColor };
        }else if (asset.color === secondaryColor){
          return { ...asset, color: primaryColor };
        }
        return asset;
      });
    
      // Set the updated assets back to the state or perform any necessary action
      setPantAssets(updatedPantAssets);
  
      
      const updatedHelmetAssets = helmetAssets.map((asset) => {
        if (asset.color === primaryColor) {
          return { ...asset, color: secondaryColor };
        }else if (asset.color === secondaryColor){
          return { ...asset, color: primaryColor };
        }
        return asset;
      });
    
      // Set the updated assets back to the state or perform any necessary action
      setHelmetAssets(updatedHelmetAssets);
  
      const updatedSockAssets = sockAssets.map((asset) => {
        if (asset.color === primaryColor) {
          return { ...asset, color: secondaryColor };
        }else if (asset.color === secondaryColor){
          return { ...asset, color: primaryColor };
        }
        return asset;
      });
    
      // Set the updated assets back to the state or perform any necessary action
      setSockAssets(updatedSockAssets);
  };


  return (
    <div className="options-container">
      {tabOpen && <>
        
      <div className="tab-data">
     

      { <h3 style={{fontSize: '20px', textTransform: 'uppercase', marginLeft:'2px', marginBottom:'30px'}}>{title}</h3>}

      <input
    className='brand-text-input'
      type="text"
      value={cityName}
      onChange={(event) => setCityName(event.target.value)}
      placeholder="Enter city name"
      style={{marginLeft:'10px'}}
    />
            <div className='sub-type' style={{marginBottom:'10px', padding:'10px', borderBottom: '1px solid #ccc'}} >
                City
              </div>

      <input
      className='brand-text-input'
      type="text"
      value={teamName}
      onChange={(event) => setTeamName(event.target.value)}
      placeholder="Enter team name"
      style={{marginLeft:'10px'}}
    />
            <div className='sub-type' style={{marginBottom:'10px',  padding:'10px', borderBottom: '1px solid #ccc'}} >
              Team Name
              </div>
              <div className="color-square" onClick={() => openColorPicker(primaryColor)} style={{ cursor:'pointer',background: primaryColor, borderRadius: '4px', border: '1px solid black', height: '30px', width: '98%', marginRight: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', fontWeight: 'bold'}}>
                {primaryColor}
              </div>
              <div className='sub-type' style={{marginBottom:'10px', padding:'10px', borderBottom: '1px solid #ccc'}} >
                Primary Color
              </div>
              <div className="color-square" onClick={() => openColorPickerSecondary( secondaryColor)}style={{ cursor:'pointer',background: secondaryColor, borderRadius: '4px', border: '1px solid black', height: '30px', width: '98%', marginRight: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', fontWeight: 'bold'}}>
                {secondaryColor}
              </div>
              <div className='sub-type' style={{marginBottom:'10px', padding:'10px', borderBottom: '1px solid #ccc'}} >
                Secondary Color
              </div>
              <button className='font-button' onClick={(()=>{

                flipColors()

              })}>
                 <AiOutlineSwap/> Swap Primary & Secondary Colors
              </button>
              <div className='sub-type' style={{marginBottom:'10px', padding:'10px', borderBottom: '1px solid #ccc'}} >
              </div>

      <div className="" >
            <button
              className="font-button"
              onClick={() => openModal()}
            >
              <BsFillShieldFill/> Select Premade Logos
            </button>
          </div>
          <div className={`asset-item`} style={{ display: 'flex' }}>
          <input
            type="checkbox"
            checked={flipLogo}
            onChange={handleCheckboxChange}
            className='checkbox'
          />
          <div style={{ marginLeft: '10px', color: 'rgba(0,0,0,0.75)', fontSize: '13px' }}>
            Flip LOGO on other side
          </div>
        </div>

      <div className="custom-file-upload" style={{marginLeft:'10px'}}>

  <div style={{marginLeft:'5px',paddingTop:'5px'}}> <b>UPLOAD LOGO BETA</b></div>
  <div style={{marginBottom:'20px', marginLeft:'5px'}}>USE A 1080x1080 LOGO FOR BEST RESULTS</div>
  <input
    type="file"
    accept="image/png"
    id="fileInput"
    onChange={setLogoUrl}
    className="hidden-input"
  />
  <label htmlFor="fileInput" className="custom-button" style={{borderRadius:'5px'}}>
  <BsFillShieldFill/> Upload Logo
  </label>


</div>

</div>
{isColorPickerOpen && (
        <div className="color-picker-modal">
  <div className="color-picker-container">
      <div className="chrome-picker-wrapper">
        <ChromePicker color={primaryColor} onChange={(color) => {
          updateAssetColor(primaryColor, color.hex);
          setPrimaryColor(color.hex);
        }} />
      </div>
    
      <div className="circle-picker-wrapper">
        <h3>Basic Colors</h3>
        <CirclePicker
 colors={[
  '#2a1ad4',
  '#cb9e6a',
  '#1d7143',
  '#e72c1a',
  '#ae2d33',
  '#273d7f',
  '#18191b',
  '#f5f5f4',
  '#849ca8',
  '#f3ca21',
  '#5a75b2',
  '#512c7d',
  '#b62729',
  '#ed6021',
  '#e8a632',
  '#d22524',
  '#9f613e',
  '#1b1b1b',
]}        onChange={(color) => {
          updateAssetColor(primaryColor, color.hex);
          setPrimaryColor(color.hex);
        }} />
      </div>
    <button className="color-picker-close-button" onClick={() => setColorPickerOpen(false)}>Close</button>

  </div>
</div>

      )}

{isColorPickerSecondaryOpen && (
        <div className="color-picker-modal">
  <div className="color-picker-container">
      <div className="chrome-picker-wrapper">
        <ChromePicker color={secondaryColor} onChange={(color) => {
           updateAssetColor(secondaryColor, color.hex);
          setSecondaryColor(color.hex);
        }} />
      </div>
    
      <div className="circle-picker-wrapper">
        <h3>Basic Colors</h3>
        <CirclePicker
 colors={[
  '#2a1ad4',
  '#cb9e6a',
  '#1d7143',
  '#e72c1a',
  '#ae2d33',
  '#273d7f',
  '#18191b',
  '#f5f5f4',
  '#849ca8',
  '#f3ca21',
  '#5a75b2',
  '#512c7d',
  '#b62729',
  '#ed6021',
  '#e8a632',
  '#d22524',
  '#9f613e',
  '#1b1b1b',
]}        onChange={(color) => {
        updateAssetColor(secondaryColor, color.hex);
          setSecondaryColor(color.hex);
        }} />
      </div>
    <button className="color-picker-close-button" onClick={() => setColorPickerSecondaryOpen(false)}>Close</button>

  </div>
</div>

      )}
</>
}

{isModalOpen && (
        <div className="modal">
          {renderModalContent()} {/* Render the modal content */}
        </div>
      )}
</div>
  );
}


export default BrandOptions;
