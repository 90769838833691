import React, { useState } from 'react';
import './AssetOptions.css';
import { CirclePicker } from 'react-color';
import { ChromePicker } from 'react-color';

function NumberOptions({tabOpen, numberFont, setNumberFont, allFonts, numberColor, setNumberColor, primaryColor, secondaryColor, showNumber, setShowNumber}) {
    const [isColorPickerOpen, setColorPickerOpen] = useState(false);
    const [inputValue, setInputValue] = useState(showNumber);

    const handleChange = (event) => {
        const inputValue = event.target.value;
        
        // Validate the input value
        if (/^\d{0,2}$/.test(inputValue) && parseInt(inputValue, 10) <= 99) {
          setInputValue(inputValue);
        }
      };
    
      const handleSubmit = (event) => {
        event.preventDefault();
        const newFontSize = parseInt(inputValue);
        if (!isNaN(newFontSize)) {
            setShowNumber(newFontSize);
        }
      };

    const openColorPicker = (color) => {
        setColorPickerOpen(true);
      };

    const Dropdown = ({ allFonts }) => {
        const handleFontChange = (event) => {
            setNumberFont(event.target.value);
        };
        const fontName = '012345689 ' + numberFont.substring(
            numberFont.lastIndexOf('/') + 1,
            numberFont.indexOf('.')
        ).replace(/_/g, ' ');
        return (
          <div>
            <select value={fontName} onChange={handleFontChange} className="custom-select" style={{ fontFamily: "NumberFont", color: 'purple', fontSize: '30px'}}>
              <option value="">{fontName}</option>
              {allFonts
                .filter((font) => font !== fontName)
                .map((font, index) => {
                  const fontNameStyle = `CustomFont${index}`;
                  const customFont = new FontFace(fontNameStyle, `url(${font})`);
                  customFont.load().then(() => {
                    document.fonts.add(customFont);
                  });
        
                  return (
                    <option key={index} value={font} style={{ fontFamily: fontNameStyle, color: 'purple', fontSize: '30px'}}>
                      0123456789 {font
                        .substring(font.lastIndexOf('/') + 1, font.indexOf('.'))
                        .replace(/_/g, ' ')}
                    </option>
                  );
                })}
            </select>
          </div>
        );  
      
      };

      if(!tabOpen){return null}
  return (
    <div className="options-container">
              { <h3 style={{fontSize: '20px', textTransform: 'uppercase', marginLeft:'2px', marginBottom:'20px'}}>NUMBERS</h3>}

          <Dropdown allFonts={allFonts} />
          <div className='sub-type'>
              Font
              </div>
          <div style={{borderBottom: '1px solid #E2E8F0', backgroundColor:'#F5F7FA', marginTop:'15px', marginBottom: '10px', width:'104%'}}/>

          <div className="color-square" onClick={() => openColorPicker(numberColor)} style={{ cursor:'pointer',background: numberColor, borderRadius: '4px', border: '1px solid black', height: '30px', width: '98%', marginRight: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', fontWeight: 'bold'}}>
                {numberColor}
              </div>
              <div className='sub-type'>
              Color
              </div>
              <div style={{borderBottom: '1px solid #E2E8F0', backgroundColor:'#F5F7FA', marginBottom: '10px', marginTop:'15px', width:'104%'}}/>

              <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center' }}>
  <input
    className='font-input'
    type="number"
    placeholder={showNumber}
    value={inputValue}
    onChange={handleChange}
  />
  <button type="submit" className="font-button" style={{backgroundColor:'purple'}}>Apply</button>
</form>
<div className='sub-type'>
              Show #
              </div>
<div style={{borderBottom: '1px solid #E2E8F0', backgroundColor:'#F5F7FA', marginBottom: '10px', marginTop:'15px', width:'104%'}}/>


              {isColorPickerOpen && (
        <div className="color-picker-modal">
  <div className="color-picker-container">
      <div className="chrome-picker-wrapper">
        <ChromePicker color={numberColor} onChange={(color) => {
          setNumberColor(color.hex);
        }} />
      </div>
    
      <div className="circle-picker-wrapper">
        <h3>Team Colors</h3>
        <CirclePicker
 colors={[
    primaryColor, secondaryColor,
    '#F3F3F3', // White
     '#1A1A1A', // Black
   
  ]}        onChange={(color) => {
    setNumberColor(color.hex);
        }} />
      </div>
    <button className="color-picker-close-button" onClick={() => setColorPickerOpen(false)}>Close</button>

  </div>
</div>

      )}
    </div>
  );
}


export default NumberOptions;
