import React, { useState, useEffect  } from 'react';
import ProspectTableRow from './ProspectTableRow';
import StickyHeader from './StickyHeader';

const DraftProspectList = ({ bigBoard, leagueData }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [viewOption, setViewOption] = useState('large');
  const [favoritesOnly, setFavoritesOnly] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState('ALL');
  const [selectedArchetype, setSelectedArchetype] = useState('ALL');

  const filteredBigBoardToChange = bigBoard.filter((prospect) => {
    const lowercaseQuery = searchQuery.toLowerCase();
    const lowercasePosQuery = selectedPosition.toLowerCase();
    const lowercaseArchQuery = selectedArchetype.toLowerCase();

    if (lowercasePosQuery== 'edge'){
      return (
        (prospect.playerType.toLowerCase().includes('rusher')||
        prospect.position.toLowerCase().includes('re') || 
        prospect.position.toLowerCase().includes('le')) &&
        (prospect.name.toLowerCase().includes(lowercaseQuery) ||
        prospect.college.toLowerCase().includes(lowercaseQuery)
        ) && (
          ((lowercaseArchQuery==='all') && true) ||
          prospect.playerType.toLowerCase().includes(lowercaseArchQuery))
      );
    }
    if (lowercasePosQuery== 'ol'){
      return (
        (
        prospect.position.toLowerCase().includes('lt') ||
        prospect.position.toLowerCase().includes('lg') ||
        prospect.position.toLowerCase() === ('c') ||
        prospect.position.toLowerCase().includes('rg') ||
        prospect.position.toLowerCase().includes('rt')) &&
        (prospect.name.toLowerCase().includes(lowercaseQuery) ||
        prospect.college.toLowerCase().includes(lowercaseQuery))
        &&
        ((lowercaseArchQuery==='ol_power') && (prospect.playerType.toLowerCase().includes("ot_power") || prospect.playerType.toLowerCase().includes("g_power") || prospect.playerType.toLowerCase().includes("c_power")) ||
        (lowercaseArchQuery==='ol_agile') && (prospect.playerType.toLowerCase().includes("ot_agile") || prospect.playerType.toLowerCase().includes("g_agile") || prospect.playerType.toLowerCase().includes("c_agile")) ||
        (lowercaseArchQuery==='passprotector') && prospect.playerType.toLowerCase().includes("passprotector") || 
        (lowercaseArchQuery==='all') && true)
        
      );
    }
    if (lowercasePosQuery== 'iol'){
      return (
        (
        prospect.position.toLowerCase().includes('lg') ||
        prospect.position.toLowerCase() === ('c') ||
        prospect.position.toLowerCase().includes('rg')) &&
        (prospect.name.toLowerCase().includes(lowercaseQuery) ||
        prospect.college.toLowerCase().includes(lowercaseQuery)) &&
        ((lowercaseArchQuery==='ol_power') && (prospect.playerType.toLowerCase().includes("g_power") || prospect.playerType.toLowerCase().includes("c_power")) ||
        (lowercaseArchQuery==='ol_agile') && (prospect.playerType.toLowerCase().includes("g_agile") || prospect.playerType.toLowerCase().includes("c_agile")) ||
        (lowercaseArchQuery==='passprotector') &&prospect.playerType.toLowerCase().includes("passprotector") ||
        (lowercaseArchQuery==='all') && true)
      );
    }
    if (lowercasePosQuery== 'ot'){
      return (
        (
        prospect.position.toLowerCase().includes('lt') ||
        prospect.position.toLowerCase().includes('rt')) &&
        (prospect.name.toLowerCase().includes(lowercaseQuery) ||
        prospect.college.toLowerCase().includes(lowercaseQuery)) &&
        ((lowercaseArchQuery==='ol_power') && (prospect.playerType.toLowerCase().includes("ot_power"))||
        (lowercaseArchQuery==='ol_agile') && (prospect.playerType.toLowerCase().includes("ot_agile")) ||
        (lowercaseArchQuery==='passprotector') &&prospect.playerType.toLowerCase().includes("passprotector") ||
        (lowercaseArchQuery==='all') && true)
      );
    }
    if (lowercasePosQuery== 'dl'){
      return (
        (prospect.position.toLowerCase().includes('dt')||
        prospect.position.toLowerCase().includes('re') || 
        prospect.position.toLowerCase().includes('le')) &&
        (prospect.name.toLowerCase().includes(lowercaseQuery) ||
        prospect.college.toLowerCase().includes(lowercaseQuery)||
        prospect.playerType.toLowerCase().includes(lowercaseArchQuery)
        ) && (
          ((lowercaseArchQuery==='all') && true) ||
          prospect.playerType.toLowerCase().includes(lowercaseArchQuery))
      );
    }
    if (lowercasePosQuery== 'lb'){
      return (
        (prospect.position.toLowerCase().includes('rolb')||
        prospect.position.toLowerCase().includes('lolb') || 
        prospect.position.toLowerCase().includes('mlb')) &&
        !prospect.playerType.toLowerCase().includes('rusher') && 
        (prospect.name.toLowerCase().includes(lowercaseQuery) ||
        prospect.college.toLowerCase().includes(lowercaseQuery)||
        prospect.playerType.toLowerCase().includes(lowercaseArchQuery)
        ) && (
          ((lowercaseArchQuery==='all') && true) ||
          prospect.playerType.toLowerCase().includes(lowercaseArchQuery))
      );
    }
    if (lowercasePosQuery== 'db'){
      return (
        (prospect.position.toLowerCase().includes('cb')||
        prospect.position.toLowerCase().includes('fs') || 
        prospect.position.toLowerCase().includes('ss')) &&
        (prospect.name.toLowerCase().includes(lowercaseQuery) ||
        prospect.college.toLowerCase().includes(lowercaseQuery)||
        prospect.playerType.toLowerCase().includes(lowercaseArchQuery)
        ) && (
          ((lowercaseArchQuery==='all') && true) ||
          prospect.playerType.toLowerCase().includes(lowercaseArchQuery))
      );
    }
    if (lowercasePosQuery== 's'){
      return (
        (
        prospect.position.toLowerCase().includes('fs') || 
        prospect.position.toLowerCase().includes('ss')) &&
        (prospect.name.toLowerCase().includes(lowercaseQuery) ||
        prospect.college.toLowerCase().includes(lowercaseQuery)||
        prospect.playerType.toLowerCase().includes(lowercaseArchQuery)
        ) && (
          ((lowercaseArchQuery==='all') && true) ||
          prospect.playerType.toLowerCase().includes(lowercaseArchQuery))
      );
    }
    
    return (
      (lowercasePosQuery!=='all' && prospect.position.toLowerCase().includes(lowercasePosQuery) || lowercasePosQuery=='all')
      &&
      (lowercaseArchQuery!=='all' && prospect.playerType.toLowerCase().includes(lowercaseArchQuery) || lowercaseArchQuery=='all')
      &&
      (prospect.name.toLowerCase().includes(lowercaseQuery) ||
      prospect.college.toLowerCase().includes(lowercaseQuery))
    );
  });
  const [filteredBigBoard, setfilteredBigBoard] =  useState(filteredBigBoardToChange);

  useEffect(() => {
    // Update filteredBigBoard when filteredBigBoardToChange changes
    setfilteredBigBoard(filteredBigBoardToChange);
  }, [searchQuery,selectedPosition,selectedArchetype, bigBoard]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
 
  const handleViewOptionChange = (option) => {
    setViewOption(option);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
      <StickyHeader onSearchChange={handleSearchChange} viewOption={viewOption} onViewOptionChange={handleViewOptionChange} favoritesOnly={favoritesOnly} setFavoritesOnly={setFavoritesOnly} selectedPosition={selectedPosition} onPositionChange={setSelectedPosition} selectedArchetype={selectedArchetype} onArchetypeChange={setSelectedArchetype}/>
      <div style={{ width: '870px' }}>
        {filteredBigBoard.map((prospect, index) => (
          <ProspectTableRow key={index} prospect={prospect} index={index} viewOption={viewOption} isPopup={false} uniqueId={leagueData} favoritesOnly={favoritesOnly} progress={0} selectedArchetype={selectedArchetype}/>
        ))}
      </div>
    </div>
  );
};

export default DraftProspectList;
