import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';


const SpiderChart= ({ ratings, width, height }) => {
  const chartRef = useRef(null);
const axisLabel =  {
  fill: "black"
}

  useEffect(() => {
    const chartContainer = d3.select(chartRef.current);

    const margin = { top: 50, right: 50, bottom: 50, left: 50 };
    const chartWidth = width - margin.left - margin.right;
    const chartHeight = height - margin.top - margin.bottom;
    const radius = Math.min(chartWidth, chartHeight) / 2;

    const color = d3.scaleOrdinal().range([d3.rgb('#cad613')]);

    const maxValue = Math.max(...ratings.map((d) => d.value));
    const ticks = 5;
    const tickValues = d3.range(ticks).map(i => Math.ceil(maxValue / (ticks - 1) * i));

    const angleSlice = Math.PI * 2 / ratings.length;
    const angleScale = d3.scaleLinear()
      .range([0, Math.PI * 2])
      .domain([0, ratings.length]);

    const radiusScale = d3.scaleLinear()
      .range([0, radius])
      .domain([0, maxValue]);

    const line = d3.lineRadial()
      .angle((d, i) => angleScale(i))
      .radius((d )=> radiusScale(d.value))
      .curve(d3.curveLinearClosed);

    const area = d3.areaRadial()
      .angle((d, i) => angleScale(i))
      .innerRadius(radiusScale(0))
      .outerRadius((d) => radiusScale(d.value))
      .curve(d3.curveLinearClosed);

    const axes = chartContainer.append('g')
      .classed('axes', true)
      .attr('transform', `translate(${margin.left + chartWidth / 2}, ${margin.top + chartHeight / 2})`)
      .selectAll('g')
      .data(ratings)
      .enter()
      .append('g');

    axes.append('line')
      .classed('axis-line', true)
      .attr('x1', 0)
      .attr('y1', 0)
      .attr('x2', (d, i) => radius * Math.cos(angleSlice * i - Math.PI / 2))
      .attr('y2', (d, i) => radius * Math.sin(angleSlice * i - Math.PI / 2));

    axes.append('text')
      .classed('axis-label', true)
      .attr('x', (d, i) => (radius * 1.4 * Math.cos(angleSlice * i - Math.PI / 2)-40))
      .attr('y', (d, i) => radius * 1.2 * Math.sin(angleSlice * i - Math.PI / 2))
      .text((d) => d.label)
      .attr("class", axisLabel); // add the axisLabel class to the text element

    const paths = chartContainer.append('g')
      .classed('paths', true)
      .attr('transform', `translate(${margin.left + chartWidth / 2}, ${margin.top + chartHeight / 2})`)
      .selectAll('path')
      .data([ratings])
      .enter()
      .append('path')
      .classed('area', true)
      .attr('d', area)
      .style('fill', (d, i) => color(i.toString()))
      .style('opacity', 0.5);

    const linePath = chartContainer.append('g')
      .classed('line-path', true)
      .attr('transform', `translate(${margin.left + chartWidth / 2}, ${margin.top + chartHeight / 2})`)
      .append('path')
      .datum(ratings)
      .classed('line', true)
      .attr('d', line)
      .style('stroke', 'rgba(255,255,255,0.1)')
      .style('stroke-width', 2)
      .style('fill', 'none');

      const ticksContainer = chartContainer.append('g')
  .classed('ticks', true)
  .attr('transform', `translate(${margin.left + chartWidth / 2}, ${margin.top + chartHeight / 2})`);

ticksContainer.selectAll('circle')
  .data(tickValues)
  .enter()
  .append('circle')
  .classed('tick-circle', true)
  .attr('cx', 0)
  .attr('cy', 0)
  .attr('r', d => radiusScale(d))
  .style('fill', 'none')
  .style('stroke', 'rgba(0,0,0,0.15)')
  .style('stroke-width', 1);

// ticksContainer.selectAll('text')
//   .data(tickValues)
//   .enter()
//   .append('text')
//   .classed('tick-label', true)
//   .attr('x', 0)
//   .attr('y', d => -radiusScale(d))
//   .attr('dy', -4)
//   .text(d => d)
//   .style('stroke', 'rgba(255,255,255,1)');

}, [ratings, width, height]);

return (
<svg ref={chartRef} width={width} height={height}>
<g />
</svg>
);
};

export default SpiderChart;
