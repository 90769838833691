import React, { useState } from 'react';
import axios from 'axios';

const LoginForm = ({ setAuthenticated, setUserProfile}) => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('http://localhost:8000/api/auth/login/', formData);

      console.log(response.data.message, response.data.username); // Display success message
      setUserProfile(response.data.user_profile)
      console.log(response.data.user_profile)
      // Call the parent component's setAuthenticated function to update the state
      setAuthenticated(true);
    } catch (error) {
      console.error('Login failed:', error.response.data.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Username:
        <input type="text" name="username" value={formData.username} onChange={handleChange} />
      </label>
      <br />
      <label>
        Password:
        <input type="password" name="password" value={formData.password} onChange={handleChange} />
      </label>
      <br />
      <button type="submit">Login</button>
    </form>
  );
};

export default LoginForm;
