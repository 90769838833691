import React from 'react';

const ScoutStickyHeader = () => {
  return (
    <div style={{ backgroundColor: '#f0f0f0', padding: '10px', position: 'sticky', top: '0', zIndex: '100'}}>
        Front Office
  </div>
  );
};

export default ScoutStickyHeader;
