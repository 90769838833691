import React from 'react';
import { GiWeightLiftingUp } from "react-icons/gi";
import { BiRun } from "react-icons/bi";
import { MdLocalPolice } from "react-icons/md";
import { ImPowerCord } from "react-icons/im";
import { FaPowerOff } from "react-icons/fa";
import { IoColorFilter } from "react-icons/io5";
import { BsFillLightningFill } from "react-icons/bs";
import { FaBowlingBall } from "react-icons/fa";
import { GiPoliceOfficerHead } from "react-icons/gi";
import { FaLock } from "react-icons/fa";
import { IoIosWifi } from "react-icons/io";
import { CiCoinInsert } from "react-icons/ci";
import { FaGamepad } from "react-icons/fa";
import { FaBomb } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import { GiArcheryTarget } from "react-icons/gi";
import { IoCompassSharp } from "react-icons/io5";
import { GiJumpAcross } from "react-icons/gi";
import { SiAdblock } from "react-icons/si";
import { FaHandHoldingHand } from "react-icons/fa6";
import { RiAlarmWarningFill } from "react-icons/ri";
import { GiWreckingBall } from "react-icons/gi";
import { FaMitten } from "react-icons/fa";
import { BsCone } from "react-icons/bs";
import { MdEggAlt } from "react-icons/md";
import { IoIosConstruct } from "react-icons/io";

const Archetype = ({ prospect }) => {
    let arch = prospect.playerType.substring(prospect.playerType.indexOf('_') + 1).replace(/([A-Z])/g, ' $1').trim()
    let icon;

    switch(arch.toLowerCase()){
        case "strong arm":
            icon = <GiWeightLiftingUp />;
            break;
        case "field general":
            icon = <MdLocalPolice />
            break;
        case "power rusher":
            icon = <ImPowerCord />
            break; 
        case "power":
            icon = <FaPowerOff />
            break;
        case "hybrid":
            icon = <IoColorFilter />;
            break;
        case "speed rusher":
            icon = <BsFillLightningFill />
            break;
        case "smaller speed rusher":
            icon = <BsFillLightningFill />
            break;
        case "run stopper":
            icon = <FaBowlingBall />
            break; 
        case "field general":
            icon = <GiPoliceOfficerHead />
            break;
        case "zone":
            icon = <IoIosWifi />
            break;
        case "man to man":
            icon = <FaLock />
            break;
        case "slot":
            icon = <CiCoinInsert />
            break;
        case "playmaker":
            icon = <FaGamepad />
            break;   
        case "deep threat":
            icon = <IoIosWarning />
            break;   
        case "physical":
            icon = <FaBomb />
            break;  
        case "accuracy":
            icon = <GiArcheryTarget />
            break;   
        case "pass coverage":
            icon = <IoCompassSharp />
            break;
        case "blocking":
            icon = <SiAdblock />
            break; 
        case "vertical threat":
            icon = <GiJumpAcross />
            break; 
        case "possesion":
            icon = <FaHandHoldingHand />
            break; 
        case "pass protecter":
            icon = <RiAlarmWarningFill />
            break;
        case "elusive back":
            icon = <BsCone />
            break; 
        case "recieving back":
            icon = <FaMitten />
            break; 
        case "power back":
            icon = <GiWreckingBall />
            break; 
        case "scrambler":
            icon = <MdEggAlt />
            break;
        case "utility":
            icon = <IoIosConstruct />
            break;
        case "run support":
            icon = <GiWreckingBall />
            break; 
        default:
            //agile
            icon = <BiRun />;
            break;
    }
    
    return (
    <div style={{marginLeft: '12px', fontSize: '15px', display:'flex', marginTop:'1px'}}><div style={{marginRight:'5px', marginTop:'1px'}}>{icon}</div>{arch}</div>
  );
};

export default Archetype;


