import React, { useState, useEffect } from 'react';
import chroma from "chroma-js";
import Anton from '../Fonts/Microsport.ttf';
// import Microsport from './Fonts/Microsport.ttf';
import { FaAngleUp, FaAngleDown, FaAngleDoubleUp, FaAngleDoubleDown, FaGripLines, FaCrown   } from "react-icons/fa";

function PowerRankings() {
  const [rankings, setRankings] = useState([]);
  const [teamColors, setTeamColors] = useState({});
  
  const customFont = new FontFace('Anton', `url(${Anton}`);
  document.fonts.add(customFont);

  useEffect(() => {
    fetch('https://neonsportz.com/api/leagues/PFL/rankings/?size=0&page=1&ordering=rank')
      .then(response => response.json())
      .then(data => {
        setRankings(data);
        fetchTeamColors(data);
        console.log(data)
      })
      .catch(error => console.error('Error fetching rankings:', error));
  }, []);

  const fetchTeamColors = (data) => {
    const colors = {};
    const promises = data.map(ranking => {
      return fetch(`https://neonsportz.com/api/leagues/PFL/teams/${ranking.team.abbrName}`)
        .then(response => response.json())
        .then(teamData => {
          colors[ranking.team.abbrName] = {
            primaryColor: teamData.primaryColor,
            secondaryColor: teamData.secondaryColor
          };
        })
        .catch(error => console.error(`Error fetching team colors for ${ranking.team.abbrName}:`, error));
    });
    
    Promise.all(promises).then(() => {
      setTeamColors(colors);
    });
  };

  const rankingCard = {
    // border: '1px solid rgba(155,155,155,0.5)',
    borderRadius: '1px',
    margin: '4px',
    // padding: '10px',
    // height: '50px',
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <div style={{width: '1040px', height: '700px', position: 'absolute',marginTop:'70px', zIndex: -1}}><img src='../Images/prbackground.png' style={{}}/></div>
    <div style={{width: '1040px', height: '700px', position: 'absolute',marginTop:'70px', zIndex: -2, backgroundColor: '#25272a'}}></div>

    <div style={{width: '1040px', height: '700px', border: '10px solid #25272a', marginTop: '70px', zIndex: 1}}>
    {rankings[0] && <h2 style={{color: 'white', font: '200% Anton', width: '1040px', textAlign: 'center', paddingTop: '20px'}}>PFL SZN {rankings[0].seasonIndex + 1} WEEK {rankings[0].weekIndex + 1} POWER RANKINGS</h2>}      <div className="tiles" style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', padding: '10px'}}>
        {rankings.map((ranking, index) => {
          let change = ranking.rank-ranking.prevRank
          return <div key={index} className="tile" style={{...rankingCard, backgroundColor: teamColors[ranking.team.abbrName]?getColors(teamColors[ranking.team.abbrName], ranking.team.displayName).primaryColor:'', color: 'white', borderBottom: teamColors[ranking.team.abbrName]?`2px solid ${getColors(teamColors[ranking.team.abbrName], ranking.team.displayName).secondaryColor}`:'2px solid white'}}>
            <div style={{display:'flex', overflow: 'hidden'}}>
            <div style={{ position: 'relative', overflow: 'hidden', width: '300px', height: '55px', marginRight: '-300px' }}>
                <div style={{ zIndex: 2,  position: 'absolute', top: '40%', left: '75%', transform: 'translate(-100%, -90%) rotate(-25deg)', backgroundColor: teamColors[ranking.team.abbrName]?getColors(teamColors[ranking.team.abbrName], ranking.team.displayName).darkerPrimaryColor+'96':'', width: '300px', height: '100px', clipPath: 'polygon(0 0, 100% 0%, 85% 100%, 0% 100%)' }} />
            </div>

            <div style={{zIndex: 3, display: 'flex'}}>
                <div style={{paddingLeft: '10px', width: '40px', zIndex: 2}}>
                <h2 style={{ color: 'white', fontSize: '200%', marginTop: '5px', marginBottom: '-30px', textShadow: `1px 1px 0 ${teamColors[ranking.team.abbrName]?getColors(teamColors[ranking.team.abbrName], ranking.team.displayName).secondaryColor:''}` }}>{ranking.rank}</h2>
                </div>
                <div style={{zIndex: 1, width: '100px', marginLeft:'-75px', marginRight: '115px'}}>
                    <img style={{opacity: '50%', width: '175px',  marginBottom:'-35px', borderRadius: 0, marginLeft: '-5px'}} src={`https://cdn.neonsportz.com/teamlogos/256/${ranking.team.logoId}.png`} alt={ranking.team.teamName} className="team-logo" />
                </div>
                {ranking.team.leaguemember && (
                    <div style={{display: 'flex', color: 'white' ,  fontSize: '100%', position: 'absolute', width:'240px', justifyContent:'right', height: '30px', marginTop: '-7.5px'}}>
                        <img style={{ marginTop: '11px', width: '15px',height: '15px',  borderRadius: '10rem', marginRight: '2.5px'}} src={ranking.team.leaguemember.user.avatar_url} alt={`${ranking.team.displayName} Avatar`} />
                        <p style={{fontSize: '75%'}}>{ranking.team.leaguemember.user.username}</p>
                    </div>
                )}
                <h2 style={{color: 'white' ,  fontSize: '100%', width:'145px', marginLeft: '-95px', textAlign: 'right', marginTop: '20px', marginBottom:'-30px'}}>{ranking.team.displayName}</h2>
                <i style={{color: teamColors[ranking.team.abbrName]?getColors(teamColors[ranking.team.abbrName], ranking.team.displayName).secondaryColor:'' ,  fontSize: '50%', width:'150px', textAlign: 'right', marginTop: '42.5px', marginBottom:'-30px', marginLeft: '-150px'}}>{ranking.team.cityName}</i>
            </div>
            <div style={{ color: 'white', zIndex: 4, position:'fixed', marginLeft: ranking.rank<10?'37.5px':'55px', fontSize: '25px', marginTop: '17.5px' }}>
              {change<=-2?<FaAngleDoubleUp style={{}}/>:
              change>=2?<FaAngleDoubleDown/>:
              change==-1?<FaAngleUp/>:
              change==1?<FaAngleDown/>:
              change==0&&ranking.rank==1?<FaCrown />:
              <FaGripLines/>}
            </div>
          </div>
          </div>
        })}
      </div>
      </div>
    </div>
  );
}

export default PowerRankings;


const getColors = (teamInfo, displayName) => {
    let primaryColor = teamInfo.primaryColor ? '#' + parseInt(teamInfo.primaryColor).toString(16).padStart(6, '0') : '#000000';
    let secondaryColor = teamInfo.secondaryColor ? '#' + parseInt(teamInfo.secondaryColor).toString(16).padStart(6, '0') : primaryColor;
    
    const hueDistance = chroma.distance(primaryColor, secondaryColor, 'hsl');

    if (hueDistance < 30) {
        secondaryColor = '#ffffff';
    }
    
    if (displayName === "Titans") {
        secondaryColor = '#ff0000';
    }
    if (displayName === "Buccaneers") {
        secondaryColor = '#FF7900';
    }
    if (displayName === "Jaguars" || displayName === "Ravens") {
        secondaryColor = '#D7A22A';
    }
    if (displayName === "Lions") {
        secondaryColor = '#B0B7BC';
    }
    if (displayName === "Saints" || displayName === "Steelers" || displayName === "Raiders") {
        secondaryColor = primaryColor;
        primaryColor = '#2D2D2D'
    }
    if (displayName === "Black Knights" ) {
        primaryColor = '#2D2D2D'
    }
    if (displayName === "Blues" || displayName === "Cowboys" || displayName === "Colts" || displayName === "Condors"  || displayName === "Aviators") {
        secondaryColor = '#ababb0';
    }
    if (displayName === "Thunderbirds") {
        secondaryColor = '#cb7a20';
    }
    if(displayName === "Panthers" || displayName === "Bengals"){
        secondaryColor = '#2D2D2D'
    }
    if( displayName === "Eagles"){
        secondaryColor = "#38df26"
    }
    let darkerPrimaryColor = chroma(primaryColor).darken(1).hex();

    return {'primaryColor': primaryColor, 'secondaryColor': secondaryColor, "darkerPrimaryColor": darkerPrimaryColor}
}