import React from 'react';
import './Graphics.css'

const Graphics = () => {
  // Array of image URLs
  const images = [
    '/Graphics/polaroid/ffxGlobe.png',
    '/Graphics/polaroid/jd.png',
    '/Graphics/polaroid/1.png',
    '/Graphics/polaroid/2024.png',
    '/Graphics/polaroid/gd.png',
    '/Graphics/polaroid/q1.png',
    '/Graphics/polaroid/ffx1.png',
    '/Graphics/polaroid/an.png',
    '/Graphics/polaroid/cd.png',
    '/Graphics/polaroid/fs.png',
    '/Graphics/polaroid/potw.png',
    '/Graphics/polaroid/q2.png',
    '/Graphics/polaroid/ffx2.png',
    '/Graphics/polaroid/wp.png',
    '/Graphics/polaroid/ld.png',
    '/Graphics/polaroid/dhop.png',
    '/Graphics/polaroid/cd2.png',
    '/Graphics/polaroid/q4.png',
    '/Graphics/polaroid/ffx3.png',
    '/Graphics/polaroid/sb.png',
    '/Graphics/polaroid/k9.png',
    '/Graphics/polaroid/an2.png',
    '/Graphics/polaroid/md.png',
    '/Graphics/polaroid/q3.png',

    // Add more image URLs as needed
  ];

  return (
    <div className="graphics-container">
      {/* <h1>GRAPHICS</h1> */}
      <div className="grid">
        {images.map((image, index) => (
          <div className="grid-item" key={index}>
            <img src={image} alt={`alternate ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
    
  );
};

export default Graphics;
