import React, { useState, useEffect } from 'react';
import './Resume.css';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import EmailIcon from '@mui/icons-material/Email';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

const Resume = () => {
  const [showBackToTop, setShowBackToTop] = useState(false);
  
  const handleScroll = () => {
    const resumeDiv = document.querySelector('.resume');
    const scrollTop = resumeDiv.scrollTop;
  
    if (scrollTop > 5) {
      setShowBackToTop(true);
    } else {
      setShowBackToTop(false);
    }
  };
  
  useEffect(() => {
    const resumeDiv = document.querySelector('.resume');
    resumeDiv.addEventListener('scroll', handleScroll);
    return () => {
      resumeDiv.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const resumeDiv = document.querySelector('.resume');

  return (
    <div className="container">
    <div className="sidebar" id="sidebar">
      </div>
    <div className="resume">


         <button
        className={`back-to-top ${showBackToTop ? 'show' : ''}`}
        id="backToTopBtn"
        onClick={() => resumeDiv.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <ArrowCircleUpIcon style={{fontSize:'70px', color:'dodgerblue'}}/>
      </button>
        <header>
        <h1 className='resume-header'>Jacob Feiss</h1>
        <hr className='header-line'/>
        <div className='dropdown-container'>
        <div className='dropdown'>
        <h2 style={{color:'#f1f0e2'}}>Software Engineer</h2>
        <h4 style={{color:'#f1f0e2'}}><EmailIcon style={{fontSize:'15px', marginRight:'5px'}}/>jakefeiss48@gmail.com</h4>
        <h4 style={{color:'#f1f0e2'}}><SmartphoneIcon style={{fontSize:'15px', marginRight:'5px'}}/>(732)778-6553</h4>
        </div>
        </div>
      </header>
      <section className="section">
      <div className='section-title' style={{width:'fit-content'}}>
        <h2>Skills</h2>
        <hr className='sub-header-line'/>
        </div>        
        <h3>Programming Languages</h3>
        <ul className="skills-list">
          <li>TypeScript</li>
          <li>JavaScript</li>
          <li>Java</li>
          <li>C</li>
          <li>C++</li>
          <li>C#</li>
          <li>React.js</li>
          <li>Node.js</li>
          <li>HTML/CSS</li>
          <li>JavaFX</li>
          <li>Three.js</li>
        </ul>
        <h3>Tools</h3>
        <ul className="skills-list">
          <li>VS/VSCode</li>
          <li>IntelliJ</li>
          <li>Eclipse</li>
          <li>Git</li>
          <li>Terminal</li>
          <li>Figma</li>
          <li>Trello</li>
          <li>Jira</li>
          <li>Gradle</li>
          <li>Cypress</li>
        </ul>
      </section>
      <section className="section">
      <div className='section-title' style={{width:'fit-content'}}>
        <h2>Experience</h2>
        <hr className='sub-header-line'/>
        </div>
        <div className="job">
          <h3>Software Engineer Intern</h3>
          <h4>Waters Corporation</h4>
          <p style={{marginTop:'-5px'}}>May 2022 - February 2023</p>
          <ul>
            <li>Developed Cypress testing framework using Cypress and Cucumber</li>
            <li>Collaborated with cross-functional teams in an Agile environment</li>
          </ul>
        </div>
        <div className="job">
          <h3>Graphic Designer</h3>
          <h4>WPI Football</h4>
          <p style={{marginTop:'-5px'}}>January 2020 - May 2023</p>
          <ul>
            <li>Created graphic and video content for use in social media athletic promotion and recruitment</li>
            <li>Delivered full suite of graphics with a consistent brand identity</li>
            <li>Data entry and tracking of recruiting database</li>
          </ul>
        </div>
      </section>
      <section className="section">
      <div className='section-title' style={{width:'fit-content'}}>
        <h2>Education</h2>
        <hr className='sub-header-line'/>
        </div>
        <div className="education">
          <h3>Bachelor of Science in Computer Science</h3>
          <h4>Worcester Polytechnic Institute</h4>
          <p style={{marginTop:'-5px'}}>Graduated in May 2023</p>
        </div>
      </section>
      <section className="section">
      <div className='section-title' style={{width:'fit-content'}}>
        <h2>Projects</h2>
        <hr className='sub-header-line'/>
        </div>
        <div style={{ display: 'flex', marginTop: '20px'}}>
    <div style={{  width: '65%',  }}>
      <a className="a-noStyle" href="/modding/madden-kit-creator" target="_blank" rel="noopener noreferrer">
        <img className="project" src="/Projects/JerseyForge.png" alt="Project 1" style={{ width: '100%', height: '100%' }} />
      </a>
    </div>
    <div style={{ width: '35%', padding: '0 20px', marginLeft: '20px'}}>
      <h2 style={{color: 'purple'}}>JerseyForge</h2>
      <p>An application built to streamline the process of creating uniforms to be used in EA Madden NFL</p>
      <p>Features include: a 3d model with the current uniform mapped to it using three.js, 150+ unique assets with color options, uploading unique assets, saving the kit in a zip file to be used in game with the help of the Fifa Editor Tool, configurable text and layers.</p>
      {/* Additional project information */}
    </div>
  </div>
  <div style={{ display: 'flex', marginTop: '20px'}}>
    <div style={{  width: '65%',  }}>
      <a className="a-noStyle" href="https://goatconnect.wpi.edu/" target="_blank" rel="noopener noreferrer">
        <img className="project" src="/Projects/GoatConnect.png" alt="Project 2" style={{ width: '100%', height: '100%' }} />
      </a>
    </div>
    <div style={{ width: '35%', padding: '0 20px', marginLeft: '20px' }}>
      <h2 style={{color: 'purple'}}>Goatconnect</h2>
      <p>A job search application designed specifically for WPI athletes and alumni.</p>
      <p>As part of a team of three WPI Student Athletes, I led the creation of a User Interface with an intuitive UX using Typescript and React.</p>
    </div>
  </div>
      </section>
      <section className="section">
        <div className='section-title' style={{width:'fit-content'}}>
        <h2>Summary</h2>
        <hr className='sub-header-line'/>
        </div>
        <p style={{lineHeight:'1.5'}}>
        A highly skilled and versatile software engineer with a strong passion for UI/UX design. With a solid background in front-end development, I excel at creating intuitive and visually appealing user interfaces that enhance the overall user experience. Additionally, I have a strong foundation in back-end development and unit testing. My ability to bridge the gap between design and functionality enables me to deliver user-centric software solutions that are both visually appealing and highly functional. I thrive in collaborative environments and am adept at working in cross-functional teams to deliver high-quality projects.
        </p>
      </section>
    </div>

    <div className="sidebar"/>
    </div>
  );
};

export default Resume;
