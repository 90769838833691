import React, { useState, useEffect } from 'react';
import DraftProspectList from './DraftProspectList';
import draftY1 from './_draftY1.json';

const DraftProspectsComponent = ({ filePath, leagueData }) => {
  const [draftProspects, setDraftProspects] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      console.log(filePath)
      try {
        const response = await fetch(`http://localhost:4000/draft-prospects?filePath=${encodeURIComponent(filePath)}`);
        if (response.ok) {
          const data = await response.json();
          //console.log(JSON.parse(draftY1.bigBoard))
          console.log(JSON.parse(draftY1.bigBoard))
          setDraftProspects(JSON.parse(data.bigBoard)); // Parse the JSON string
         // console.log(JSON.parse(data.bigBoard))
        } else {
          console.error('Failed to fetch draft prospects');
        }
      } catch (error) {
        console.error('Error fetching draft prospects:', error.message);
      }
    };

    fetchData();
  }, []); // The empty dependency array ensures that this effect runs only once

  // Render your component using the draftProspects state

  return (
<div style={{ display: 'flex' }}>
  <DraftProspectList bigBoard={draftProspects} leagueData={leagueData}/>
</div>


  );
};

export default DraftProspectsComponent;
