import React from 'react';
import { Link } from 'react-router-dom';

const Modding = () => {
  return (
    <div>
      <h1>MODDING</h1>
      <button>
      <Link to="/jerseyforge" style={{color:'#fff'}}>
        JerseyForge
      </Link>
      </button>
    </div>
  );
};

export default Modding;
