import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Resume from './Resume';
import Modding from './Modding';
import Graphics from './Graphics';
import RFL from './RFL';
// import NavigationBar from './NavigationBar';
import Home from './Home';
import SkillSpotter from './SkillSpotter'
import KitCreator from './KitCreator';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import LeaguePage from './SkillSpotter/LeaguePage';
import LeagueList from './SkillSpotter/LeagueList';
import Pfl from './PFL/Pfl';
import PowerRankings from './PFL/PowerRankings';
import PflDashboard from './PFL/PFLDashboard';
import GOTW from './PFL/GOTW';

function App() {
  return (
      <div>
    <Router>
      <div>
   {/* <NavigationBar /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/modding/*" element={<Modding />} />
          <Route path="/jerseyforge" element={
              <DndProvider backend={HTML5Backend}>
                <KitCreator />
              </DndProvider>
          } />
          <Route path="/graphics" element={<Graphics />} />
          <Route path="/rfl" element={<RFL />} />
          <Route path="/skillspotter" element={<SkillSpotter/>} />
          <Route path="/skillspotter/leagues" element={<LeagueList />} />
          <Route path="/skillspotter/leagues/:uniqueId" element={<LeaguePage />} />
          <Route path="/pfl/scouting" element={<Pfl/>} />
          <Route path="/pfl/power-rankings" element={<PowerRankings/>} />
          <Route path="/pfl" element={<PflDashboard/>} />
          <Route path="/pfl/gotw" element={<GOTW/>} />

        </Routes>
      </div>
    </Router>
    </div>
  );
}




export default App;
