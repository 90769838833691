import React, { useState, useEffect } from 'react';
import DraftProspectList from './DraftProspectList';
import draftY1 from './_draftY1.json';
import draftY2 from './_draftY2.json';
import draftY3 from './_draftY3.json';
import draftY4 from './_draftY4.json';

const DraftProspectsComponent = ({ filePath, leagueData }) => {
  const [draftProspects, setDraftProspects] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setDraftProspects(JSON.parse(draftY4.bigBoard)); // Parse the JSON string
    };
    fetchData();
  }, []); // The empty dependency array ensures that this effect runs only once

  // Render your component using the draftProspects state

  return (
<div style={{ display: 'flex' }}>
  <DraftProspectList bigBoard={draftProspects} leagueData={leagueData}/>
</div>


  );
};

export default DraftProspectsComponent;
