import React from 'react';
import { BiSearch } from 'react-icons/bi'; // Import the search icon
import { TbStarFilled } from "react-icons/tb";
import './select.css'
import { FaInfoCircle } from "react-icons/fa";

const StickyHeader = ({ onSearchChange, viewOption, onViewOptionChange, favoritesOnly, setFavoritesOnly, selectedPosition, onPositionChange,  selectedArchetype, onArchetypeChange}) => {
  let starColor = favoritesOnly ? '#FFD900' : ''
  return (
    <div style={{ backgroundColor: '#f0f0f0', padding: '10px', position: 'sticky', top: '0', zIndex: '100', width: '810px' }}>
      <div style={{ display: 'flex', marginRight: '10px', marginBottom: '10px' }}>
      <div
          onClick={() => setFavoritesOnly(!favoritesOnly)}
          style={{
            padding: '8px',
            marginRight: '10px',
            cursor: 'pointer',
            backgroundColor: favoritesOnly ? '#ccc' : 'transparent',
            borderBottom: favoritesOnly ? 'none' : '1px solid #ccc',
            display:'flex'
          }}
        >
          <TbStarFilled  style={{width:"20px", color: starColor}}/><div>Favorites</div>
        </div>
        <select
        style={{ padding: '8px', borderRadius: '5px', border: 'none', marginRight: '10px', cursor:'pointer' }}
        onChange={(e) => {onPositionChange(e.target.value); onArchetypeChange("ALL")}}
        value={selectedPosition}
      >
        <option value="ALL">All Positions</option>
        <option value="QB" className='option'>Quarterback</option>
        <option value="HB">Running Back</option>
        <option value="FB">Fullback</option>
        <option value="WR">Wide Reciever</option>
        <option value="TE">Tight End</option>
        <option value="OL">Offensive Line</option>
        <option value="IOL">Interior Offensive Line</option>
        <option value="OT">Offensive Tackle</option>
        {/* <option value="LT">Left Tackle</option>
        <option value="LG">Left Gaurd</option>
        <option value="C">Center</option>
        <option value="RG">Right Gaurd</option>
        <option value="RT">Right Tackle</option> */}
        <option value="Edge">Edge</option>
        <option value="DL">Defense Line</option>
        {/* <option value="LE">Left End</option>
        <option value="DT">Defense Tackle</option>
        <option value="RE">Right End</option> */}
        <option value="LB">Linebacker</option>
        {/* <option value="LOLB">Left Outside Linebacker</option>
        <option value="MLB">Middle Linebacker</option>
        <option value="ROLB">Right Outside Linebacker</option> */}
        <option value="DB">Defensive Back</option>
        {/* <option value="CB">Cornerback</option> */}
        <option value="S">Safety</option>
        {/* <option value="FS">Free Saftey</option>
        <option value="SS">Strong Saftey</option> */}
        <option value="K">Kicker</option>
        <option value="P">Punter</option>

        {/* Add more options for different positions */}
      </select>
      <select
        style={{ padding: '8px', borderRadius: '5px', border: 'none', marginRight: '10px', cursor:'pointer' }}
        onChange={(e) => onArchetypeChange(e.target.value)}
        value={selectedArchetype}
      >
        <option value="ALL">All Archetypes</option>
        {(selectedPosition=='QB' || selectedPosition=='ALL') && <option value="QB_FieldGeneral">Field General</option>}
        {(selectedPosition=='QB' || selectedPosition=='ALL') && <option value="QB_Improviser">Improviser</option>}
        {(selectedPosition=='QB' || selectedPosition=='ALL') && <option value="QB_StrongArm">Strong Arm</option>}
        {(selectedPosition=='QB' || selectedPosition=='ALL') && <option value="QB_Scrambler">Scrambler</option>}
        {(selectedPosition=='HB' || selectedPosition=='ALL') && <option value="HB_PowerBack">Power Back</option>}
        {(selectedPosition=='HB' || selectedPosition=='ALL') && <option value="HB_ReceivingBack">Receiving Back</option>}
        {(selectedPosition=='HB' || selectedPosition=='ALL') && <option value="HB_ElusiveBack">Elusive Back</option>}
        {(selectedPosition=='WR' || selectedPosition=='ALL') && <option value="WR_DeepThreat">Deep Thread</option>}
        {(selectedPosition=='WR' || selectedPosition=='ALL') && <option value="WR_Playmaker">Playmaker</option>}
        {(selectedPosition=='WR' || selectedPosition=='ALL') && <option value="WR_Physical">Physical WR</option>}
        {(selectedPosition=='WR' || selectedPosition=='ALL') && <option value="WR_Slot">Slot WR</option>}
        {(selectedPosition=='TE' || selectedPosition=='ALL') && <option value="TE_VerticalThreat">Vertical Threat</option>}
        {(selectedPosition=='TE' || selectedPosition=='ALL') && <option value="TE_Possession">Possession</option>}
        {(selectedPosition=='TE' || selectedPosition=='ALL') && <option value="TE_Blocking">Blocking</option>}
        {(selectedPosition=='OT' || selectedPosition=='IOL' || selectedPosition=='OL' || selectedPosition=='ALL') && <option value="OL_Agile">Agile</option>}
        {(selectedPosition=='OT' || selectedPosition=='IOL' || selectedPosition=='OL' || selectedPosition=='ALL') && <option value="OL_Power">Power</option>}
        {(selectedPosition=='OT' || selectedPosition=='IOL' || selectedPosition=='OL' || selectedPosition=='ALL') && <option value="PassProtector">Pass Protecter</option>}
        {(selectedPosition=='Edge' || selectedPosition=='ALL' || selectedPosition=='DL'  || selectedPosition=='RE' || selectedPosition=='LE'||  selectedPosition=='ROLB' || selectedPosition=='LOLB' || selectedPosition=='MLB'  || selectedPosition=='LB') && <option value="RunStopper">Run Stopper</option>}
        {(selectedPosition=='Edge'  || selectedPosition=='ALL'|| selectedPosition=='DL'  || selectedPosition=='RE' || selectedPosition=='LE'||  selectedPosition=='ROLB' || selectedPosition=='LOLB') && <option value="SpeedRusher">Speed Rusher</option>}
        {(selectedPosition=='Edge'  || selectedPosition=='ALL'|| selectedPosition=='DL'  || selectedPosition=='RE' || selectedPosition=='LE'||  selectedPosition=='ROLB' || selectedPosition=='LOLB') && <option value="PowerRusher">Power Rusher</option>}
        {(selectedPosition=='LB'  || selectedPosition=='ALL') && <option value="FieldGeneral">Field General</option>}
        {(selectedPosition=='LB'  || selectedPosition=='ALL') && <option value="PassCoverage">Pass Coverage</option>}
        {(selectedPosition=='DB'  || selectedPosition=='ALL') && <option value="MantoMan">Man to Man</option>}
        {(selectedPosition=='DB' || selectedPosition=='S'  || selectedPosition=='ALL') && <option value="Zone">Zone</option>}
        {(selectedPosition=='DB'  || selectedPosition=='ALL') && <option value="Slot">Slot DB</option>}
        {(selectedPosition=='DB' || selectedPosition=='S' || selectedPosition=='ALL') && <option value="RunSupport">Run Support</option>}
        {(selectedPosition=='DB' || selectedPosition=='S' || selectedPosition=='ALL') && <option value="Hybrid">Hybrid</option>}


        {/* Add more options for different positions */}
      </select>
        <div
          onClick={() => onViewOptionChange('large')}
          style={{
            padding: '8px',
            marginRight: '10px',
            cursor: 'pointer',
            backgroundColor: viewOption === 'large' ? '#ccc' : 'transparent',
            borderBottom: viewOption === 'large'  ? 'none' : '1px solid #ccc',

          }}
        >
          Large
        </div>
        <div
          onClick={() => onViewOptionChange('medium')}
          style={{
            padding: '8px',
            marginRight: '10px',
            cursor: 'pointer',
            backgroundColor: viewOption === 'medium' ? '#ccc' : 'transparent',
            borderBottom: viewOption === 'medium'  ? 'none' : '1px solid #ccc',

          }}
        >
          Medium
        </div>
        <div
          onClick={() => onViewOptionChange('thin')}
          style={{
            padding: '8px',
            cursor: 'pointer',
            backgroundColor: viewOption === 'thin' ? '#ccc' : 'transparent',
            borderBottom: viewOption === 'thin'  ? 'none' : '1px solid #ccc',

          }}
        >
          Thin
        </div>
      </div>
      <BiSearch style={{ marginRight: '5px', fontSize: '20px', color: '#777' }} />
      <input
        type="text"
        placeholder="Search by name or college..."
        style={{ width: '90%', padding: '8px', borderRadius: '5px', border: '1px solid #ccc' }}
        onChange={onSearchChange}
      />
      <div style={{fontSize: '12px', marginTop:'10px', marginLeft:'5px'}}><FaInfoCircle /> 50% scouting to unlock detailed view</div>
    </div>
  );
};

export default StickyHeader;
