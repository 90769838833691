import React from 'react';
import './GameModule.css';

const GameModule = ({ gameOfTheWeek }) => {
  if (!gameOfTheWeek) {
    return <p>Loading game data...</p>;
  }
  
  const { homeTeam, awayTeam, homeScore, awayScore } = gameOfTheWeek;

  console.log(gameOfTheWeek)
  return (
    <div className="game-module">
      <div className="team-container">
        <div className="team">
          <img src={`https://cdn.neonsportz.com/teamlogos/256/${homeTeam.logoId}.png`} alt={homeTeam.displayName} className="team-logo" />
          <p className="team-name">{homeTeam.displayName}</p>
          <p className="">{homeTeam.totalWins}-{homeTeam.totalLosses}{homeTeam.totalTies > 0 && `-${homeTeam.totalTies}`}</p>
          <p className="">{homeTeam.userName}</p>
        </div>
        <div className="score">
          <p>{homeScore}</p>
          <p>-</p>
          <p>{awayScore}</p>
        </div>
        <div className="team">
          <img src={`https://cdn.neonsportz.com/teamlogos/256/${awayTeam.logoId}.png`} alt={awayTeam.displayName} className="team-logo" />
          <p className="team-name">{awayTeam.displayName}</p>
          <p className="">{awayTeam.totalWins}-{awayTeam.totalLosses}{awayTeam.totalTies > 0 && `-${awayTeam.totalTies}`}</p>
          <p className="">{awayTeam.userName}</p>

        </div>
      </div>
    </div>
  );
};

export default GameModule;
