import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DraftProspectsComponent from './DraftProspects';
import CurrScouts from './CurrScouts';
import './LeagueNav.css'

const LeaguePage = () => {
  const { uniqueId } = useParams();
  const [leagueData, setLeagueData] = useState(null);
  const [dataUser, setUser] = useState([]);

  useEffect(() => {
    // Make API call using the uniqueId
    const fetchData = async () => {
      try {
        const response = await fetch(`http://localhost:8000/api/league/${uniqueId}/`);
        // const validJsonString = draftData.replace(/\\"/g, '"').replace(/\\\\/g, '\\');
        const data = await response.json();//JSON.parse(validJsonString)//
        setLeagueData(data);
      } catch (error) {
        console.error('Error fetching league data:', error);
      }
    };

    fetchData();
  }, [uniqueId]);

  useEffect(() => {
    const fetchLeagues = async () => {
      try {
        const responseUser = await fetch('http://localhost:8000/api/current_user/', {
          credentials: 'include', // Include credentials (cookies) in the request
        });
        const dataUser = await responseUser.json();
        setUser(dataUser)

      } catch (error) {
      }
    };

    fetchLeagues();
  }, []);

  return (
    <div>
      
      {leagueData ? (
        
        <div >
          <div className={'nav'}>
           <div style={{display: 'flex'}}>
            {leagueData.league_icon && <img
              style={{width: '50px', height: '50px'}}
              src={`${leagueData.league_icon}`}
            />}
            <h1 style={{fontSize: '50px'}}>{leagueData.league_name}</h1>
            </div>

            <div
            style={{ cursor: 'pointer', marginLeft: '20px', color: 'blue', marginTop: '20px'}}
            onClick={() => window.location.href = '/skillspotter/leagues'}
          >
            Back to Leagues
          </div>
          </div>
          <div style={{display:'flex'}}>
          <div style={{ height: '85vh', overflowY:'scroll' }}>
          <DraftProspectsComponent filePath={leagueData.league_draft_file} leagueData={uniqueId}/>
          </div>
          <div style={{ height: '85vh', overflowY:'scroll' }}>

          <CurrScouts league={leagueData} setLeague={setLeagueData}/>
          </div>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default LeaguePage;
