import React, { useState } from 'react';
import './AssetOptions.css';
import { AiFillEye, AiFillEyeInvisible, AiOutlineUpload } from 'react-icons/ai';
import { BsFillTrash2Fill } from 'react-icons/bs';
import { CirclePicker } from 'react-color';
import { ChromePicker } from 'react-color';
const DEFAULT_COLOR = '#000000'; // Define the default color here

function AssetOptions({ tabOpen, allAssets, handleAssetSelection, assets, setAssets, title, assetVisibility, setAssetVisibility, selectedColor, setSelectedColor, currentAsset, setCurrentAsset, handleFileUpload, currNorm, allNorms, setNorm, currFonts, allFonts, setFont, fontSize, setFontSize, yOffset, setYOffset,showChestLogo,setShowChestLogo, showBackLogo, setShowBackLogo,showSleeveLogo,setShowSleeveLogo, primaryColor, secondaryColor, cityName, teamName, logoHelmetSize, setLogoHelmetSize, logoHelmetX, setLogoHelmetX, logoHelmetY, setLogoHelmetY, logoHelmetRotate, setLogoHelmetRotate, setSelectedAssets, selectedAsset} ) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedAssetType, setSelectedAssetType] = useState(null);
  const [isColorPickerOpen, setColorPickerOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isContentVisible, setContentVisible] = useState(false);
  const [showLogoOptions, setShowLogoOptions] = useState(false);

  const handleToggleLogoOptions = () => {
    setShowLogoOptions(!showLogoOptions);
  };
  const handleSetText = (assetId, newText) => {
    setAssets((prevAssets) =>
      prevAssets.map((prevAsset) =>
        prevAsset.id === assetId ? { ...prevAsset, text: newText.toUpperCase() } : prevAsset
      )
    );
  };

  const handleCheckboxChange = () => {
    setShowChestLogo((prevShowChestLogo) => !prevShowChestLogo);
  };
  const handleBackCheckboxChange = () => {
    setShowBackLogo((prevShowBackLogo) => !prevShowBackLogo);
  };
  const handleSleevCheckboxChange = () => {
    setShowSleeveLogo((prevShowSleeveLogo) => !prevShowSleeveLogo);
  };
  
  const handleTextButton = () => {
    setContentVisible(!isContentVisible);
  };

  const openColorPicker = (asset, color) => {
    setCurrentAsset(asset)
    setColorPickerOpen(true);
    setSelectedColor(color);
  };

  const toggleAssetVisibility = (assetId) => {
    handleAssetSelection(assetId, selectedAsset, setSelectedAssets);
    setAssetVisibility((prevState) => ({
      ...prevState,
      [assetId]: !prevState[assetId],
    }));
  };

  const addTextAsset = () => {
    const newTextAsset = {
      id: 10000, // You can assign a unique ID to the new text asset
      type: 'Text', // Set the type as 'Text'
      name: 'New Text Asset', // Set the name of the text asset
      subType: 'Text Input', // You can set the subtype if needed
      color: DEFAULT_COLOR, // Set the default color\
      text: 'hello',
    };
  
    setAssets((prevAssets) => [newTextAsset, ...prevAssets]);
  };
  

  const moveAssetUp = (index) => {
    if (index === 0) return; // Already at the top, no need to move
    setAssets((prevAssets) => {
      const reorderedAssets = [...prevAssets];
      const movedAsset = reorderedAssets[index];
      reorderedAssets.splice(index, 1);
      reorderedAssets.splice(index - 1, 0, movedAsset);
      return reorderedAssets.map((asset, i) => ({
        ...asset,
        zIndex: i + 1, // Update the zIndex based on the new order
      }));
    });
  };

  
const Dropdown = ({ allFonts }) => {
  const handleFontChange = (event) => {
    setFont(event.target.value);
  };
  const fontName = currFonts.substring(
    currFonts.lastIndexOf('/') + 1,
    currFonts.indexOf('.')
  ).replace(/_/g, ' ');
  return (
    <div>
      <select value={fontName} onChange={handleFontChange} className="custom-select" style={{ fontFamily: "CustomFont", color: 'purple', fontSize: '30px'}}>
        <option value="">{fontName}</option>
        {allFonts
          .filter((font) => font !== fontName)
          .map((font, index) => {
            const fontNameStyle = `CustomFont${index}`;
            const customFont = new FontFace(fontNameStyle, `url(${font})`);
            customFont.load().then(() => {
              document.fonts.add(customFont);
            });
  
            return (
              <option key={index} value={font} style={{ fontFamily: fontNameStyle, color: 'purple', fontSize: '30px'}}>
                {font
                  .substring(font.lastIndexOf('/') + 1, font.indexOf('.'))
                  .replace(/_/g, ' ')}
              </option>
            );
          })}
      </select>
    </div>
  );  

};

const FontSizeEditor = () => {

  const [inputValue, setInputValue] = useState(fontSize);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newFontSize = parseInt(inputValue);
    if (!isNaN(newFontSize)) {
      setFontSize(newFontSize);
    }
  };

  return (
<form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center' }}>
  <input
    className='font-input'
    type="number"
    placeholder={fontSize}
    value={inputValue}
    onChange={handleChange}
  />
  <button type="submit" className="font-button" style={{backgroundColor:'purple'}}>Apply</button>
</form>


  );


};

const YAxisEditor = () => {

  const [inputValue, setInputValue] = useState(yOffset);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newFontSize = parseInt(inputValue);
    if (!isNaN(newFontSize)) {
      setYOffset(newFontSize);
    }
  };

  return (
<form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center' }}>
  <input
    className='font-input'
    type="number"
    placeholder={fontSize}
    value={inputValue}
    onChange={handleChange}
  />
  <button type="submit" className="font-button" style={{backgroundColor:'purple'}}>Apply</button>
</form>

  );


};

const LogoHelmetSizeEditor = () => {

  const [inputValue, setInputValue] = useState(logoHelmetSize);

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newFontSize = parseInt(inputValue);
    if (!isNaN(newFontSize)) {
      setLogoHelmetSize(newFontSize);
    }
  };

  return (
<form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center' }}>
  <input
    className='font-input'
    type="number"
    placeholder={logoHelmetSize}
    value={inputValue}
    onChange={handleChange}
  />
  <button type="submit" className="font-button" style={{backgroundColor:'purple'}}>Apply</button>
</form>

  );


};

const LogoHelmetEditor = ({ size, setSize }) => {
  const [inputValue, setInputValue] = useState(String(size));

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const newFontSize = parseInt(inputValue);
    if (!isNaN(newFontSize)) {
      setSize(newFontSize);
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center' }}>
      <input
        className="font-input"
        type="number"
        placeholder={size}
        value={inputValue}
        onChange={handleChange}
      />
      <button type="submit" className="font-button" style={{ backgroundColor: 'purple' }}>
        Apply
      </button>
    </form>
  );
};


  const moveAssetDown = (index) => {
    if (index === assets.length - 1) return; // Already at the bottom, no need to move
    setAssets((prevAssets) => {
      const reorderedAssets = [...prevAssets];
      const movedAsset = reorderedAssets[index];
      reorderedAssets.splice(index, 1);
      reorderedAssets.splice(index + 1, 0, movedAsset);
      return reorderedAssets.map((asset, i) => ({
        ...asset,
        zIndex: i + 1, // Update the zIndex based on the new order
      }));
    });
  };


  const openModal = (type) => {
    setSelectedAssetType(type);
    setModalOpen(true);
  };

  const closeModal = () => {
    setSelectedAssetType(null);
    setModalOpen(false);
  };

  const isAssetTypeCollapsed = (assetType) => {
    return !assets.some((asset) => asset.type === assetType);
  };

  const handleAssetClick = (asset, selectedColor) => {
    const isAssetSelected = assets.some((selectedAsset) => selectedAsset.id === asset.id);
    const assetWithColor = { ...asset, color: selectedColor || DEFAULT_COLOR };
  
    if (isAssetSelected) {
      removeAsset(asset.id);
    } else {
      setAssets((prevAssets) => [assetWithColor, ...prevAssets]);
    }
  };

  const removeAsset = (assetId) => {
    setAssets((prevAssets) => prevAssets.filter((asset) => asset.id !== assetId));
  };

const renderModalContent = () => {
  if (!selectedAssetType) return null;
  const assetsByType = allAssets.filter((asset) => asset.type === selectedAssetType);
  if(selectedAssetType!=="Norm"){
  return (
    <div className="modal-content">
      <div className="modal-content-ontop">
    <button onClick={closeModal} className="modal-close-button">
      Close
    </button>
    <input
        className="modal-search-bar"
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      </div>
    <div className="asset-grid" style={{ marginTop: '30px' }}>
      {assetsByType.map((asset) => {
        const isAssetSelected = assets.some((selectedAsset) => selectedAsset.id === asset.id);
        const selectedAsset = assets.find((selectedAsset) => selectedAsset.id === asset.id);
        if (
          asset.name.toLowerCase().indexOf(searchQuery.toLowerCase()) === -1 &&
          asset.subType.toLowerCase().indexOf(searchQuery.toLowerCase()) === -1
        ) {
          return null;
        }        return (
          <div
            key={asset.id}
            className={`modal-item${isAssetSelected ? ' selected' : ''}`}
            onClick={() => handleAssetClick(asset, selectedAsset && selectedAsset.color)}
          >
{asset.previewUrl ? (
  <img src={asset.previewUrl} alt={asset.name} className="asset-image" />
) : (
  <img src={asset.imageUrl} alt={asset.name} className="asset-image" />
)}
            <div className="asset-name" style={{margin:'10px'}}>
              <div>
              {asset.name}
              </div>
              <div className='sub-type'>
              {asset.subType}
              </div>
            </div> {/* Display asset name */}
            {isAssetSelected && <div className="selected-overlay"></div>}
          </div>
        );
      })}
    </div>
  </div>
  
  );
  }else{
    return (
      <div className="modal-content">
      <button onClick={closeModal} className="modal-close-button">
        Close
      </button>
      <div className="asset-grid" style={{ marginTop: '30px' }}>
        {allNorms.map((asset) => {
          const isAssetSelected = currNorm === asset.imageUrl    
          return (
            <div
              key={asset.id}
              className={`modal-item${isAssetSelected ? ' selected' : ''}`}
              onClick={() => setNorm(asset.imageUrl)}
            >
  {asset.previewUrl ? (
    <img src={asset.previewUrl} alt={asset.name} className="asset-image" />
  ) : (
    <img src={asset.imageUrl} alt={asset.name} className="asset-image" />
  )}
              <div className="asset-name" style={{margin:'10px'}}>
                <div>
                {asset.name}
                </div>
                <div className='sub-type'>
                {asset.subType}
                </div>
              </div> {/* Display asset name */}
              {isAssetSelected && <div className="selected-overlay"></div>}
            </div>
          );
        })}
      </div>
    </div>
    
    );
  }
  
};

  const assetGroups = allAssets.reduce((groups, asset) => {
    const { type } = asset;
    if (!groups[type]) {
      groups[type] = [];
    }
    groups[type].push(asset);
    return groups;
  }, {});

  return (
    <div className="options-container">
      {tabOpen && <>
        
      <div className="tab-data">
     

      {isModalOpen && (
        <div className="modal">
          {renderModalContent()} {/* Render the modal content */}
        </div>
      )}

      { <h3 style={{fontSize: '20px', textTransform: 'uppercase', marginLeft:'2px'}}>{title}</h3>}

    {title==="Jersey" &&
    <div>
      <button className='font-button' onClick={handleToggleLogoOptions} style={{backgroundColor:'rgba(0,0,0,0.05)', color:'purple', fontWeight: 'bold', marginTop:'20px'}}>
        {showLogoOptions ? 'Hide Logo Options - ' : 'Show Logo Options + '}
      </button>
      <div style={{borderBottom: '1px solid #E2E8F0', backgroundColor:'#F5F7FA', marginTop:'15px', width:'104%'}}/>

          {showLogoOptions && (
      <div style={{marginLeft: '-30px', paddingLeft:'30px', borderBottom: '3px solid purple'}}>
        <div className={`asset-item`} style={{ display: 'flex' }}>
          <input
            type="checkbox"
            checked={showChestLogo}
            onChange={handleCheckboxChange}
            className='checkbox'
          />
          <div style={{ marginLeft: '10px', color: 'rgba(0,0,0,0.75)', fontSize: '13px' }}>
            SHOW LOGO ON <b style={{ color: 'purple' }}>CHEST</b>
          </div>
        </div>
        <div className={`asset-item`} style={{ display: 'flex' }}>
          <input
            type="checkbox"
            checked={showBackLogo}
            onChange={handleBackCheckboxChange}
            className='checkbox'
          />
          <div style={{ marginLeft: '10px', color: 'rgba(0,0,0,0.75)', fontSize: '13px' }}>
            SHOW LOGO ON <b style={{ color: 'purple' }}>BACK</b>
          </div>
        </div>
        <div className={`asset-item`} style={{ display: 'flex' }}>
          <input
            type="checkbox"
            checked={showSleeveLogo}
            onChange={handleSleevCheckboxChange}
            className='checkbox'
          />
          <div style={{ marginLeft: '10px', color: 'rgba(0,0,0,0.75)', fontSize: '13px' }}>
            SHOW LOGO ON <b style={{ color: 'purple' }}>SLEEVES</b>
          </div>
        </div>
      </div>
    )}
  </div>
}
{title==="Helmet" &&
    <div>
      <button className='font-button' onClick={handleToggleLogoOptions} style={{backgroundColor:'rgba(0,0,0,0.05)', color:'purple', fontWeight: 'bold', marginTop:'20px'}}>
        {showLogoOptions ? 'Hide Logo Options - ' : 'Show Logo Options + '}
      </button>
      <div style={{borderBottom: '1px solid #E2E8F0', backgroundColor:'#F5F7FA', marginTop:'15px', width:'104%'}}/>

          {showLogoOptions && (
      <div style={{marginLeft: '-30px', paddingLeft:'30px', borderBottom: '3px solid purple'}}>
      <div className={`asset-item`} style={{ display: 'flex' }}>
          <input
            type="checkbox"
            checked={showChestLogo}
            onChange={handleCheckboxChange}
            className='checkbox'
          />
          <div style={{ marginLeft: '10px', color: 'rgba(0,0,0,0.75)', fontSize: '13px' }}>
            SHOW LOGO ON <b style={{ color: 'purple' }}>HELMET</b>
          </div>
        </div>
        {/* <div className={`asset-item`} style={{ display: 'flex' }}>
          <input
            type="checkbox"
            checked={showBackLogo}
            onChange={handleBackCheckboxChange}
            className='checkbox'
          />
          <div style={{ marginLeft: '10px', color: 'rgba(0,0,0,0.75)', fontSize: '13px' }}>
            <b style={{ color: 'purple' }}>FLIP LOGO</b> ON OTHER SIDE
          </div>
        </div> */}
        <div >
          <LogoHelmetSizeEditor/>
          <div className='sub-type' style={{marginBottom:'10px', padding:'10px', borderBottom: '1px solid #ccc'}} >
                Logo Size
          </div>
        </div>
        <div >
          <LogoHelmetEditor size={logoHelmetX} setSize={setLogoHelmetX}/>
          <div className='sub-type' style={{marginBottom:'10px', padding:'10px', borderBottom: '1px solid #ccc'}} >
                Logo X-Offset
          </div>
        </div>
        <div >
          <LogoHelmetEditor size={logoHelmetY} setSize={setLogoHelmetY}/>
          <div className='sub-type' style={{marginBottom:'10px', padding:'10px', borderBottom: '1px solid #ccc'}} >
                Logo Y-Offset
          </div>
        </div>
        <div >
          <LogoHelmetEditor size={logoHelmetRotate} setSize={setLogoHelmetRotate}/>
          <div className='sub-type' style={{marginBottom:'10px', padding:'10px', borderBottom: '1px solid #ccc'}} >
                Logo Rotate
          </div>
        </div>
      </div>
    )}
  </div>
}
      {assets.map((asset, index) => {
        const isCollapsed = isAssetTypeCollapsed(asset.type);
        return (
<div>
<div
  key={asset.id}
  className={`asset-item${isCollapsed ? ' collapsed' : ''}`}
  style={{ display: isCollapsed ? 'none' : 'flex' }}
>
  {asset.noDelete===undefined && 
<button onClick={() => removeAsset(asset.id)} className="remove-button" disabled={assetVisibility[asset.id]}>
      <BsFillTrash2Fill />
    </button>
      }
        {asset.visible===undefined && 
  <button onClick={() => toggleAssetVisibility(asset.id)}>
    {assetVisibility[asset.id] ? <AiFillEye /> : <AiFillEyeInvisible style={{ color: 'rgba(0,0,0,0.5' }} />}
  </button>
      }
  <div className="asset-details">
  { asset.type!=='Custom' && !(asset.type==='League' && asset.canColor===undefined) && asset.type!=='Texture' &&
  <div className="color-square" onClick={() => openColorPicker(asset, asset.color)} style={{ background: asset.color, borderRadius: '4px', border: '1px solid black', height: '20px', width: '20px', marginRight: '10px'}}></div>
      }
  <span className="items-span">
              
  {asset.type === 'Text' ? (
  <div>
  <input
    className="item-text-input"
    type="text"
    value={asset.text}
    onChange={(e) => {
      const newText = e.target.value;
      setAssets((prevAssets) =>
        prevAssets.map((prevAsset) =>
          prevAsset.id === asset.id ? { ...prevAsset, text: newText } : prevAsset
        )
      );
    }}
  />
</div>
) : (
  <div>{asset.name}</div>
)}

            <div className='sub-type'>
              {asset.subType}
              </div>
              {asset.type==="Text" &&
              <div>
      <button onClick={handleTextButton} className='font-button' style={{backgroundColor:'purple', width: '100%'}}>
        {isContentVisible ? 'Hide Options' : 'Show Options'}
      </button>
    </div>
              } 
             
              
  </span>

  <div className='extra-buttons'>
  <button onClick={() => moveAssetDown(index)} disabled={index === assets.length - 1 || isCollapsed}>
      ▼
    </button>
    <button onClick={() => moveAssetUp(index)} disabled={index === 0 || isCollapsed}>
      ▲
    </button>
    </div>

  </div>
</div>
{asset.type==="Text" &&
              <div>
      {isContentVisible && (
        <div style={{borderBottom: '1px solid #E2E8F0', backgroundColor:'#F5F7FA', marginTop:'-15px', width:'104%'}}>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
    <button className="font-button" style={{width: '92%'}} onClick={() => handleSetText(asset.id, teamName)}>Set to Team Name</button>
    <button className="font-button" style={{width: '92%'}} onClick={() => handleSetText(asset.id, cityName)}>Set to City</button>
  </div>
          <Dropdown allFonts={allFonts} />
          <div className="sub-type" style={{marginBottom:'15px'}}>Font Select</div>
          <FontSizeEditor />
          <div className="sub-type" style={{marginBottom:'15px'}}>Font Size</div>
          <YAxisEditor />
          <div className="sub-type" style={{marginBottom:'15px'}}>Y-Axis Offset</div>
        </div>
      )}
    </div>
              } 
</div>
        );
      })}

{ <h3 style={{marginLeft: '15px', fontSize: '15px', borderBottom: '1px solid rgba(0,0,0,0.1)'}}>ADD LAYER</h3>}
<div className="more-items-container">
        {Object.keys(assetGroups).map((type) => (
          <div className="more-items" key={type}>
            <button
              className="more-items-button"
              onClick={() => openModal(type)}
            >
              See All <b style={{color:'purple'}}>{type}</b> Items +
            </button>
          </div>
        ))}
          <div className="more-items" key={'Norm'}>
            <button
              className="more-items-button"
              onClick={() => openModal('Norm')}
            >
              Select <b style={{color:'purple'}}>Norm</b> +
            </button>
            {title==="Jersey" &&
            <button
              className="more-items-button"
              onClick={addTextAsset}
              disabled={assets.some(asset => asset.type === 'Text')}
              >
              Add <b style={{color:'purple'}}>Text</b> + (1 Max)
            </button>
          }
          </div>
        
{ title==="Jersey" &&
<div className="custom-file-upload">
  <div style={{marginLeft:'5px',paddingTop:'5px', borderTop:'1px solid rgba(0,0,0,0.1)'}}> <b>UPLOAD ASSET BETA</b></div>
  <div style={{marginBottom:'20px', marginLeft:'5px'}}>FILE MUST BE 2048x4096</div>
  <input
    type="file"
    accept="image/png"
    id="fileInput"
    onChange={handleFileUpload}
    className="hidden-input"
  />
  <label htmlFor="fileInput" className="custom-button">
  <AiOutlineUpload/>  Upload Asset
  </label>
</div>}
      </div>

      {isColorPickerOpen && (
        <div className="color-picker-modal">
  <div className="color-picker-container">
      <div className="chrome-picker-wrapper">
        <ChromePicker color={selectedColor} onChange={(color) => {
          setSelectedColor(color.hex);
          currentAsset.color = color.hex;
        }} />
      </div>
    
      <div className="circle-picker-wrapper">
        <h3>Team Colors</h3>
        <CirclePicker
 colors={[
  primaryColor, secondaryColor,
  '#F3F3F3', // White
   '#1A1A1A', // Black
 
]}        onChange={(color) => {
          setSelectedColor(color.hex);
          currentAsset.color = color.hex;
        }} />
      </div>
    <button className="color-picker-close-button" onClick={() => setColorPickerOpen(false)}>Close</button>

  </div>
</div>

      )}
      </div></>
}
</div>
  );
}

export default AssetOptions;
