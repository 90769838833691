import React from 'react';
import DraftProspectsComponent from './DraftProspects';
function Pfl() {
  return (
    <div>
        <DraftProspectsComponent/>
    </div>
  );
}




export default Pfl;
